import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import theme from './app/theme'
import store from './redux/store'
import { ThemeProvider } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import ScrollToTop from './app/ScrollToTop'
import App from './app/App'

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ScrollToTop />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </LocalizationProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
)
