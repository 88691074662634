import { useState } from 'react'
import firebase, { firestore } from '../../../../../../utils/firebase/firebase'
import UserBooking from '../userBooking/UserBooking'
import dayjs from 'dayjs'

import {
  BookingCard,
  Bookings,
  Header,
  UserBookingContainer,
  Button,
  CloseButton,
  MoreButton,
} from './UserBookingsCard.styles'
import { toast } from 'react-toastify'
function UserBookingsCard({ bookingType, bookings, getUserBookings }) {
  const [filteredBookings, setFilteredBookings] = useState([])
  const [bookingLimit, setBookingLimit] = useState(5)
  const [baseBookings, setBaseBookings] = useState([])

  const handleOnClick = () => {
    if (bookingType === 'future') {
      setFilteredBookings(
        bookings
          .filter(
            (booking) =>
              booking.data.startTime > firebase.firestore.Timestamp.now()
          )
          .slice(0, bookingLimit)
      )
      setBaseBookings(
        bookings.filter(
          (booking) =>
            booking.data.startTime > firebase.firestore.Timestamp.now()
        )
      )
      setBookingLimit(bookingLimit + 5)
    }
    if (bookingType === 'past') {
      const sortedBookings = bookings
        .slice()
        .sort((a, b) => b.data.startTime - a.data.startTime)
      const newArray = sortedBookings
        .filter(
          (booking) =>
            booking.data.startTime < firebase.firestore.Timestamp.now()
        )
        .slice(0, bookingLimit)
      setBaseBookings(
        sortedBookings.filter(
          (booking) =>
            booking.data.startTime < firebase.firestore.Timestamp.now()
        )
      )
      setBookingLimit(bookingLimit + 5)
      setFilteredBookings(newArray)
    }

    if (bookingType === 'recurring') {
      const timeNow = dayjs().add(1, 'day')
      const newArray = bookings
        .filter(
          (booking) =>
            booking.data.isRecurring &&
            dayjs(booking.data.startTime.toDate()) > timeNow
        )
        .slice(0, bookingLimit)

      setBaseBookings(
        bookings.filter(
          (booking) =>
            booking.data.isRecurring &&
            dayjs(booking.data.startTime.toDate()) > timeNow
        )
      )
      setFilteredBookings(newArray)
      setBookingLimit(bookingLimit + 5)
    }
  }

  const cancelBooking = async (id) => {
    var bookingRef = firestore.collection('bookings').doc(id)
    if (bookingType === 'future') {
      // if booking type is future then update booking status to cancelled

      return bookingRef
        .update({
          status: 'cancelled',
        })
        .then(() => {
          toast.success('Booking successfully cancelled.')
          getUserBookings()
          setFilteredBookings([])
          setBookingLimit(5)
        })
        .catch((error) => {
          // The document probably doesn't exist.
          toast.error('Error updating singele booking: ', error)
          console.log(error)
        })
    } else if (bookingType === 'recurring') {
      // if booking type is recurring then update recurring status to false.
      return bookingRef
        .update({
          isRecurring: false,
        })
        .then(() => {
          toast.success('Recurring bookings successfully cancelled.')
          getUserBookings()
          setFilteredBookings([])
          setBookingLimit(5)
        })
        .catch((error) => {
          // The document probably doesn't exist.
          toast.error('Error updating booking: ', error)
        })
    } else {
      toast.error('Error Updating Booking')
    }
  }

  return (
    <BookingCard>
      <Header>
        <h5>{bookingType} Bookings</h5>
        {filteredBookings.length < 1 && (
          <button type='button' onClick={handleOnClick}>
            View Bookings
          </button>
        )}
      </Header>
      <Bookings>
        {filteredBookings.length > 0 &&
          filteredBookings.map((booking, index) => (
            <UserBookingContainer bookingType={bookingType} key={index}>
              <UserBooking bookingType={bookingType} booking={booking} />

              {bookingType !== 'past' && (
                <Button
                  type='button'
                  disabled={
                    bookingType !== 'recurring' &&
                    booking.data.status === 'cancelled'
                  }
                  onClick={() => cancelBooking(booking.id)}
                >
                  {bookingType !== 'recurring' &&
                  booking.data.status === 'cancelled'
                    ? booking.data.status
                    : 'Cancel'}
                </Button>
              )}
            </UserBookingContainer>
          ))}
      </Bookings>
      {filteredBookings.length < baseBookings.length &&
        filteredBookings.length > 0 && (
          <MoreButton onClick={handleOnClick}>More</MoreButton>
        )}
      {filteredBookings.length > 0 && (
        <CloseButton
          onClick={() => {
            setFilteredBookings([])
            setBookingLimit(5)
          }}
        >
          close
        </CloseButton>
      )}
    </BookingCard>
  )
}

export default UserBookingsCard
