import { Box, Button, Stack, Typography } from '@mui/material'

const Timeslot = ({ studio, t, onSelect, selectedTime, index }) => {
  const selected = selectedTime === index

  return (
    <Stack
      disabled={!t.isAvailable}
      variant={selected ? 'contained' : 'outlined'}
      key={t.id}
      component={Button}
      sx={{ p: 1 }}
      onClick={onSelect}
    >
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        letterSpacing={1}
        sx={{ width: '100%' }}
      >
        <Typography variant='body2' color={'inherit'}>
          {t.startTime.format('HH:mmA')} - {t.endTime.format('HH:mmA')}
        </Typography>
      </Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        sx={{ width: '100%' }}
      >
        <Typography color={'inherit'} variant='body2'>
          £{studio.price}
        </Typography>
        <Typography color={'inherit'} variant='body2'>
          {studio.price} Credits
        </Typography>
      </Box>
    </Stack>
  )
}

export default Timeslot
