import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import Span from '../../../components/Span'

const Studios = () => {
  return (
    <Box>
      <Typography
        variant='h2'
        fontSize={'45px'}
        fontWeight={400}
        gutterBottom
        color='white.main'
      >
        Your own
        <Span> private </Span>
        training facility.
      </Typography>
      <Typography mb={2} color='white.main'>
        Say goodbye to crowded gym floors and distractions. Our private studios
        offer an intimate space for you to concentrate solely on achieving your
        fitness goals. Train alone, with friends or clinets. HYVE studios are
        available to members and non-members.
      </Typography>

      <Link to='/gyms/llanelli/studios'>
        <Button variant='contained'>Learn More</Button>
      </Link>
    </Box>
  )
}

export default Studios
