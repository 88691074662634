import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import LowAvailabilityWarning from './LowAvailabilityWarning'
import { SHOW_LOW_AVAILABILITY_ALERT_COUNT } from '../helpers'

const ClassCard = ({ gymClass }) => {
  const startTime = gymClass.startTime.toDate()
  const remaining =
    gymClass.class.capacity - gymClass.class.enrolledMembers.length
  const soldOut = remaining <= 0

  return (
    <Link to={`/classes/${gymClass.id}`}>
      <Box
        sx={{
          background: `url(${gymClass.class.coverImage}), radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3029586834733894) 23%, rgba(0,0,0,0.4559217436974789) 41%, rgba(0,0,0,1) 100%, rgba(0,0,0,0.6559217436974789) 100%)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundBlendMode: 'multiply',
          height: 280,
          backgroundSize: 'cover',
          borderRadius: 1,
          borderBottom: (t) =>
            `5px solid ${soldOut ? 'red' : t.palette.primary.main}`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: 2,
          '&:hover': {
            background: `url(${gymClass.class.coverImage}), radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2929586834733894) 23%, rgba(0,0,0,0.4559217436974789) 41%, rgba(0,0,0,.8) 100%, rgba(0,0,0,0.6559217436974789) 100%)`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundBlendMode: 'multiply',
            backgroundSize: 'cover',
          },
        }}
      >
        <Box
          display={'flex'}
          justifyContent={`${
            remaining <= SHOW_LOW_AVAILABILITY_ALERT_COUNT && !soldOut
              ? 'space-between'
              : 'flex-end'
          }`}
        >
          <LowAvailabilityWarning
            capacity={gymClass.class.capacity}
            enrolled={gymClass.class.enrolledMembers.length}
          />

          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            gap={0.2}
          >
            <Box
              sx={{
                border: (t) => `1px solid ${t.palette.primary.main}`,
                background: '#00000080',
                display: 'flex',
                padding: '8px 18px',
                color: 'white.main',
                flexDirection: 'column',
                textAlign: 'center',
                borderRadius: 0.5,
              }}
            >
              <Typography lineHeight={1} variant='body2'>
                {dayjs(startTime).format('ddd')}
              </Typography>
              <Typography variant='h6' fontWeight={800} lineHeight={1}>
                {dayjs(startTime).format('DD')}
              </Typography>
              <Typography lineHeight={1} variant='body2'>
                {dayjs(startTime).format('MMM')}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              gap={0.5}
              mt={0.5}
              width={'100%'}
              justifyContent={'center'}
              sx={{
                border: (t) => `1px solid ${t.palette.primary.main}`,
                background: '#00000080',
                borderRadius: 0.5,
              }}
            >
              <Typography variant='body2' py={0.2}>
                {dayjs(startTime).format('HH:mm')}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box>
            <Typography variant='h6' color='white.main'>
              {gymClass.class.title}
            </Typography>
            <Typography
              variant='body2'
              color='white.main'
              fontWeight={'light'}
              textTransform='capitalize'
            >
              {gymClass.user.brand ??
                `${gymClass.user.firstName} ${gymClass.user.lastName}`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Link>
  )
}

export default ClassCard
