import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import './style.scss'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { firestore } from '../../../utils/firebase/firebase'
import { Fade } from 'react-awesome-reveal'
import { Box, CircularProgress } from '@mui/material'
import { createBooking } from '../../../utils/helpers'
import { toast } from 'react-toastify'

function Confirm({ currentUser }) {
  const [error, setError] = useState('')
  const [confirmed, setConfirmed] = useState(false)
  const [bookingSession, setBookingSession] = useState({})
  const [isCreditsRequired, setIsCreditsRequired] = useState(true)
  const [creditsRequired, setCreditsRequired] = useState(0)
  const [loading, setLoading] = useState(true)
  const [redirecting, setRedirecting] = useState(false)

  //Get the last user booking session.
  useEffect(() => {
    currentUser &&
      firestore
        .collection('users')
        .doc(currentUser.id)
        .collection('booking_sessions')
        .orderBy('bookingRequest.createdAt', 'desc')
        .limit(1)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log('Data ====>>> ', doc.data())
            setBookingSession({
              location: doc.data().bookingRequest.location.name,
              studio: doc.data().bookingRequest.studio.name,
              studioId: doc.data().bookingRequest.studio.id,
              startTime: doc.data().bookingRequest.startTime,
              date: doc.data().bookingRequest.startTime.seconds,
              credits: doc.data().bookingRequest.bookingDetails.credits,
              capacity: doc.data().bookingRequest.studio.capacity,
              bookingRequest: doc.data().bookingRequest,
              time: doc.data().bookingRequest.bookingDetails.time,
            })

            setCreditsRequired(
              doc.data().bookingRequest.bookingDetails.credits -
                currentUser.credits
            )

            currentUser.credits >=
              doc.data().bookingRequest.bookingDetails.credits &&
              setIsCreditsRequired(false)
          })
          console.log('SessionData ====>>> ', bookingSession)
          setLoading(false)
        })
        .catch((error) => {
          console.log('Error getting documents: ', error)
          setLoading(false)
        })

    if (currentUser) {
      bookingSession.credits < currentUser.credits &&
        setIsCreditsRequired(false)
      console.log('Session Credits:', bookingSession.credits)
      console.log('user Credits:', currentUser.credits)
      setCreditsRequired(bookingSession.credits - currentUser.credits)
      console.log(bookingSession.credits, currentUser.credits)
    }
    //eslint-disable-next-line
  }, [currentUser])

  //Set booking session to state
  const sendToSingleCheckout = async (user, qty) => {
    setRedirecting(true)
    const docRef = await firestore
      .collection('users')
      .doc(user)
      .collection('checkout_sessions')
      .add({
        mode: 'payment',
        price: `price_1Jo8ieLxp1x7fxstJntYi6ux`,
        allow_promotion_codes: false,
        success_url: `${window.location.origin}/availability/confirm`,
        cancel_url: window.location.origin,
        quantity: qty,
      })
    // Wait for the CheckoutSession to get attached by the extension

    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data()
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`)
        setRedirecting(false)
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url)
      }
    })
  }

  const handleSubmit = async () => {
    try {
      await createBooking(bookingSession.bookingRequest).then((r) => {
        // Reduce user credit balance
        const creditRef = firestore.collection('users').doc(currentUser.id)
        creditRef.update({
          credits:
            currentUser.credits -
            bookingSession.bookingRequest.bookingDetails.credits,
        })
        setConfirmed(true)
      })
    } catch (error) {
      toast.error(error.message)
      setError(error.message)
    }
  }

  return loading ? (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      minHeight={'80vh'}
    >
      <CircularProgress />
    </Box>
  ) : (
    <div className='confirm__wrapper'>
      {!confirmed ? (
        <Fade>
          <div className='confirm__details'>
            <h1>Confirm your Booking</h1>
            <div className='confirm__location'>
              HYVE {bookingSession.location}
            </div>
            <div className='confirm__studio'>{bookingSession.studio}</div>
            <div className='confirm__date'>
              {dayjs.unix(bookingSession.date).format('dddd DD MMMM')}
            </div>
            <div className='confirm__time'>{bookingSession.time}</div>
            <div className='confirm__studioCapacity'>
              Max capacity {bookingSession.capacity} person
            </div>
            {!isCreditsRequired ? (
              <>
                <div className='confirm__bookButton' onClick={handleSubmit}>
                  CONFIRM NOW FOR {bookingSession.credits} CREDITS
                </div>
              </>
            ) : (
              <>
                <div style={{ color: 'red', fontSize: '0.8rem' }}>
                  You need to purchase credits to book this studio
                </div>
                <div
                  className='confirm__bookButton'
                  onClick={() => {
                    sendToSingleCheckout(currentUser.id, creditsRequired)
                  }}
                >
                  {redirecting
                    ? 'Redirecting...'
                    : `BUY ${creditsRequired} CREDITS`}
                </div>
              </>
            )}

            <Link to={`/availability?studio=${bookingSession.studioId}`}>
              <div className='confirm__backButton'>Back to Availability</div>
            </Link>

            {error && <div className='confirm__error'>{error}</div>}
          </div>
        </Fade>
      ) : (
        <Fade direction='down'>
          <div className='confirm__details'>
            <h1>Thanks, YOUR BOOKING IS CONFIRMED!</h1>
            <div className='confirm__location'>
              HYVE {bookingSession.location}
            </div>
            <div className='confirm__studio'>{bookingSession.studio}</div>
            <div className='confirm__date'>
              {dayjs.unix(bookingSession.date).format('dddd DD MMMM')}
            </div>
            <div className='confirm__time'>{bookingSession.time}</div>
            <div className='confirm__studioCapacity'>
              Max capacity {bookingSession.capacity} person
            </div>

            <Link to='/account?studios'>
              <div className='confirm__accountButton'>MY BOOKINGS</div>
            </Link>
          </div>
        </Fade>
      )}
    </div>
  )
}

const mapStateToProps = ({ system, user }) => ({
  currentUser: user.currentUser,
  bookingRequest: system.bookingRequest,
  loading: system.loading,
})

export default connect(mapStateToProps)(Confirm)
