import { Helmet } from 'react-helmet'
import { Container, Box, Typography } from '@mui/material'
import logo from '../../../assets/images/hyve-express.png'
import { useEffect } from 'react'

const Drefach = () => {
  useEffect(() => {
    window.location.href = 'https://www.gyms4everyone.com'
  }, [])
  return (
    <Container>
      <Helmet>
        <script async src='https://js.stripe.com/v3/pricing-table.js'></script>
      </Helmet>
      <Box sx={{ width: '200px', margin: 'auto', py: 2 }}>
        <img src={logo} alt='hyve express' style={{ width: '100%' }} />
      </Box>
      <Typography
        variant='h6'
        align='center'
        gutterBottom
        sx={{ color: (t) => t.palette.common.white }}
      >
        Join Hyve Express Drefach today!
      </Typography>
      <stripe-pricing-table
        pricing-table-id='prctbl_1OHW7CLxp1x7fxstF7G29uvU'
        publishable-key='pk_live_51J1bYsLxp1x7fxstawNYnEMH7AYaUF6djPXhPuBCQYWHoXBgBO2hmGVOx5SZtn6ugjekqOgmcXVA6u9gdsbLu69D00OmxsC1Mc'
      ></stripe-pricing-table>
    </Container>
  )
}

export default Drefach
