import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  doc,
  updateDoc,
} from 'firebase/firestore'
import firebase, { newFirestore } from './firebase/firebase'
import dayjs from 'dayjs'

export const timestampCheck = (date) => {
  if (!(date instanceof firebase.firestore.Timestamp)) {
    const newDate = firebase.firestore.Timestamp.fromDate(dayjs(date).toDate())
    newDate.nanoseconds = 0
    return newDate
  }
  return date
}

export const checkOverlappingBooking = async (
  startTime,
  endTime,
  studioId,
  bookingId
) => {
  const bookingsCollection = collection(newFirestore, 'bookings')
  const matchesStudio = where('studio.id', '==', studioId)
  const isConfirmed = where('status', '==', 'confirmed')
  const timeNow = firebase.firestore.Timestamp.fromDate(
    dayjs().hour(0).minute(0).toDate()
  )
  startTime = timestampCheck(startTime)
  endTime = timestampCheck(endTime)
  //Get all future bookings from firestore
  const q = query(
    bookingsCollection,
    matchesStudio,
    isConfirmed,
    where('startTime', '>', timeNow)
  )

  const querySnapshot = await getDocs(q)

  querySnapshot.forEach((doc) => {
    const booking = doc.data()
    console.log('booking:', booking)
    booking.id = doc.id
    if (
      booking.startTime >= startTime &&
      booking.startTime < endTime &&
      booking.id !== bookingId
    ) {
      console.log('trigger test1:', doc.id)
      throw new Error('Booking overlaps with existing booking')
    }
    //Condition 2: Check for existing bookings ending during the booking request
    if (
      booking.endTime > startTime &&
      booking.endTime <= endTime &&
      booking.id !== bookingId
    ) {
      console.log('trigger test2:', doc.id)
      throw new Error('Booking overlaps with existing booking')
    }
    //Condition 3: Check for existing bookings starting before and ending after the booking request
    if (
      booking.startTime < startTime &&
      booking.endTime >= endTime &&
      booking.id !== bookingId
    ) {
      console.log('trigger test3:', doc.id)
      throw new Error('Booking overlaps with existing booking')
    }
  })

  return false
}

export const createBooking = async (booking) => {
  try {
    await checkOverlappingBooking(
      timestampCheck(booking.startTime),
      timestampCheck(booking.endTime),
      booking.studio.id,
      booking.id
    )
    const docRef = await addDoc(collection(newFirestore, 'bookings'), booking)
    console.log('Booking written with ID: ', docRef.id)
    return docRef.id
  } catch (error) {
    console.log(error.message)
    throw new Error(`${error.message}`)
  }
}

export const changeBooking = async (id, newStart, newEnd, newStudioId) => {
  const studioMap = {
    TSp2LZix7eGLiNXgdWBW: 'Studio 4',
    ZkxxmrSfsWYDv5RerP51: 'Wattbikes',
    jlqBiWXgO4ITUWLHrfes: 'Studio 6',
    kM8p1jSenI4M0Mr1PzBo: 'Studio 5',
    t4HqiPsnmZ79FSzUrKtt: 'Studio 1',
    xu8if6J5KizyDieVileo: 'Endless Pool',
    IAU4CwQKmedKSYf1LRE1: 'Studio 7',
  }

  if (!(newStart instanceof firebase.firestore.Timestamp)) {
    newStart = firebase.firestore.Timestamp.fromDate(
      dayjs(newStart).second(0).toDate()
    )
  }

  if (!(newEnd instanceof firebase.firestore.Timestamp)) {
    newEnd = firebase.firestore.Timestamp.fromDate(
      dayjs(newEnd).second(0).toDate()
    )
  }

  try {
    await checkOverlappingBooking(newStart, newEnd, newStudioId, id)
    const bookingRef = doc(newFirestore, 'bookings', id)

    await updateDoc(bookingRef, {
      startTime: newStart,
      endTime: newEnd,
      'studio.id': newStudioId,
      'studio.name': studioMap[newStudioId],
    })
    console.log('Booking updated  with ID: ', bookingRef.id)
    return bookingRef.id
  } catch (error) {
    console.log(error.message)
    throw new Error(`${error.message}`)
  }
}

export const dayOfWeek = (date) => {
  const day = date.getDay()
  return isNaN(day)
    ? null
    : [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
      ][day]
}

export default function nFormatter(
  num,
  opts = {
    digits: 1,
  }
) {
  if (!num) return '0'
  if (opts.full) {
    return Intl.NumberFormat('en-US').format(num)
  }
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value
    })
  return item
    ? (num / item.value).toFixed(opts.digits).replace(rx, '$1') + item.symbol
    : '0'
}
