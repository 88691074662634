import { Header, MembershipDetails } from '../user/User.styles'
import { Box, Button, Typography } from '@mui/material'
import MembershipCard from '../user/components/membershipCard/MembershipCard'
import AddMembershipModal from './AddMembershipModal'
import { useState } from 'react'

function Memberships({ subscriptions }) {
  const [addNew, setAddNew] = useState(false)

  const toggleAddNew = () => {
    setAddNew((ps) => !ps)
  }
  return (
    <MembershipDetails>
      <Box display={'flex'} justifyContent={'space-between'} sx={{ mb: 2 }}>
        <Header>
          <Typography variant='h5'>Membership details</Typography>
        </Header>
        <Button variant='contained' onClick={toggleAddNew}>
          Create New membership
        </Button>
      </Box>
      {subscriptions < 1 && <p>No current or past memberships</p>}
      {subscriptions.map((sub, index) => (
        <MembershipCard sub={sub} key={index} />
      ))}
      <AddMembershipModal open={addNew} handleClose={toggleAddNew} />
    </MembershipDetails>
  )
}

export default Memberships
