import { Box, Button, TextField, Typography } from '@mui/material'
import { doc, updateDoc } from 'firebase/firestore'
import React, { useState } from 'react'
import { newFirestore } from '../../../utils/firebase/firebase'
import { toast } from 'react-toastify'

const Brand = ({ currentUser }) => {
  const defaultBrand =
    currentUser.brand ?? `${currentUser.firstName} ${currentUser.lastName}`
  const [value, setValue] = useState(defaultBrand)

  const handleUpdate = async () => {
    const userRef = doc(newFirestore, 'users', currentUser.id)

    try {
      value &&
        (await updateDoc(userRef, {
          brand: value,
        }))
      toast.success('Brand updated successfully.')
    } catch (error) {
      toast.error('Error updating brand. Try again.')
    }
  }
  return (
    <Box>
      <Typography variant='h5' color='white.main'>
        Brand
      </Typography>
      <Typography variant='body2' mb={2}>
        By default, each class you host as a PT at HYVE references you as the
        personal trainer by name. Update your brand to market classes under your
        custom name.{' '}
      </Typography>
      <Box display={'flex'} gap={1}>
        <TextField
          label='Custom brand&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
          variant='outlined'
          size='small'
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <Button
          variant='contained'
          onClick={handleUpdate}
          disabled={!value || value === defaultBrand}
        >
          Submit Change
        </Button>
      </Box>
    </Box>
  )
}

export default Brand
