import React, { useState } from 'react'
import './styles.scss'
import HyveInput from '../../../../../components/brand/input/Input.component'
import HyveButton from '../../../../../components/brand/button/Button.component'
import { Link } from 'react-router-dom'
import firebase from '../../../../../utils/firebase/firebase'

function PasswordResetForm() {
  const [error, setError] = useState('')
  const [state, setState] = useState({
    email: '',
    loading: false,
    success: false,
  })

  const handleChange = (e) => {
    const { value, name } = e.target
    setState({ ...state, [name]: value })
    setError('')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setState({ ...state, loading: true })
    firebase
      .auth()
      .sendPasswordResetEmail(state.email)
      .then(() => {
        setState({ success: true, loading: false, email: '' })
      })
      .catch((error) => {
        var errorMessage = error.message
        setError(errorMessage)
      })
    setState({ ...state, loading: false })
  }

  return (
    <>
      <form className='loginForm' onSubmit={handleSubmit}>
        <HyveInput
          type='email'
          name='email'
          required
          value={state.email}
          onChange={handleChange}
          dark
          disabled={state.success}
        >
          Email Address
        </HyveInput>

        <HyveButton
          disabled={!state.email || state.success}
          fill
          type='submit'
          dark
        >
          Reset Password
        </HyveButton>
      </form>
      <p style={{ fontSize: 16 }} className='login__error'>
        {error &&
          !state.success &&
          'Your request failed, please check your email and try again.'}
      </p>
      {state.success && (
        <p style={{ fontSize: 14 }} className='request__success'>
          Reset email sent ✔️ Check your inbox for a link to reset your password
        </p>
      )}
      <Link to='/login'>
        <p style={{ fontSize: 14 }} className='login__forgotPassword'>
          back to login
        </p>
      </Link>
    </>
  )
}

export default PasswordResetForm
