import { UserBookingContainer } from './UserBooking.styles'
import dayjs from 'dayjs'

function UserBooking({ booking, bookingType }) {
  return (
    <UserBookingContainer
      bookingType={bookingType}
      background={bookingType !== 'recurring' && booking.data.status}
    >
      {bookingType !== 'recurring' ? (
        <>
          <p>{dayjs(booking.data.startTime.toDate()).format('ddd DD MMM')}</p>
          <p> {dayjs(booking.data.startTime.toDate()).format('HH:mm')}</p>
          <p>{booking.data.studio.name}</p>
          <p>{booking.data.bookingDetails.price} CREDITS</p>
        </>
      ) : (
        <>
          <p>{dayjs(booking.data.startTime.toDate()).format('ddd')}</p>
          <p>{dayjs(booking.data.startTime.toDate()).format('HH:mm')}</p>
          <p>
            From:{' '}
            {booking.data.start &&
              dayjs(booking.data.start.toDate()).format('DD MMM YY')}
          </p>
          <p>{booking.data.studio.name}</p>
          <p>
            {booking.data.recurringWeek}/
            {booking.data.recurringCount === -1
              ? '-'
              : booking.data.recurringCount}
          </p>
        </>
      )}
    </UserBookingContainer>
  )
}

export default UserBooking
