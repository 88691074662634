import {
  Alert,
  AlertTitle,
  Box,
  Chip,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from '@mui/material'
import React from 'react'
import useStudios from '../../../studios/useStudios'
import { NavLink } from 'react-router-dom'

const StyledCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.black,
}))

const Studios = () => {
  const { studios, loading, error } = useStudios(false)

  if (error)
    return (
      <Alert>
        <AlertTitle>Error</AlertTitle>
        {error}
      </Alert>
    )

  if (loading)
    return (
      <Box
        sx={{
          minHeight: '70vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    )

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead
          sx={{
            background: (t) => t.palette.primary.main,
          }}
        >
          <TableRow>
            <StyledCell>Title</StyledCell>
            <StyledCell>Status</StyledCell>
            <StyledCell>Price (£ / Credits)</StyledCell>
            <StyledCell>Block (mins)</StyledCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {studios.map((row) => (
            <TableRow
              key={row.id}
              component={NavLink}
              to={`/admin/studios/${row.id}`}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  background: (t) => t.palette.secondary.dark,
                },
              }}
            >
              <TableCell component='th'>{row.name}</TableCell>
              <TableCell>
                {row.isActive ? (
                  <Chip
                    label='Published'
                    variant='outlined'
                    color='primary'
                    sx={{ borderRadius: 1, letterSpacing: 0.5 }}
                  />
                ) : (
                  <Chip
                    label='Draft'
                    variant='outlined'
                    color='info'
                    sx={{ borderRadius: 1, letterSpacing: 0.5 }}
                  />
                )}
              </TableCell>
              <TableCell>{row.price}</TableCell>
              <TableCell>{row.block}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default Studios
