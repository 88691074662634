import { NavLink, useLocation } from 'react-router-dom'
import { Button, Link, Box, Divider, useMediaQuery } from '@mui/material'
import BarChartIcon from '@mui/icons-material/BarChart'
import EventIcon from '@mui/icons-material/Event'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics'
import PeopleIcon from '@mui/icons-material/People'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'

function AdminNav() {
  const { pathname } = useLocation()
  const location = pathname.replace('/admin', '').replace('/', '')
  const mobile = useMediaQuery('(max-width:600px)')

  const links = [
    {
      title: 'dashboard',
      link: '/admin/dashboard',
      icon: <BarChartIcon fontSize='small' />,
    },
    {
      title: 'calendar',
      link: '/admin/calendar',
      icon: <EventIcon fontSize='small' />,
    },
    {
      title: 'book',
      link: '/admin/book',
      icon: <AddCircleIcon fontSize='small' />,
    },
    {
      title: 'classes',
      link: '/admin/classes',
      icon: <SportsGymnasticsIcon fontSize='small' />,
    },
    {
      title: 'users',
      link: '/admin/users',
      icon: <PeopleIcon fontSize='small' />,
    },
    {
      title: 'single gym visit',
      link: 'https://buy.stripe.com/bIYdTe9C2cIPcus289',
      icon: <AttachMoneyIcon fontSize='small' />,
    },
    {
      title: 'podium',
      link: '/admin/podium',
      icon: <EmojiEventsIcon fontSize='small' />,
    },
    // {
    //   title: 'settings',
    //   link: '/admin/settings',
    //   icon: <SettingsApplicationsIcon fontSize='small' />,
    // },
  ]
  return (
    <Box mb={1}>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          position: 'sticky',
          top: 60,
          alignSelf: 'flex-start',
          gap: 1,
          width: '100%',
          mb: 1,
        }}
      >
        {links.map((l, i) =>
          l.link.substring(0, 5) === 'https' ? (
            <Button
              component={Link}
              key={l + i}
              href={l.link}
              target='_blank'
              variant={location === l.title ? 'contained' : 'outlined'}
              sx={{ flexGrow: 1 }}
            >
              {l.icon} {!mobile && l.title}
            </Button>
          ) : (
            <Button
              key={l + i}
              variant={location === l.title ? 'contained' : 'outlined'}
              component={NavLink}
              to={l.link}
              sx={{ flexGrow: 1 }}
            >
              {l.icon} &nbsp;{!mobile && l.title}
            </Button>
          )
        )}
      </Box>
      <Divider />
    </Box>
  )
}

export default AdminNav
