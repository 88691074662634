import React from 'react';
import './styles.scss';

function HyveInput({
	type,
	name,
	required,
	value,
	children,
	onChange,
	pattern,
	dark,
	placeholder,
	disabled,
}) {
	return (
		<div className={dark ? 'hyveInput dark' : 'hyveInput'}>
			<input
				className={dark ? 'hyveInputField dark' : 'hyveInputField'}
				type={type}
				name={name}
				required={required}
				value={value}
				onChange={onChange}
				pattern={pattern}
				placeholder={placeholder}
				disabled={disabled}
			/>
			<label className={dark && 'dark'} htmlFor={name}>
				{children}
			</label>
		</div>
	);
}

export default HyveInput;
