import React from 'react'
import Page from '../../../components/page/Page'
import { Box, Button, Divider, Typography, Link, Stack } from '@mui/material'
import { NavLink } from 'react-router-dom'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'

import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'

import useStudio from '../useStudio'

const Studio = () => {
  const { id } = useParams()
  const { studio, loading, error } = useStudio(id)
  const hours = studio && studio.openingHours

  return (
    <Page loading={loading} error={error}>
      {!loading && !error && (
        <>
          <Box
            sx={{
              background: `url(${studio.coverImage}), linear-gradient(270deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3029586834733894) 23%, rgba(0,0,0,0.6559217436974789) 41%, rgba(0,0,0,1) 100%, rgba(0,0,0,0.6559217436974789) 100%);`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundBlendMode: 'multiply',
              height: 300,
              backgroundSize: 'cover',
              borderRadius: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <Typography variant='h4' color={'white.main'}>
              {studio.name}
            </Typography>
            <Typography
              mb={1}
              variant='body2'
              fontWeight={'light'}
              color={'primary'}
            >
              £{studio.price} or {studio.price} credits for {studio.block}{' '}
              minutes
            </Typography>
            <Divider sx={{ mb: 2 }} />
          </Box>
          <Box display={'flex'} gap={1} my={1} flexWrap={'wrap'} maxWidth={500}>
            <Button
              size='large'
              component={NavLink}
              to={`/availability?studio=${id}`}
              variant='contained'
              startIcon={<CalendarMonthIcon />}
              sx={{ flexGrow: 1 }}
            >
              Check availability
            </Button>

            <Link
              to='/gyms/llanelli/studios'
              sx={{ flexGrow: 1 }}
              component={NavLink}
            >
              <Button size='large' variant='outlined' fullWidth>
                View more studios
              </Button>
            </Link>
          </Box>
          <Typography maxWidth={'900px'} mb={2} mt={1}>
            {studio.description}
          </Typography>
          <Stack gap={2}>
            {studio.equipment && (
              <Box maxWidth={'1200px'}>
                <Typography color={'primary'}>Equipment</Typography>
                <Box display={'grid'} gridTemplateColumns={'1fr 1fr 1fr'}>
                  {studio.equipment.map((e) => (
                    <Typography variant='body2'>{e}</Typography>
                  ))}
                </Box>
              </Box>
            )}
            {studio.size && (
              <Box>
                <Typography color={'primary'}>Size</Typography>
                <Typography variant='body2'>{studio.size} SQ.FT</Typography>
              </Box>
            )}
            {studio.openingHours && (
              <Box>
                <Typography color={'primary'}>Opening hours</Typography>
                <Box display={'grid'} gridTemplateColumns={'1fr 1fr'}>
                  <Typography>Sunday</Typography>
                  <Typography variant='body2'>
                    from {hours.sunday.open.hour}:{hours.sunday.open.minute} to{' '}
                    {hours.sunday.close.hour}:{hours.sunday.close.minute}
                  </Typography>
                </Box>
                <Box display={'grid'} gridTemplateColumns={'1fr 1fr'}>
                  <Typography>Monday</Typography>
                  <Typography variant='body2'>
                    from {hours.monday.open.hour}:{hours.monday.open.minute} to{' '}
                    {hours.monday.close.hour}:{hours.monday.close.minute}
                  </Typography>
                </Box>
                <Box display={'grid'} gridTemplateColumns={'1fr 1fr'}>
                  <Typography>Tuesday</Typography>
                  <Typography variant='body2'>
                    from {hours.tuesday.open.hour}:{hours.tuesday.open.minute}{' '}
                    to {hours.tuesday.close.hour}:{hours.tuesday.close.minute}
                  </Typography>
                </Box>
                <Box display={'grid'} gridTemplateColumns={'1fr 1fr'}>
                  <Typography>Wednesday</Typography>
                  <Typography variant='body2'>
                    from {hours.wednesday.open.hour}:
                    {hours.wednesday.open.minute} to{' '}
                    {hours.wednesday.close.hour}:{hours.wednesday.close.minute}
                  </Typography>
                </Box>
                <Box display={'grid'} gridTemplateColumns={'1fr 1fr'}>
                  <Typography>Thursday</Typography>
                  <Typography variant='body2'>
                    from {hours.thursday.open.hour}:{hours.thursday.open.minute}{' '}
                    to {hours.thursday.close.hour}:{hours.thursday.close.minute}
                  </Typography>
                </Box>
                <Box display={'grid'} gridTemplateColumns={'1fr 1fr'}>
                  <Typography>Friday</Typography>
                  <Typography variant='body2'>
                    from {hours.friday.open.hour}:{hours.friday.open.minute} to{' '}
                    {hours.friday.close.hour}:{hours.friday.close.minute}
                  </Typography>
                </Box>
                <Box display={'grid'} gridTemplateColumns={'1fr 1fr'}>
                  <Typography>Saturday</Typography>
                  <Typography variant='body2'>
                    from {hours.saturday.open.hour}:{hours.saturday.open.minute}{' '}
                    to {hours.saturday.close.hour}:{hours.saturday.close.minute}
                  </Typography>
                </Box>
              </Box>
            )}
          </Stack>
        </>
      )}
    </Page>
  )
}

const mapStateToProps = ({ user, subscription }) => ({
  currentUser: user.currentUser,
  subscriptionData: subscription.currentSubscription,
})

export default connect(mapStateToProps, null)(Studio)
