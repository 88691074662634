import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import Span from '../../../components/Span'

const Trainers = () => {
  return (
    <Box>
      <Typography
        variant='h2'
        fontSize={'45px'}
        fontWeight={400}
        gutterBottom
        color='white.main'
      >
        We
        <Span> support </Span>
        personal trainers.
      </Typography>
      <Typography mb={2} color='white.main'>
        Begin your journey as a personal trainer with our low-cost,
        zero-commitment studio rentals and open gym floor access. Unlock your
        potential and transform lives in our facilities. Start making an impact
        today!
      </Typography>
      <Link to='/gyms/llanelli/studios'>
        <Button variant='contained'>Learn More</Button>
      </Link>
    </Box>
  )
}

export default Trainers
