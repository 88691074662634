import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { useEffect, useState } from 'react'
import firebase, { newFirestore } from '../../utils/firebase/firebase'

const useUserWaitlistClasses = (userId) => {
  const [waitlistClasses, setWaitlistClasses] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  useEffect(() => {
    const classesRef = collection(newFirestore, 'bookings')
    const q = query(
      classesRef,
      where('isClass', '==', true),
      where('status', '==', 'confirmed'),
      where(
        'startTime',
        '>',
        firebase.firestore.Timestamp.fromDate(new Date())
      ),
      orderBy('startTime', 'asc')
    )

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const classes = []
      querySnapshot.forEach(
        (doc) => {
          doc.data().class.waitlistMembers.find((m) => m.id === userId)
          if (doc.data().class.waitlistMembers.find((m) => m.id === userId)) {
            classes.push({ id: doc.id, ...doc.data() })
          }
        },
        (error) => {
          setError('Error retrieving classes: ', error.message)
          setLoading(false)
        }
      )
      setWaitlistClasses(classes)
      setLoading(false)
    })

    return () => {
      unsubscribe()
    }
  }, [userId])

  return { waitlistClasses, loading, error }
}

export default useUserWaitlistClasses
