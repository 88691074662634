import { useParams } from 'react-router'
import firebase, { firestore } from '../../../../../../utils/firebase/firebase'
import { useState } from 'react'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material'
import Loader from '../../../../../../components/Loader'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

function AdminNotes({ user, adminUser }) {
  const [newNote, setNewNote] = useState('')
  const [clickedAdd, setClickedAdd] = useState(false)
  const params = useParams()

  const removeNote = (note) => {
    var noteRef = firestore.collection('users').doc(params.uid)

    noteRef.update({
      notes: firebase.firestore.FieldValue.arrayRemove(note),
    })
  }

  const addNote = (e) => {
    e.preventDefault()
    setNewNote('')
    setClickedAdd(false)
    var notesRef = firestore.collection('users').doc(params.uid)

    try {
      if (user.notes) {
        notesRef.update({
          notes: firebase.firestore.FieldValue.arrayUnion(newNote),
        })
      } else {
        notesRef.set({ notes: [newNote] }, { merge: true })
      }
      toast.success('Note added')
    } catch (error) {
      toast.error('Error adding note')
    }
  }

  if (!user) return <Loader />

  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'start'}
      >
        <Box>
          <Typography variant='h5' sx={{ color: 'white!important' }}>
            Admin notes
          </Typography>
          <Typography variant='body2'>
            Comments visible to admin only
          </Typography>
        </Box>
        {!clickedAdd && (
          <Button variant='contained' onClick={() => setClickedAdd(true)}>
            Add new
          </Button>
        )}
      </Box>
      {clickedAdd && (
        <Box
          sx={{ mt: 2 }}
          component={'form'}
          display={'flex'}
          gap={1}
          onSubmit={addNote}
          mb={5}
        >
          <TextField
            variant='outlined'
            placeholder='Add a new note'
            fullWidth
            value={newNote}
            size='small'
            onChange={(e) => setNewNote(e.target.value)}
          />
          <Button type='submit' variant='contained'>
            Submit
          </Button>
          <Button
            type='submit'
            variant='outlined'
            onClick={() => setClickedAdd(false)}
          >
            Cancel
          </Button>
        </Box>
      )}
      <Box sx={{ mt: 2 }} display={'flex'} gap={1} flexDirection={'column'}>
        {!!user.notes && user.notes.length > 0 ? (
          user.notes.map((note, i) => (
            <Box display={'flex'} gap={1} key={note + i}>
              <TextField disabled value={note} size='small' fullWidth />
              <Button
                variant='contained'
                color='error'
                onClick={() => removeNote(note)}
              >
                Delete
              </Button>
            </Box>
          ))
        ) : (
          <Alert severity='info'>
            <AlertTitle>No notes</AlertTitle>
          </Alert>
        )}
      </Box>
    </Box>
  )
}

const mapStateToProps = ({ user }) => ({
  adminUser: user.currentUser,
})

export default connect(mapStateToProps, null)(AdminNotes)
