import React, { useState } from 'react'
import dayjs from 'dayjs'
import './styles.scss'
import { GiBeehive, GiRecycle, GiTwoCoins } from 'react-icons/gi'
import { CgGym, CgMouse } from 'react-icons/cg'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { VscPerson } from 'react-icons/vsc'
import { RiDoorFill } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics'
import { cancelSingleBooking } from '../../helpers'
function BookingPopup({ selectedEvent, popupToggle, setPopupToggle }) {
  const [cancelBooking, setCancelBooking] = useState(false)
  const [cancelSeries, setCancelSeries] = useState(false)

  const handleClose = (e) => {
    e.preventDefault()
    setPopupToggle(false)
    setCancelBooking(false)
  }

  return (
    <div
      className={
        popupToggle
          ? 'calendar__bookingPopup'
          : 'calendar__bookingPopup disabled'
      }
    >
      {/*
       ** First Page
       ** Booking Details & CancelOptions
       **/}
      {!cancelBooking && !cancelSeries && (
        <div className='calendar__bookingPopupWrapper'>
          <div className='calendar__bookingPopupBody'>
            <h3>Booking Details</h3>
            <div className='row'>
              <GiBeehive />{' '}
              <p>
                <span>Reference:</span> {selectedEvent.eventId}
              </p>
            </div>
            <div className='row'>
              <AiOutlineClockCircle />{' '}
              <p>
                <span>Time:</span> {dayjs(selectedEvent.start).format('HH:mm')}{' '}
                - {dayjs(selectedEvent.end).format('HH:mm')}
              </p>
            </div>
            <div className='row'>
              <VscPerson />{' '}
              <p>
                <span>User:</span>{' '}
                <Link to={`/admin/users/${selectedEvent.uid}`}>
                  {selectedEvent.firstName} {selectedEvent.lastName}
                </Link>
              </p>
            </div>
            <div className='row'>
              <RiDoorFill />{' '}
              <p>
                <span>Studio:</span> {selectedEvent.studio}
              </p>
            </div>
            <div className='row'>
              <CgGym />{' '}
              <p>
                <span>Session:</span> {selectedEvent.description}
              </p>
            </div>
            <div className='row'>
              <CgMouse />{' '}
              <p>
                <span>Booked by:</span> {selectedEvent.channel}
              </p>
            </div>
            <div className='row'>
              <CgMouse />{' '}
              <p>
                <span>Booked on:</span>{' '}
              </p>{' '}
              <p>
                {dayjs(selectedEvent.createdAt).format('ddd DD-MM-YY')} at{' '}
                {dayjs(selectedEvent.createdAt).format('hh:mm')}
              </p>
            </div>
            <div className='row'>
              <GiTwoCoins />{' '}
              <p>
                <span>Credits Paid:</span> {selectedEvent.credits}
              </p>
            </div>
            {selectedEvent.isRecurring && (
              <div className='row'>
                <GiRecycle /> <p>Recurring </p>{' '}
                <p>
                  Session {selectedEvent.recurringWeek} of{' '}
                  {selectedEvent.recurringCount}
                </p>
              </div>
            )}
            {selectedEvent.isClass && (
              <div className='row'>
                <SportsGymnasticsIcon /> <p>Recurring </p>{' '}
                <p>
                  Session {selectedEvent.recurringWeek} of{' '}
                  {selectedEvent.recurringCount}
                </p>
              </div>
            )}
            <div className='bookingPopupButtonContainer'>
              <div
                className='bookingPopupButton'
                onClick={() => setCancelBooking(true)}
              >
                Cancel Booking
              </div>

              {selectedEvent.isRecurring && (
                <div
                  className='bookingPopupButton'
                  onClick={() => setCancelSeries(true)}
                >
                  Cancel Series
                </div>
              )}
            </div>
          </div>
          <div className='calendar__bookingPopupClose' onClick={handleClose}>
            CLOSE
          </div>
        </div>
      )}
      {/*
       ** Second Page
       ** Cancel Booking Confirmation
       **/}
      {cancelBooking && (
        <div className='calendar__bookingPopupWrapper'>
          <div className='calendar__bookingPopupBody'>
            <h3>Are you sure? </h3>
            <h5>You are about to cancel the single session below.</h5>
            {selectedEvent.isRecurring && (
              <h5>
                Recurring bookings <span>will continue</span> in subsequent
                weeks.
              </h5>
            )}
            <br />
            <div className='row'>
              <GiBeehive />{' '}
              <p>
                <span>Reference:</span> {selectedEvent.eventId}
              </p>
            </div>
            <div className='row'>
              <AiOutlineClockCircle />{' '}
              <p>
                <span>Time:</span> {dayjs(selectedEvent.start).format('HH:mm')}{' '}
                - {dayjs(selectedEvent.end).format('HH:mm')}
              </p>
            </div>
            <div className='row'>
              <VscPerson />{' '}
              <p>
                <span>User:</span> {selectedEvent.firstName}{' '}
                {selectedEvent.lastName}
              </p>
            </div>
            <div className='row'>
              <RiDoorFill />{' '}
              <p>
                <span>Studio:</span> {selectedEvent.studio}
              </p>
            </div>
            <div className='row'>
              <GiTwoCoins />{' '}
              <p>
                <span>Credits Paid:</span> {selectedEvent.credits}
              </p>
            </div>
          </div>

          <div
            onClick={(e) => {
              cancelSingleBooking(selectedEvent.eventId)
              handleClose(e)
            }}
            className='calendar__bookingPopupCancel'
          >
            Cancel Booking
          </div>

          <div
            className='calendar__bookingPopupClose'
            onClick={() => {
              setCancelBooking(false)
            }}
          >
            GO BACK
          </div>
        </div>
      )}
      {/*
       ** Second Page
       ** Cancel Series Confirmation
       **/}
      {cancelSeries && (
        <div className='calendar__bookingPopupWrapper'>
          <div className='calendar__bookingPopupBody'>
            <h3>Are you sure?</h3>
            <h5>
              You are about to cancel the session below and the recurring
              booking series.
            </h5>
            {selectedEvent.isRecurring && (
              <h5>
                Recurring bookings <span>will not continue</span> in subsequent
                weeks.
              </h5>
            )}
            <br />
            <div className='row'>
              <GiBeehive />{' '}
              <p>
                <span>Reference:</span> {selectedEvent.eventId}
              </p>
            </div>
            <div className='row'>
              <AiOutlineClockCircle />{' '}
              <p>
                <span>Time:</span> {dayjs(selectedEvent.start).format('HH:mm')}{' '}
                - {dayjs(selectedEvent.end).format('HH:mm')}
              </p>
            </div>
            <div className='row'>
              <VscPerson />{' '}
              <p>
                <span>User:</span> {selectedEvent.firstName}{' '}
                {selectedEvent.lastName}
              </p>
            </div>
            <div className='row'>
              <RiDoorFill />{' '}
              <p>
                <span>Studio:</span> {selectedEvent.studio}
              </p>
            </div>
            <div className='row'>
              <GiTwoCoins />{' '}
              <p>
                <span>Credits Paid:</span> {selectedEvent.credits}
              </p>
            </div>
          </div>
          <div className='calendar__bookingPopupCancel'>Cancel Series</div>
          <div
            className='calendar__bookingPopupClose'
            onClick={() => {
              setCancelSeries(false)
            }}
          >
            GO BACK
          </div>
        </div>
      )}
    </div>
  )
}

export default BookingPopup
