import {
  Alert,
  AlertTitle,
  Paper,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material'
import TableHead from './TableHead'
import TableRow from './TableRow'
import AddMember from './AddMember'

const EnrolledTable = ({ enrolled, isFull, gymClass }) => {
  return (
    <>
      <AddMember isFull={isFull} classId={gymClass.id} />
      <TableContainer component={Paper}>
        {enrolled.length === 0 ? (
          <Alert severity='info'>
            <AlertTitle>Oops</AlertTitle>No members have signed up for this
            class.
          </Alert>
        ) : (
          <Table>
            <TableHead />
            <TableBody>
              {enrolled.map((row) => (
                <TableRow
                  row={row}
                  key={row.id}
                  gymClass={gymClass}
                  source={'enrolled'}
                />
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </>
  )
}

export default EnrolledTable
