import { TableCell, TableRow, TableHead as MUIHeader } from '@mui/material'
import React from 'react'

const TableHead = () => {
  return (
    <MUIHeader
      sx={{
        backgroundColor: (t) => t.palette.primary.main,
      }}
    >
      <TableRow>
        <TableCell sx={{ color: (t) => t.palette.background.default }}>
          Name
        </TableCell>
        <TableCell sx={{ color: (t) => t.palette.background.default }}>
          Email
        </TableCell>
        <TableCell sx={{ color: (t) => t.palette.background.default }}>
          Contact
        </TableCell>
        <TableCell sx={{ color: (t) => t.palette.background.default }}>
          Age
        </TableCell>
        <TableCell sx={{ color: (t) => t.palette.background.default }}>
          Actions
        </TableCell>
      </TableRow>
    </MUIHeader>
  )
}

export default TableHead
