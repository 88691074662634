import { Stack } from '@mui/material'
import WaitlistClasses from './WaitlistClasses'
import EnrolledClasses from './EnrolledClasses'

const Classes = ({ user }) => {
  const familyArr = user.family
    ? Object.entries(user.family).map(([member, v]) => ({
        member,
        ...v,
      }))
    : []

  return (
    <Stack gap={2}>
      <EnrolledClasses user={user} family={familyArr} />
      <WaitlistClasses user={user} family={familyArr} />
    </Stack>
  )
}

export default Classes
