import React, { useState } from 'react'
import AdminPage from '../../../../components/page/AdminPage'
import { Divider, Tab, Tabs } from '@mui/material'
import Studios from './Studios'
import TabPanel from '../../../../components/TabPanel'

const Settings = () => {
  const [tabs, setTabs] = useState(0)

  const handleTabChange = (event, newValue) => {
    setTabs(newValue)
  }

  return (
    <AdminPage>
      <Tabs value={tabs} onChange={handleTabChange}>
        <Tab label='Studios' />
        <Tab label='Audit Log' />
      </Tabs>
      <Divider />
      <TabPanel value={tabs} index={0}>
        <Studios />
      </TabPanel>
    </AdminPage>
  )
}

export default Settings
