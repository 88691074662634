import { useState } from 'react'
import {
  Box,
  Button,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { firestore } from '../../../../../utils/firebase/firebase'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import {
  refreshMembershipStatus,
  addRestriction,
  removeRestriction,
  RESTRICTION_TIMEFRAME,
} from '../helpers'
import useIsUserRestricted from '../useIsUserRestricted'

const PersonalDetailsSection = ({ user }) => {
  const [clickedChangeDetails, setClickedChangeDetails] = useState(false)
  const [clickedChangeBrand, setClickedChangeBrand] = useState(false)
  const { isRestricted, friendlyRestrictionEnds } = useIsUserRestricted(user)
  const [newBrand, setNewBrand] = useState(user.brand)
  const [formData, setFormData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    mobile: user.mobile,
    credits: user.credits,
  })
  const userRef = firestore.collection('users').doc(user.id)
  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (clickedChangeDetails) {
      setClickedChangeDetails(false)
      try {
        await userRef
          .update({
            ...formData,
            credits: parseInt(formData.credits),
          })
          .then(() => {
            toast.success('User details updated')
          })
      } catch (error) {
        toast.error('Error updating user details')
      }
      return
    }
    setClickedChangeDetails(true)
    setFormData({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      mobile: user.mobile,
      credits: user.credits,
    })
  }

  const togglePt = async () => {
    try {
      await userRef
        .update({
          isPt: !user.isPt,
        })
        .then(() => {
          toast.success('User updated successfully')
        })
    } catch (error) {
      toast.error('Error updating user details')
    }
  }

  const updateBrand = async (e) => {
    e.preventDefault()
    setClickedChangeBrand((prevState) => !prevState)

    if (clickedChangeBrand) {
      try {
        await userRef
          .update({
            brand: newBrand,
          })
          .then(() => {
            toast.success('User updated successfully')
          })
      } catch (error) {
        toast.error('Error updating user details')
      }
      return
    }
  }

  return (
    <Stack>
      <form onSubmit={handleSubmit}>
        <Box display={'flex'} justifyContent='space-between'>
          <Typography variant='h5' sx={{ color: 'white!important' }}>
            Personal details
          </Typography>
          <Box display={'flex'} gap={1}>
            <Button variant='contained' type='submit'>
              {clickedChangeDetails ? 'Submit' : 'Update'}
            </Button>
            {clickedChangeDetails && (
              <Button
                variant='outlined'
                onClick={() => setClickedChangeDetails(false)}
              >
                Cancel
              </Button>
            )}
          </Box>
        </Box>

        <Stack sx={{ mt: 2 }} gap={1.5}>
          <TextField
            id='firstName'
            label='First name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
            value={clickedChangeDetails ? formData.firstName : user.firstName}
            disabled={!clickedChangeDetails}
            onChange={onChange}
          />
          <TextField
            id='lastName'
            label='Surname &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
            value={clickedChangeDetails ? formData.lastName : user.lastName}
            disabled={!clickedChangeDetails}
            onChange={onChange}
          />
          <TextField
            id='email'
            label='Email &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
            value={clickedChangeDetails ? formData.email : user.email}
            disabled={true}
            onChange={onChange}
          />
          <TextField
            id='mobile'
            label='Mobile &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
            value={clickedChangeDetails ? formData.mobile : user.mobile}
            disabled={!clickedChangeDetails}
            onChange={onChange}
          />
          <TextField
            id='credits'
            label='Credits &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
            value={clickedChangeDetails ? formData.credits : user.credits}
            disabled={!clickedChangeDetails}
            onChange={onChange}
            type='number'
          />
          <TextField
            id='DOB '
            label='DOB &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
            value={dayjs(user.dob).format('DD MMM YYYY') ?? 'unavailable'}
            disabled={true}
          />
        </Stack>
      </form>
      <Stack gap={1}>
        <Typography variant='h6' sx={{ color: 'white!important' }} mt={3}>
          Membership Status
        </Typography>
        <Box display={'flex'} gap={1}>
          <TextField
            value={user.status}
            sx={{ textTransform: 'capitalize' }}
            disabled
            size='small'
            fullWidth
          />
          <Button
            variant='contained'
            onClick={() => refreshMembershipStatus(user.id, user.status)}
          >
            Refresh
          </Button>
        </Box>
      </Stack>

      <Stack gap={1}>
        <Typography variant='h6' sx={{ color: 'white!important' }} mt={3}>
          Personal Trainer
        </Typography>
        <Typography mb={1} variant='body2' fontWeight={'light'}>
          Enable a user as a personal trainer to make them available as a class
          host
        </Typography>
        <FormControlLabel
          control={
            <Switch checked={user.isPt} onChange={() => togglePt()} name='No' />
          }
          label={'PT'}
        />
        {user.isPt && (
          <Box
            display={'flex'}
            gap={1}
            component={'form'}
            onSubmit={updateBrand}
          >
            <TextField
              label='Optionally add a custom brand name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
              fullWidth
              size='small'
              value={clickedChangeBrand ? newBrand : user.brand}
              onChange={(e) => setNewBrand(e.target.value)}
              disabled={!clickedChangeBrand}
            />
            <Button variant='contained' type='submit'>
              {clickedChangeBrand ? 'Submit' : 'Update'}
            </Button>
            {clickedChangeBrand && (
              <Button
                variant='outlined'
                onClick={() => setClickedChangeBrand(false)}
              >
                Cancel
              </Button>
            )}
          </Box>
        )}
      </Stack>
      <Stack gap={1}>
        <Typography variant='h6' sx={{ color: 'white!important' }} mt={3}>
          Restrict User
        </Typography>
        <Typography mb={1} variant='body2' fontWeight={'light'}>
          {`Restrict the user from booking classes for ${RESTRICTION_TIMEFRAME} days.`}
        </Typography>
        <FormControlLabel
          control={
            <Switch
              checked={isRestricted}
              onChange={() =>
                isRestricted
                  ? removeRestriction(user.id)
                  : addRestriction(user.id)
              }
              name='Yes'
            />
          }
          label={'Restrict'}
        />
        {isRestricted && (
          <Box display={'flex'} gap={1} component={'form'}>
            <TextField
              label='Expires &nbsp;&nbsp;&nbsp;&nbsp;'
              fullWidth
              size='small'
              disabled
              value={`Restriction Expires: ${friendlyRestrictionEnds}`}
            />

            <Button variant='contained' onClick={() => addRestriction(user.id)}>
              Extend
            </Button>
          </Box>
        )}
      </Stack>
    </Stack>
  )
}

export default PersonalDetailsSection
