import { Box, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { NavLink } from 'react-router-dom'

const NewMembers = ({ newUsers }) => {
  return (
    <Box sx={{ my: 2 }}>
      <Typography fontSize={'2rem'}>New Members</Typography>
      <Typography fontSize={'.8rem'}>
        Members who signed up in the last 7 days
      </Typography>
      <Stack gap={1} mt={2}>
        {newUsers.map((u) => (
          <Box
            component={NavLink}
            to={`/admin/users/${u.id}`}
            sx={{
              p: '5px 10px',
              border: (t) => `1px solid #4c4c4c`,
              borderRadius: '10px',
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
              cursor: 'pointer',
              '&:hover': {
                background: '#4c4c4c',
              },
            }}
          >
            <Typography textTransform={'capitalize'}>
              {u.firstName} {u.lastName}
            </Typography>
            <Typography fontWeight={'light'}>
              Joined {dayjs(u.createdAt).format('ddd DD MMM')}
            </Typography>
            <Typography fontWeight={'light'}>{u.email}</Typography>
            <Typography
              textTransform={'capitalize'}
              fontWeight={'light'}
              textAlign={'center'}
            >
              {u.status}
            </Typography>
            <Typography
              textTransform={'capitalize'}
              fontWeight={'light'}
              textAlign={'center'}
            >
              {u.memberType}
            </Typography>
          </Box>
        ))}
      </Stack>
    </Box>
  )
}

export default NewMembers
