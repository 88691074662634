import { Box, Button, Stack, Tooltip, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { checkEnrollment, checkWaitlist } from '../../classes/helpers'
import { CLASS_CANX_POLICY } from '../helpers'
import { useState } from 'react'
import ConfirmCanxClassModal from './ConfirmCanxClassModal'

const ClassCard = ({ gymClass, user }) => {
  const [showCanxModal, setShowCanxModal] = useState(false)
  const startTime = gymClass.startTime.toDate()
  const isEnrolled = checkEnrollment(user.id, gymClass.class.enrolledMembers)
  const { isWaitlisted, position } = checkWaitlist(
    user.id,
    gymClass.class.waitlistMembers
  )

  const toggleCanxModal = () => {
    setShowCanxModal((ps) => !ps)
  }

  const isCancellable =
    dayjs(gymClass.startTime.toDate()).diff(dayjs(), 'hour') > CLASS_CANX_POLICY

  return (
    <Stack>
      <Link to={`/classes/${gymClass.id}`}>
        <Box
          sx={{
            background: `url(${gymClass.class.coverImage}), radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4029586834733894) 23%, rgba(0,0,0,0.6559217436974789) 41%, rgba(0,0,0,1) 100%, rgba(0,0,0,0.6559217436974789) 100%)`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundBlendMode: 'multiply',
            height: 280,
            backgroundSize: 'cover',
            borderTopLeftRadius: 1,
            borderTopRightRadius: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: 2,
          }}
        >
          <Box display={'flex'} justifyContent={'flex-end'}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              gap={0.2}
            >
              <Box
                sx={{
                  border: (t) => `1px solid ${t.palette.primary.main}`,
                  background: '#00000080',
                  display: 'flex',
                  padding: '8px 18px',
                  color: 'white.main',
                  flexDirection: 'column',
                  textAlign: 'center',
                  borderRadius: 0.5,
                }}
              >
                <Typography lineHeight={1} variant='body2'>
                  {dayjs(startTime).format('ddd')}
                </Typography>
                <Typography variant='h6' fontWeight={800} lineHeight={1}>
                  {dayjs(startTime).format('DD')}
                </Typography>
                <Typography lineHeight={1} variant='body2'>
                  {dayjs(startTime).format('MMM')}
                </Typography>
              </Box>
              <Box
                display={'flex'}
                gap={0.5}
                mt={0.5}
                width={'100%'}
                justifyContent={'center'}
                sx={{
                  border: (t) => `1px solid ${t.palette.primary.main}`,
                  background: '#00000080',
                  borderRadius: 0.5,
                }}
              >
                <Typography variant='body2' py={0.2}>
                  {dayjs(startTime).format('HH:mm')}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'end'}
          >
            <Box>
              <Typography variant='h6' color='white.main'>
                {gymClass.class.title}
              </Typography>
              <Typography
                variant='body2'
                color='white.main'
                fontWeight={'light'}
                textTransform='capitalize'
              >
                {gymClass.user.firstName} {gymClass.user.lastName}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Link>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        px={2}
        py={1}
        alignItems={'center'}
        sx={{
          background: (t) =>
            isEnrolled ? t.palette.primary.dark : t.palette.grey[500],
          borderBottomLeftRadius: 1,
          borderBottomRightRadius: 1,
        }}
      >
        <Typography
          textAlign={'center'}
          variant='h6'
          sx={{ color: (t) => t.palette.background.default }}
        >
          {isEnrolled ? 'Confirmed' : 'Waitlisted'}{' '}
          {isWaitlisted && `position ${position}`}
        </Typography>

        {isCancellable ? (
          <Button
            variant='contained'
            color='primary'
            size='small'
            onClick={toggleCanxModal}
          >
            Cancel
          </Button>
        ) : (
          <Tooltip
            title={`Classes cannot be cancelled within ${CLASS_CANX_POLICY} hours.`}
          >
            <Box>
              <Button variant='contained' color='primary' size='small' disabled>
                Cancel
              </Button>
            </Box>
          </Tooltip>
        )}
      </Box>
      <ConfirmCanxClassModal
        isOpen={showCanxModal}
        onClose={toggleCanxModal}
        gymClass={gymClass}
        isEnrolled={isEnrolled}
        user={user}
      />
    </Stack>
  )
}

export default ClassCard
