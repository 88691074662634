import { useEffect, useState } from 'react'
import { collection, getDocs, query, where } from 'firebase/firestore'
import firebase, { newFirestore } from '../../utils/firebase/firebase'
import { timestampCheck } from '../../utils/helpers'
import dayjs from 'dayjs'
const useTimeslots = (
  dateToGenerate,
  openingTimeHours,
  openingTimeMinutes,
  closingTimeHours,
  closingTimeMinutes,
  defaultSessionLengthMinutes,
  studioId
) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [existingBookings, setExistingBookings] = useState([])

  const getExistingBookings = async () => {
    const bookingsCollection = collection(newFirestore, 'bookings')
    const matchesStudio = where('studio.id', '==', studioId)
    const timeNow = firebase.firestore.Timestamp.fromDate(
      dateToGenerate.hour(0).minute(0).second(0).toDate()
    )
    const endOfDay = firebase.firestore.Timestamp.fromDate(
      dateToGenerate.hour(23).minute(59).second(0).toDate()
    )
    const q = query(
      bookingsCollection,
      matchesStudio,
      where('startTime', '>', timeNow),
      where('startTime', '<', endOfDay),
      where('status', '==', 'confirmed')
    )
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
      setExistingBookings((existingBookings) => [
        ...existingBookings,
        doc.data(),
      ])
    })
    setLoading(false)
  }

  const bookingOverlaps = (startTime, endTime) => {
    startTime = timestampCheck(startTime)
    endTime = timestampCheck(endTime)

    for (let i = 0; i < existingBookings.length; i++) {
      if (
        existingBookings[i].startTime >= startTime &&
        existingBookings[i].startTime < endTime
      ) {
        return true
      }
      //Condition 2: Check for existing bookings ending during the booking request
      if (
        existingBookings[i].endTime > startTime &&
        existingBookings[i].endTime <= endTime
      ) {
        return true
      }
      //Condition 3: Check for existing bookings starting before and ending after the booking request
      if (
        existingBookings[i].startTime < startTime &&
        existingBookings[i].endTime >= endTime
      ) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    setLoading(true)
    setExistingBookings([])
    try {
      getExistingBookings()
      setLoading(false)
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateToGenerate, studioId])

  let time = dateToGenerate
    .hour(openingTimeHours)
    .minute(openingTimeMinutes)
    .second(0)
  const timeSlots = []
  const closingTime = dateToGenerate
    .hour(closingTimeHours)
    .minute(closingTimeMinutes)

  while (closingTime >= time.add(defaultSessionLengthMinutes, 'minute')) {
    const startTime = time
    const endTime = time.add(defaultSessionLengthMinutes, 'minute')
    const isAvailable =
      !bookingOverlaps(startTime, endTime) && startTime.isAfter(dayjs())

    timeSlots.push({
      startTime,
      endTime,
      isAvailable,
    })

    time = time.add(30, 'minute')
  }

  return { timeSlots, loading, error }
}

export default useTimeslots
