import React, { useState } from 'react'
import './styles.scss'
import { connect } from 'react-redux'
import {
  setCurrentJoinStep,
  setSelectedProduct,
} from '../../../redux/join/join.actions'
import { sendToCheckout } from '../../../utils/firebase/firebase'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Paper,
  Typography,
} from '@mui/material'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom'
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined'
import { convertCycle } from '../about-you/helpers'

function Package({
  membership,
  currentUser,
  setCurrentJoinStep,
  setSelectedProduct,
}) {
  const { price, cycle, stripeAPI, type } = membership
  const priceMain = price.slice(0, -2)
  const priceDecimal = price.slice(-2)
  const [submitting, setSubmitting] = useState(false)

  const handleOnClick = () => {
    setSubmitting(true)
    if (!currentUser) {
      setCurrentJoinStep(3)
      setSelectedProduct({
        priceMain,
        priceDecimal,
        cycle,
        stripeAPI,
        type,
      })
      console.log('No user - register & checkout')
    } else {
      setSelectedProduct({
        priceMain,
        priceDecimal,
        cycle,
        stripeAPI,
        type,
      })
      console.log('State set. Already a user - checkout only')
      sendToCheckout(stripeAPI, currentUser.id)
    }
  }

  return (
    <Paper
      elevation={3}
      variant='outlined'
      sx={{
        border: (theme) => `1px solid ${theme.palette.primary.main}`,
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          {type === 'family' ? <FamilyRestroomIcon /> : <EmojiPeopleIcon />}
          <Typography textAlign={'center'} variant='h6'>
            {type}
          </Typography>
        </Box>

        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography variant='h5'>{cycle}</Typography>
          <Typography variant='h4'>
            £{priceMain}.{priceDecimal}
          </Typography>
        </Box>
        <Divider sx={{ mt: 1 }} />
        <Box display={'grid'} gap={1} mt={1}>
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <AdjustOutlinedIcon fontSize='small' />
            <Typography>Unlimited 24/7 access to the gym</Typography>
          </Box>
          {type === 'family' && (
            <Box display={'flex'} alignItems={'start'} gap={1}>
              <AdjustOutlinedIcon fontSize='small' />
              <Box>
                <Typography>Household membership</Typography>
                <Typography color={'primary'} fontSize={'small'}>
                  2 adults & 2 children
                </Typography>
              </Box>
            </Box>
          )}
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <AdjustOutlinedIcon fontSize='small' />
            <Typography>No Contract. Cancel anytime</Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <AdjustOutlinedIcon fontSize='small' />
            <Typography>Unlimited free member classes</Typography>
          </Box>
          {cycle !== 'Weekly' && (
            <Box display={'flex'} alignItems={'center'} gap={1}>
              <AdjustOutlinedIcon fontSize='small' />
              <Typography>24hr free trial</Typography>
            </Box>
          )}
          <Box display={'flex'} alignItems={'start'} gap={1}>
            <AdjustOutlinedIcon fontSize='small' />
            <Box>
              <Typography>
                {priceMain} HYVE credits every {convertCycle(cycle)}
              </Typography>
              <Typography fontSize={'small'} color={'primary'}>
                Redeem HYVE credist for private studio or pool bookings, guest
                passes and discounted supplements
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Button
        fullWidth
        variant='contained'
        sx={{ mt: 2 }}
        size='large'
        onClick={handleOnClick}
      >
        {submitting ? (
          <CircularProgress size={25} color='secondary' />
        ) : (
          `Join for £${priceMain}.${priceDecimal} ${cycle}`
        )}
      </Button>
    </Paper>
  )
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  setCurrentJoinStep: (joinStep) => dispatch(setCurrentJoinStep(joinStep)),
  setSelectedProduct: (product) => dispatch(setSelectedProduct(product)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Package)
