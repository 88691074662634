import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { doc, updateDoc } from 'firebase/firestore'
import { auth, newFirestore } from '../../../utils/firebase/firebase'
import { toast } from 'react-toastify'
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updateEmail,
} from 'firebase/auth'
import Modal from '../../../components/Modal'

const PersonalDetails = ({
  currentUser: { firstName, lastName, email, mobile, credits, id },
}) => {
  const [edit, setEdit] = useState()
  const [formData, setFormData] = useState({ email, mobile, password: '' })
  const [prompt, setPrompt] = useState(false)
  const [error, setError] = useState()
  const [submitting, setSubmitting] = useState(false)
  const toggleEdit = () => {
    setEdit((ps) => !ps)
  }

  const togglePasswordPrompt = () => {
    setPrompt((ps) => !ps)
    setFormData({ ...formData, password: '' })
  }

  const handleSubmit = async () => {
    formData.password && setSubmitting(true)

    const userRef = doc(newFirestore, 'users', id)

    if (email !== formData.email && !formData.password) {
      return setPrompt(true)
    }

    if (email !== formData.email) {
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        formData.password
      )

      await reauthenticateWithCredential(auth.currentUser, credential).catch(
        (e) => {
          setError('Authentication failed')
          console.log(e)
          setSubmitting(false)
        }
      )
      await updateEmail(auth.currentUser, formData.email)
        .then(() => {
          toast.success('Email updated')
          setPrompt(false)
          setFormData({ ...formData, password: '' })
          setSubmitting(false)
          updateDoc(userRef, { email: formData.email })
          setEdit(false)
        })
        .catch(() => {
          toast.error('Failed to update email, check password and try again')
        })
    }

    if (mobile !== formData.mobile && !error) {
      await updateDoc(userRef, { mobile: formData.mobile })
        .then(() => {
          toast.success('Mobile successfully updated')
          setEdit(false)
        })
        .catch((e) => toast.error('Failed to update mobile, please try again.'))
    }
  }

  return (
    <Stack gap={1}>
      <Box display={'flex'} alignItems={'start'}>
        <Typography mb={2} variant='h5' color='white.main'>
          Primary member
        </Typography>
        <IconButton onClick={toggleEdit} disabled={edit}>
          <EditIcon />
        </IconButton>
      </Box>

      <TextField
        disabled
        label='first name &nbsp; &nbsp; &nbsp;'
        variant='outlined'
        defaultValue={firstName}
      />
      <TextField
        disabled
        label='last name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
        variant='outlined'
        defaultValue={lastName}
      />
      <TextField
        disabled={!edit}
        label='email&nbsp;&nbsp;&nbsp;'
        variant='outlined'
        value={edit ? formData.email : email}
        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
      />
      <TextField
        disabled={!edit}
        label='mobile&nbsp;&nbsp;&nbsp;'
        variant='outlined'
        value={edit ? formData.mobile : mobile}
        onChange={(e) => setFormData({ ...formData, mobile: e.target.value })}
      />
      <TextField
        disabled
        label='credits&nbsp;&nbsp;&nbsp;'
        variant='outlined'
        defaultValue={credits}
      />
      {edit && (
        <Box display={'flex'} gap={1}>
          <Button
            variant='contained'
            onClick={handleSubmit}
            disabled={mobile === formData.mobile && email === formData.email}
          >
            Submit changes
          </Button>
          <Button variant='outlined' onClick={toggleEdit}>
            Cancel changes
          </Button>
        </Box>
      )}
      <Modal isOpen={prompt} hideButton title='Confirm password'>
        <Typography mb={2}>
          Please confirm your password to complete this action
        </Typography>
        <TextField
          fullWidth
          label='Password&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
          variant='outlined'
          type='password'
          value={formData.password}
          onChange={(e) =>
            setFormData({ ...formData, password: e.target.value })
          }
        />
        <Box sx={{ mt: 1 }} display={'flex'} gap={1}>
          <Button
            variant='contained'
            onClick={handleSubmit}
            fullWidth
            disabled={submitting}
          >
            Submit
          </Button>
          <Button variant='outlined' onClick={togglePasswordPrompt} fullWidth>
            Cancel
          </Button>
        </Box>
      </Modal>
    </Stack>
  )
}

export default PersonalDetails
