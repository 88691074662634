import { useEffect, useState } from 'react'
import { Box, Button, ButtonGroup, Typography } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import SessionForm from './components/SessionForm'
import ClassForm from './components/ClassForm'
import { Link, useLocation } from 'react-router-dom'
import AdminPage from '../../../components/page/AdminPage'

const Book = () => {
  const [isClass, setIsClass] = useState(false)
  const mobile = useMediaQuery('(max-width:1000px)')
  const { search } = useLocation()

  useEffect(() => {
    if (search === '?class') {
      setIsClass(true)
    } else {
      setIsClass(false)
    }
  }, [search])

  return (
    <AdminPage>
      <Box
        display={'flex'}
        flexDirection={'column'}
        px={mobile ? 2 : 20}
        mt={2}
        mb={10}
      >
        <Typography
          variant='h4'
          color={'white.main'}
          textAlign={'center'}
          mb={2}
        >
          Add a new Booking
        </Typography>
        <ButtonGroup fullWidth>
          <Button
            variant={isClass ? 'outlined' : 'contained'}
            component={Link}
            to={'/admin/book'}
          >
            Private session
          </Button>
          <Button
            variant={isClass ? 'contained' : 'outlined'}
            component={Link}
            to={'/admin/book?class'}
          >
            Public class
          </Button>
        </ButtonGroup>
        {isClass ? <ClassForm /> : <SessionForm />}
      </Box>
    </AdminPage>
  )
}

export default Book
