import { Alert, AlertTitle, Typography } from '@mui/material'

const PageError = ({ message }) => {
  return (
    <Alert severity='error'>
      <AlertTitle>OOPS!</AlertTitle>
      <Typography>{message}</Typography>
    </Alert>
  )
}

export default PageError
