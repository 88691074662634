import { Box, Button, Stack, Tooltip, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { BOOKING_CANX_POLICY } from '../helpers'
import ConfirmCanxStudioModal from './ConfirmCanxStudioModal'

const BookingCard = ({ booking }) => {
  const [confirmCancel, setConfirmCancel] = useState(false)

  const toggleCanxModal = () => {
    setConfirmCancel((ps) => !ps)
  }

  const isCancelled = booking.status === 'cancelled'
  console.log('booking', booking)
  const startTime = dayjs(booking.startTime.toDate()).format('HH:mm A')
  const endTime = dayjs(booking.endTime.toDate()).format('HH:mm A')
  const date = dayjs(booking.startTime.toDate()).format('ddd DD MMMM')
  const hoursToBooking = dayjs(booking.startTime.toDate()).diff(dayjs(), 'hour')

  return (
    <Stack
      gap={1}
      p={2}
      sx={{
        border: (t) =>
          `1px solid ${
            isCancelled ? t.palette.error.main : t.palette.primary.main
          }`,
        borderRadius: 1,
      }}
    >
      <Typography
        color='primary'
        sx={{ textTransform: 'uppercase' }}
        variant='h6'
      >
        {booking.studio.name}
      </Typography>
      <Box>
        <Typography>LOCATION</Typography>

        <Typography variant='body2' fontWeight={300}>
          HYVE Llanelli
        </Typography>
      </Box>
      <Box>
        <Typography>TIME</Typography>
        <Typography variant='body2' fontWeight={300}>
          {startTime} to {endTime}
        </Typography>
      </Box>
      <Box>
        <Typography>DATE</Typography>
        <Typography variant='body2' fontWeight={300}>
          {date}
        </Typography>
      </Box>

      {isCancelled ? (
        <Button disabled={isCancelled} variant='contained'>
          Cancelled
        </Button>
      ) : hoursToBooking < BOOKING_CANX_POLICY ? (
        <Tooltip
          title={`Bookings cannot be cancelled within ${BOOKING_CANX_POLICY} hours.`}
        >
          <Box>
            <Button
              disabled
              fullWidth
              sx={{
                background: (t) => t.palette.primary.dark,
              }}
            >
              Confirmed
            </Button>
          </Box>
        </Tooltip>
      ) : (
        <Button variant='contained' onClick={toggleCanxModal}>
          Cancel booking
        </Button>
      )}
      <ConfirmCanxStudioModal
        isOpen={confirmCancel}
        onClose={toggleCanxModal}
        booking={booking}
      />
    </Stack>
  )
}

export default BookingCard
