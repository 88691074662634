import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { PieChart } from '@mui/x-charts/PieChart'

const MemberAgeGroup = ({ users }) => {
  const today = dayjs()
  const band0 = []
  const band1 = []
  const band2 = []
  const band3 = []
  const band4 = []
  const band5 = []
  const band6 = []
  const band7 = []
  const band8 = []

  users.forEach((u) => {
    const birthday = dayjs(u.dob)
    const age = today.diff(birthday, 'year')

    if (!age) {
      band7.push(u)
      return
    }

    if (age < 19) {
      band0.push(u)
      return
    }
    if (age < 26) {
      band1.push(u)
      return
    }
    if (age < 36) {
      band2.push(u)
      return
    }
    if (age < 46) {
      band3.push(u)
      return
    }
    if (age < 56) {
      band4.push(u)
      return
    }
    if (age < 66) {
      band5.push(u)
      return
    }
    if (age < 76) {
      band6.push(u)
      return
    }

    if (age > 75) {
      band8.push(u)
    }
  })

  const demographics = [
    { id: 0, value: band0.length, label: 'Under 19' },
    { id: 1, value: band1.length, label: '19-25' },
    { id: 2, value: band2.length, label: '26-35' },
    { id: 3, value: band3.length, label: '36-45' },
    { id: 4, value: band4.length, label: '46-55' },
    { id: 5, value: band5.length, label: '56-65' },
    { id: 6, value: band6.length, label: '66-75' },
    { id: 8, value: band8.length, label: 'Over 75' },
  ]

  return (
    <Box
      flexGrow={1}
      sx={{
        border: (t) => `1px solid ${t.palette.primary.main}`,
        padding: 3,
        borderRadius: '10px',
        width: 'fit-content',
      }}
    >
      <Typography>{`Age Group (Lead member)`}</Typography>
      <Typography
        fontSize={'12px'}
        mb={2}
      >{`Excludes ${band7.length} members without a registered DOB `}</Typography>
      <PieChart
        colors={(t) => [
          '#fc6c6c',
          '#fc6cd8',
          '#c06cfc',
          '#6c78fc',
          '#6ccffc',
          '#6cfcf2',
          '#6cfc8c',
          '#f7df45',
          '#ff5959',
        ]}
        series={[
          {
            data: demographics,
            highlightScope: { faded: 'global', highlighted: 'item' },
            faded: { innerRadius: 30, additionalRadius: -30 },
            innerRadius: 30,
            outerRadius: 130,
            paddingAngle: 0,
            cornerRadius: 2,
            startAngle: 0,
            endAngle: 360,
            cx: 150,
            cy: 150,
          },
        ]}
        height={300}
      />
    </Box>
  )
}

export default MemberAgeGroup
