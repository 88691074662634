import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material'

const TagsField = (props) => {
  const tags = [
    'Functional',
    'Cardio',
    'HIIT',
    'Beginner Friendly',
    'Advanced',
    'Circuits',
    'Fat Burn',
    'Yoga',
    'Box Fit',
    'Strength',
    'Conditioning',
  ]

  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    props.setFieldValue(
      'class.tags',
      typeof value === 'string' ? value.split(',') : value
    )
  }

  return (
    <FormControl>
      <InputLabel>Tags</InputLabel>
      <Select
        labelId='tags'
        id='tags'
        multiple
        required
        value={props.values.class.tags}
        onChange={handleChange}
        input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
      >
        {tags.map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default TagsField
