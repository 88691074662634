import React, { useState, useEffect } from 'react'
import './styles.scss'
import Package from '../package/Package.component'
import { Fade } from 'react-awesome-reveal'
import { firestore } from '../../../utils/firebase/firebase'
import { connect } from 'react-redux'
import { setLoading } from '../../../redux/system/system.actions'
import BG from '../../../assets/images/HYVEBG3.jpg'
import { Box, Button, ButtonGroup, Container, Typography } from '@mui/material'
import Page from '../../../components/page/Page'
import Span from '../../../components/Span'

function Packages({ setLoading }) {
  const [membershipType, setMembershipType] = useState('individual')
  const [hyveMemberships, setHyveMemberships] = useState([])
  const [familyMemberships, setFamilyMemberships] = useState([])

  const sortedMemberships = hyveMemberships.sort(function (a, b) {
    return a.order - b.order
  })

  const sortedFamilyMemberships = familyMemberships.sort(function (a, b) {
    return a.order - b.order
  })

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const data = []
      firestore
        .collection('memberships')
        .where('type', '==', 'individual')
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc, index) => data.push(doc.data()))
          setHyveMemberships(data)
        })
        .catch((error) => {
          console.log('Data error', error)
        })

      const data2 = []
      firestore
        .collection('memberships')
        .where('type', '==', 'family')
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc, index) => data2.push(doc.data()))
          setFamilyMemberships(data2)
          setLoading(false)
        })
        .catch((error) => {
          console.log('Data error', error)
        })
    }
    fetchData()
  }, [setLoading])

  return (
    <Page container={false}>
      <Fade>
        <Box
          style={{
            backgroundImage: `linear-gradient(270deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 1)), url(${BG})`,
            minHeight: '89vh',
            paddingTop: 20,
          }}
        >
          <Container maxWidth='xl'>
            <Typography variant='h2' color={'white.main'}>
              Get
            </Typography>
            <Typography variant='h2' color='white.main' mb={3}>
              <Span>involved</Span>.
            </Typography>
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <Box
                display={'flex'}
                alignItems={'center'}
                flexDirection={'column'}
              >
                <Typography variant='h6' color='white.main'>
                  Select a membership type
                </Typography>
                <ButtonGroup
                  size='large'
                  aria-label='large button group'
                  sx={{ mt: 2 }}
                >
                  <Button
                    variant={
                      membershipType === 'individual' ? 'contained' : 'outlined'
                    }
                    onClick={() => setMembershipType('individual')}
                  >
                    Individual
                  </Button>
                  <Button
                    variant={
                      membershipType === 'family' ? 'contained' : 'outlined'
                    }
                    onClick={() => setMembershipType('family')}
                  >
                    Family
                  </Button>
                </ButtonGroup>
              </Box>

              <Box
                display={'grid'}
                width='100%'
                gridTemplateColumns={'repeat(auto-fit, minmax(275px, 1fr))'}
                gap={1}
                my={2}
                px={1}
              >
                {membershipType === 'individual' &&
                  sortedMemberships.map((membership) => {
                    return <Package membership={membership} />
                  })}

                {membershipType === 'family' &&
                  sortedFamilyMemberships.map((membership) => {
                    return <Package membership={membership} />
                  })}
              </Box>
            </Box>
          </Container>
        </Box>
      </Fade>
    </Page>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setLoading: (system) => dispatch(setLoading(system)),
})

export default connect(null, mapDispatchToProps)(Packages)
