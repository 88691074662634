import { Box, Typography } from '@mui/material'
import Page from '../../../components/page/Page'
import Span from '../../../components/Span'
import ClassCard from '../components/ClassCard'
import useClasses from '../useClasses'
import NoClass from '../components/NoClass'

const Classes = () => {
  const { classes, error, loading } = useClasses()

  return (
    <Page loading={loading} error={error}>
      <Box>
        <Typography variant='h2' color='white.main'>
          <Span>Class</Span>
        </Typography>
        <Typography variant='h2' color='white.main'>
          Schedule.
        </Typography>
        <Box
          mt={2}
          display={'grid'}
          gridTemplateColumns='repeat(auto-fit, minmax(300px, 1fr)) '
          gap={2}
        >
          {classes.length === 0 ? (
            <NoClass />
          ) : (
            classes.map((c) => <ClassCard gymClass={c} key={c.id} />)
          )}
        </Box>
      </Box>
    </Page>
  )
}

export default Classes
