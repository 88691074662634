import React from 'react'
import './styles.scss'
import { Fade } from 'react-awesome-reveal'
import { connect } from 'react-redux'
import { setLoading } from '../../redux/system/system.actions'
import { firestore } from '../../utils/firebase/firebase'
import { Link } from 'react-router-dom'

function AddCredits({ currentUser, setLoading }) {
  //Credit Packages
  const packages = [
    // {
    // 	credits: 78,
    // 	saving: 37,
    // 	price: 49.0,
    // 	priceAPI: 'price_1JooboLxp1x7fxste7Tltl3Z',
    // 	text: '*** Special launch trial *** ',
    // 	text2: '6 x Studio 1 bookings or any combination of studios',
    // },
    {
      credits: 75,
      saving: 5,
      price: 71.75,
      priceAPI: 'price_1Jo8kbLxp1x7fxstL3xhoe9D',
      text: 'HYVE CREDITS',
      text2: 'Book your own private studio',
    },
    {
      credits: 100,
      saving: 10,
      price: 90,
      priceAPI: 'price_1Jo8leLxp1x7fxstB1QjZwrf',
      text: 'HYVE CREDITS',
      text2: 'Book your own private studio',
    },
    {
      credits: 150,
      saving: 15,
      price: 127.5,
      priceAPI: 'price_1Jo8mSLxp1x7fxstcn5brAXY',
      text: 'HYVE CREDITS',
      text2: 'Book your own private studio',
    },
  ]

  const sendToSingleCheckout = async (user, priceAPI) => {
    setLoading(true)

    const docRef = await firestore
      .collection('users')
      .doc(user)
      .collection('checkout_sessions')
      .add({
        mode: 'payment',
        price: `${priceAPI}`,
        allow_promotion_codes: false,
        success_url: `${window.location.origin}/account`,
        cancel_url: `${window.location.origin}/add-credits`,
      })
    // Wait for the CheckoutSession to get attached by the extension

    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data()
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`)
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url)
      }
    })
  }

  return (
    <div className='addCredits__wrapper'>
      <div className='addCredits__header'>
        <h1>Add a Credit Pack</h1>
        <p>
          Buy a HYVE credit pack today and save up to 15% on your private studio
          bookings.
        </p>
        <p style={{ marginTop: '20px' }}>
          Alternatively you can Pay-As-You go.{' '}
          <Link to='/gyms/llanelli/studios'>VIEW OUR STUDIOS HERE</Link> to
          check availability and book online in just a few clicks.{' '}
        </p>
      </div>
      <div className='addCredits__content'>
        {packages.map((pack, i) => {
          return (
            <Fade cascade='true' triggerOnce key={i}>
              <div
                className='creditPackage'
                onClick={() => {
                  sendToSingleCheckout(currentUser.id, pack.priceAPI)
                }}
              >
                <div className='creditPackageDescription'>
                  <h4>{pack.credits}</h4>
                  <p>credits</p>
                  <p>{pack.text}</p>
                  <p>{!pack.text2 ? 's' : pack.text2}</p>
                  <h5>save {pack.saving}%</h5>
                </div>
                <div className='creditsPackagePrice'>
                  <h4>£{pack.price}</h4>
                </div>
              </div>
            </Fade>
          )
        })}
      </div>
    </div>
  )
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  setLoading: (system) => dispatch(setLoading(system)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddCredits)
