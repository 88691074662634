import { combineReducers } from 'redux';
import userReducer from './user/user.reducer';
import joinReducer from './join/join.reducer';
import subscriptionReducer from './subscription/subscription.reducer';
import systemReducer from './system/system.reducer';

export default combineReducers({
	user: userReducer,
	join: joinReducer,
	subscription: subscriptionReducer,
	system: systemReducer,
});
