import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { auth } from '../../utils/firebase/firebase'
import { setCurrentSubscription } from '../../redux/subscription/subscription.actions'
import { setStudioId } from '../../redux/system/system.actions'

import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  Divider,
  SwipeableDrawer,
  styled,
} from '@mui/material'
import logo from '../../assets/images/hyve-yellow.png'
import whiteLogo from '../../assets/images/hyve-white.png'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import useMediaQuery from '@mui/material/useMediaQuery'

const StyledMenuIcon = styled(MenuRoundedIcon)(({ theme }) => ({
  color: theme.palette.white.main,
  transition: 'ease .3s all',
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}))

const Nav = ({
  currentUser,
  setCurrentSubscription,
  subscriptionData,
  setStudioId,
}) => {
  const mobile = useMediaQuery('(max-width:1000px)')
  const [drawerOpen, setDrawerOpen] = useState(false)

  const toggleDrawer = () => {
    mobile && setDrawerOpen((ps) => !ps)
  }

  return (
    <Box
      component='nav'
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 999,
        width: '100%',
        height: 60,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <Container
        maxWidth={'xl'}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Link to='/'>
          <img src={logo} alt='hyve logo' height={25} />
        </Link>
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={2}
        >
          {!mobile && (
            <NavItems
              currentUser={currentUser}
              toggleDrawer={toggleDrawer}
              setCurrentSubscription={setCurrentSubscription}
            />
          )}

          {mobile && (
            <StyledMenuIcon
              onClick={toggleDrawer}
              color='white'
              sx={{ fontSize: '45px' }}
            />
          )}
        </Box>
      </Container>
      <MobileMenu
        isOpen={drawerOpen}
        toggleDrawer={toggleDrawer}
        mobile={mobile}
        setCurrentSubscription={setCurrentSubscription}
        currentUser={currentUser}
      />
    </Box>
  )
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  subscriptionData: state.subscription.subscriptionData,
})

const mapDispatchToProps = (dispatch) => ({
  setCurrentSubscription: (subscription) =>
    dispatch(setCurrentSubscription(subscription)),
  setStudioId: (system) => dispatch(setStudioId(system)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Nav)

const NavItems = ({
  mobile,
  currentUser,
  toggleDrawer,
  setCurrentSubscription,
}) => {
  const handleLogout = () => {
    setCurrentSubscription()
    auth.signOut()
    toggleDrawer()
  }

  const handleAddCredits = () => {
    return <Redirect to='/somewhere/else' />
  }
  return (
    <Box display={'flex'} flexDirection={mobile ? 'column' : 'row'} gap={1}>
      {mobile && (
        <>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Link to='/'>
              <img src={whiteLogo} alt='hyve logo' height={20} />
            </Link>
            <StyledMenuIcon
              onClick={toggleDrawer}
              color='white'
              sx={{ fontSize: '45px' }}
            />
          </Box>
          <Divider />
        </>
      )}
      <Link to='/gyms/llanelli'>
        <Button color='white' size='large' fullWidth onClick={toggleDrawer}>
          Gym
        </Button>
      </Link>
      <Link to='/classes'>
        <Button color='white' size='large' fullWidth onClick={toggleDrawer}>
          Class schedule
        </Button>
      </Link>
      <Link to='/gyms/llanelli/studios'>
        <Button color='white' size='large' fullWidth onClick={toggleDrawer}>
          Private studios
        </Button>
      </Link>
      <Link to={currentUser ? '/account' : '/join'}>
        <Button
          variant='contained'
          fullWidth
          size='large'
          onClick={toggleDrawer}
        >
          {currentUser ? 'My account' : 'Join now'}
        </Button>
      </Link>
      <Link to={currentUser ? '/' : '/login'}>
        <Button
          variant='outlined'
          onClick={currentUser && handleLogout}
          fullWidth
          size='large'
          sx={{ whiteSpace: 'nowrap' }}
        >
          {currentUser ? 'Logout' : 'Login'}
        </Button>
      </Link>
      {currentUser?.isAdmin && (
        <Link to={'/admin/dashboard'}>
          <Button
            variant='outlined'
            fullWidth
            size='large'
            sx={{ whiteSpace: 'nowrap' }}
            color='white'
          >
            Admin
          </Button>
        </Link>
      )}
      <Box display={'flex'} alignItems={'center'}>
        {currentUser && currentUser.firstName && (
          <Link to='/add-credits'>
            <Chip
              onClick={handleAddCredits}
              avatar={
                <Avatar>
                  {currentUser.firstName.charAt(0) +
                    currentUser.lastName.charAt(0)}
                </Avatar>
              }
              variant='outlined'
              label={`${currentUser.credits} CREDITS`}
            />
          </Link>
        )}
      </Box>
    </Box>
  )
}

const MobileMenu = ({
  isOpen,
  toggleDrawer,
  mobile,
  setCurrentSubscription,
  currentUser,
}) => {
  return (
    <SwipeableDrawer
      anchor={'right'}
      open={isOpen}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
      PaperProps={{
        sx: {
          backgroundColor: 'black',
          backgroundImage: 'none',
        },
      }}
    >
      <Box width={300} p={2}>
        <NavItems
          mobile={mobile}
          toggleDrawer={toggleDrawer}
          setCurrentSubscription={setCurrentSubscription}
          currentUser={currentUser}
        />
      </Box>
    </SwipeableDrawer>
  )
}
