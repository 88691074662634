import React, { useState, useEffect } from 'react'
import { firestore } from '../../../utils/firebase/firebase'
import AdminPage from '../../../components/page/AdminPage'
import { Box } from '@mui/material'
import dayjs from 'dayjs'
import TotalMembers from './components/TotalMembers'
import MemberStatus from './components/MemberStatus'
import MemberAgeGroup from './components/MemberAgeGroup'
import NewMembers from './components/NewMembers'
import WebKpis from './components/WebKpis'

function Dashboard() {
  const [users, setUsers] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const getData = async () => {
    // Get user Data
    const userArray = []
    await firestore
      .collection('users')
      .where('status', 'in', ['trialing', 'active', 'past_due'])
      .get()
      .then(async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const user = doc.data()
          user.id = doc.id
          user.createdAt =
            doc.data().createdAt && dayjs(doc.data().createdAt.toDate())
          userArray.push(user)
        })
        setUsers(userArray)
        console.group(users)
        setIsLoading(false)
        return
      })
      .catch((error) => {
        console.log('Error getting documents: ', error)
      })
  }

  useEffect(() => {
    getData()
    //eslint-disable-next-line
  }, [])

  const newMemberData = users
    .filter((u) => {
      return u.createdAt > dayjs().subtract(1, 'week')
    })
    .sort((a, b) => {
      return dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? 1 : -1
    })

  return (
    <AdminPage loading={isLoading}>
      {users && (
        <>
          <Box display={'flex'} flexWrap={'wrap'} gap={2}>
            <TotalMembers users={users} newUsers={newMemberData} />
            <MemberStatus users={users} />
            <MemberAgeGroup users={users} />
            <WebKpis />
          </Box>
          <NewMembers newUsers={newMemberData} />
        </>
      )}
    </AdminPage>
  )
}

export default Dashboard
