import { Box, Typography } from '@mui/material'
import { PieChart } from '@mui/x-charts/PieChart'

const MemberStatus = ({ users }) => {
  const activeUsers = users.filter((user) => user.status === 'active')
  const overdueUsers = users.filter((user) => user.status === 'past_due')
  const trialUsers = users.filter((user) => user.status === 'trialing')

  const userStatusData = [
    { id: 0, value: activeUsers.length, label: 'Active' },
    { id: 1, value: trialUsers.length, label: 'Trial' },
    { id: 2, value: overdueUsers.length, label: 'Overdue' },
  ]

  return (
    <Box
      flexGrow={1}
      sx={{
        border: (t) => `1px solid ${t.palette.primary.main}`,
        padding: 3,
        borderRadius: '10px',
        width: 'fit-content',
      }}
    >
      <Typography>Status</Typography>
      <PieChart
        colors={(t) => ['#f7df45', '#6ab8ed', '#ff5959']}
        series={[
          {
            data: userStatusData,
            highlightScope: { faded: 'global', highlighted: 'item' },
            faded: { innerRadius: 30, additionalRadius: -30 },
            innerRadius: 30,
            outerRadius: 130,
            paddingAngle: 0,
            cornerRadius: 2,
            startAngle: 0,
            endAngle: 360,
            cx: 150,
            cy: 150,
          },
        ]}
        height={300}
      />
    </Box>
  )
}

export default MemberStatus
