import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/functions'
import 'firebase/functions'
import 'firebase/analytics'
import 'firebase/compat/storage'
import { getAuth } from 'firebase/auth'

//v9
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'

const prodConfig = {
  apiKey: 'AIzaSyDKPPY78vNZ0WjugF-YreO8LqRSxXRvnAw',
  authDomain: 'train-hyve.firebaseapp.com',
  projectId: 'train-hyve',
  storageBucket: 'train-hyve.appspot.com',
  messagingSenderId: '97321419675',
  appId: '1:97321419675:web:b8d9f0081b49d55d06d73e',
  measurementId: 'G-SHN2923PSG',
}

const devConfig = {
  apiKey: 'AIzaSyABgc-dvhzTmEpFLIPgIpI8pJSmsGd8L-U',
  authDomain: 'hyve-dev-99778.firebaseapp.com',
  projectId: 'hyve-dev-99778',
  storageBucket: 'hyve-dev-99778.appspot.com',
  messagingSenderId: '132727501421',
  appId: '1:132727501421:web:f57706aa7a0fedefb20564',
}

const firebaseApp = initializeApp(
  process.env.NODE_ENV === 'development' ? devConfig : prodConfig
)

firebase.initializeApp(
  process.env.NODE_ENV === 'development' ? devConfig : prodConfig
)

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return

  const userRef = await firestore.doc(`users/${userAuth.uid}`)
  const snapshot = await userRef.get()

  if (!snapshot.exists) {
    const { email } = userAuth
    const createdAt = new Date()

    try {
      userRef.set({ email, createdAt, ...additionalData, credits: 0 })
    } catch (error) {
      console.log('error creating user', error.message)
    }
  }
  return userRef
}

// Sends user to the Strip hosted payment page
// API - Stripe price key
// user - Firebase Auth UID
export const sendToCheckout = async (API, user) => {
  const docRef = await firestore
    .collection('users')
    .doc(user)
    .collection('checkout_sessions')
    .add({
      price: `${API}`,
      allow_promotion_codes: true,
      success_url: 'https://www.trainhyve.com/thanks',
      cancel_url: window.location.origin,
    })
  // Wait for the CheckoutSession to get attached by the extension

  docRef.onSnapshot((snap) => {
    const { error, url } = snap.data()
    if (error) {
      // Show an error to your customer and
      // inspect your Cloud Function logs in the Firebase console.
      alert(`An error occured: ${error.message}`)
    }
    if (url) {
      // We have a Stripe Checkout URL, let's redirect.
      window.location.assign(url)
    }
  })
}

// Sends user to the Strip hosted payment page
// API - Stripe price key
// user - Firebase Auth UID
export const sendToSingleCheckout = async (API, user) => {
  const docRef = await firestore
    .collection('users')
    .doc(user)
    .collection('checkout_sessions')
    .add({
      mode: 'payment',
      price: `${API}`,
      allow_promotion_codes: true,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    })
  // Wait for the CheckoutSession to get attached by the extension

  docRef.onSnapshot((snap) => {
    const { error, url } = snap.data()
    if (error) {
      // Show an error to your customer and
      // inspect your Cloud Function logs in the Firebase console.
      alert(`An error occured: ${error.message}`)
    }
    if (url) {
      // We have a Stripe Checkout URL, let's redirect.
      window.location.assign(url)
    }
  })
}

export const auth = firebase.auth()
export const newAuth = getAuth(firebaseApp)
export const firestore = firebase.firestore()
export const newFirestore = getFirestore(firebaseApp)
export const storage = firebase.storage()
export const functions = getFunctions(firebaseApp, 'europe-west2')

export const portalRedirect = async () => {
  const functionRef = firebase
    .app()
    .functions('europe-west2')
    .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink')
  const { data } = await functionRef({ returnUrl: window.location.origin })
  window.location.assign(data.url)
}

export const resetPassword = (email) => {
  firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => {
      console.log(' Password reset email sent!')
    })
    .catch((error) => {
      var errorMessage = error.message
      console.log(errorMessage)
    })
}

export default firebase
