import { subscriptionActionTypes } from './subscription.types';

const INITIAL_STATE = {
	currentSubscription: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case subscriptionActionTypes.SET_CURRENT_SUBSCRIPTION:
			return { ...state, currentSubscription: action.payload };

		default:
			return state;
	}
};

export default userReducer;
