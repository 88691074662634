import { useEffect, useState } from 'react'
import dayjs from 'dayjs'

const useTrend = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const getData = async () => {
    let url = new URL(`https://api.tinybird.co/v0/pipes/kpis.json`)

    const result = await fetch(url, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TINYBIRD_API_KEY}`,
      },
    })
      .then((r) => r.json())
      .then((r) => r)
      .catch((e) => e.toString())

    if (!result.data) {
      console.error(`there is a problem running the query: ${result}`)
      setLoading(false)
    } else {
      setData(result.data)
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
    //eslint-disable-next-line
  }, [])

  const visits = data.map((t) => t.visits)
  const pageViews = data.map((t) => t.pageviews)
  const date = data.map((t) => dayjs(t.date).format('DD MMM'))
  const averageSession = data.map((t) => Math.round(t.avg_session_sec / 60))

  return { visits, pageViews, averageSession, date, loading }
}

export default useTrend
