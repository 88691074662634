import { createTheme } from '@mui/material'

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#d3b908',
      contrastText: '#000',
    },
    secondary: {
      main: '#222e50',
    },
    background: {
      default: '#000000',
      paper: '#171717',
    },
    white: {
      main: '#fff',
    },
    common: {
      black: '#000',
      white: '#fff',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Oswald',
    },
    h4: {
      fontFamily: 'Montserrat',
    },
    fontFamily: 'Oswald',
    body1: {
      fontFamily: 'Montserrat',
      color: 'white',
    },
    body2: {
      fontFamily: 'Montserrat',
      color: 'white',
    },
    caption: {
      fontFamily: 'Montserrat',
    },
    button: {
      letterSpacing: '1.5px',
    },
  },
})

export default theme
