import React from 'react'
import './styles.scss'
import { Link } from 'react-router-dom'
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineLinkedin,
} from 'react-icons/ai'

function Footer() {
  return (
    <footer>
      <div className='footer__wrap'>
        <div className='footer__links'>
          <div className='footer__customerService footer__block'>
            <h4 className='footer__heading'>Customer Service</h4>
            <a className='footer__link' href='mailto:hi@trainhyve.com'>
              Contact Us
            </a>

            <Link className='footer__link' to='/faq'>
              FAQ
            </Link>
            <div className='footer__social'>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://www.facebook.com/trainhyve/'
              >
                <AiOutlineFacebook className='footer__facebook' />
              </a>
              <a
                rel='noreferrer'
                href='https://www.instagram.com/trainhyve/'
                target='_blank'
              >
                <AiOutlineInstagram className='footer__instagram' />
              </a>
              <a
                rel='noreferrer'
                href='https://www.linkedin.com/company/74954216'
                target='_blank'
              >
                <AiOutlineLinkedin className='footer__linkedin' />
              </a>
            </div>
          </div>
          <div className='footer__about footer__block'>
            <h4 className='footer__heading'>About</h4>
            <Link className='footer__link' to='/rules'>
              House Rules
            </Link>
          </div>
          <div className='footer__careers footer__block'>
            <h4 className='footer__heading'>Careers</h4>
            <a
              className='footer__link'
              target='_blank'
              href='https://www.linkedin.com/company/trainhyve/'
              rel='noreferrer'
            >
              Careers
            </a>
          </div>
          <div className='footer__legal footer__block'>
            <h4 className='footer__heading'>Legal</h4>
            <Link className='footer__link' to='/terms'>
              Terms & Conditions
            </Link>
            <Link className='footer__link' to='/terms'>
              Privacy Policy
            </Link>
            <Link className='footer__link' to='/terms'>
              Cookie Policy
            </Link>
          </div>
        </div>
        <div className='footer__socket'>
          <p>
            HYVE is a trading name of J Load INC LIMITED, registered in England
            & Wales with company number 12332350. Registered office: Fairways
            Cliff Walk, Pendine, Carmarthen, United Kingdom, SA33 4PA ©{' '}
            {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
