import { systemActionTypes } from './system.types';

export const setLoading = (system) => ({
	type: systemActionTypes.SET_LOADING,
	payload: system,
});

export const setStudioId = (system) => ({
	type: systemActionTypes.SET_STUDIO_ID,
	payload: system,
});

export const setLocation = (system) => ({
	type: systemActionTypes.SET_LOCATION,
	payload: system,
});

export const setStudio = (system) => ({
	type: systemActionTypes.SET_STUDIO,
	payload: system,
});

export const setBookingRequest = (system) => ({
	type: systemActionTypes.SET_BOOKING_REQUEST,
	payload: system,
});
