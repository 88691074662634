import firebase from '../../../utils/firebase/firebase'
import dayjs from 'dayjs'
import { firestore } from '../../../utils/firebase/firebase'
import { toast } from 'react-toastify'

export const resourceMap = [
  { resourceId: 't4HqiPsnmZ79FSzUrKtt', resourceTitle: 'STUDIO 1' },
  { resourceId: 'TSp2LZix7eGLiNXgdWBW', resourceTitle: 'STUDIO 4' },
  {
    resourceId: 'kM8p1jSenI4M0Mr1PzBo',
    resourceTitle: 'STUDIO 5 ',
  },
  { resourceId: 'jlqBiWXgO4ITUWLHrfes', resourceTitle: 'STUDIO 6' },
  { resourceId: 'IAU4CwQKmedKSYf1LRE1', resourceTitle: 'STUDIO 7' },
  { resourceId: 'ZkxxmrSfsWYDv5RerP51', resourceTitle: 'WATTBIKES' },
  { resourceId: 'xu8if6J5KizyDieVileo', resourceTitle: 'POOL' },
]

export const dateFromDaysPrior = (days) =>
  firebase.firestore.Timestamp.fromDate(dayjs().subtract(days, 'days').toDate())

// Cancel Studio Booking
export const cancelSingleBooking = (bookingId) => {
  const currentTime = firebase.firestore.Timestamp.now()
  firestore
    .collection('bookings')
    .doc(bookingId)
    .update({ status: 'cancelled', cancelledAt: currentTime })
    .then(() => {
      toast.success('CLASS CANCELLED SUCCESSFULLY')
    })
    .catch((error) => {
      toast.error(`Error updating document: ', ${error.message}`)
    })
}

// Cancel Studio Booking
export const reinstateSingleBooking = (bookingId) => {
  firestore
    .collection('bookings')
    .doc(bookingId)
    .update({ status: 'confirmed', cancelledAt: null, isPublished: false })
    .then(() => {
      toast.success('CLASS REINSTATED SUCCESSFULLY')
    })
    .catch((error) => {
      toast.error(`Error updating document: ', ${error.message}`)
    })
}
