import { Box, Stack } from '@mui/material'
import Page from '../../components/page/Page'
import AdminNav from '../nav/AdminNav'
import Loader from '../Loader'

const AdminPage = ({ children, loading }) => {
  return (
    <Page hideFooter>
      <Stack>
        <AdminNav />
        <Box>{loading ? <Loader /> : children}</Box>
      </Stack>
    </Page>
  )
}

export default AdminPage
