import React, { useState } from 'react'
import { Calendar, Views, dayjsLocalizer } from 'react-big-calendar'
import dayjs from 'dayjs'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import './styles.scss'
import { Fade } from 'react-awesome-reveal'
import BookingPopup from './components/bookingPopup/BookingPopup'
import { resourceMap } from './helpers'
import useSubscribedEvents from './useSubscribedEvents'
import ClassModal from './components/ClassModal'
import AdminPage from '../../../components/page/AdminPage'
import { changeBooking } from '../../../utils/helpers'
import { toast } from 'react-toastify'

const localizer = dayjsLocalizer(dayjs)
const DnDCalendar = withDragAndDrop(Calendar)

function CalendarPage() {
  const [popupToggle, setPopupToggle] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState({})
  const [viewClass, setViewClass] = useState()
  const { events, loading } = useSubscribedEvents()

  const timeNow = new Date()
  const clearClass = () => {
    setViewClass()
  }

  const updateBooking = async ({ event, start, end, resourceId }) => {
    if (event.start < timeNow) {
      return toast.error('Cannot change past bookings.')
    }
    try {
      toast.promise(changeBooking(event.eventId, start, end, resourceId), {
        pending: 'Updating booking',
        success: 'Booking updated',
        error: {
          render({ data }) {
            return data.message
          },
        },
      })
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <AdminPage loading={loading}>
      <BookingPopup
        selectedEvent={selectedEvent}
        popupToggle={popupToggle}
        setPopupToggle={setPopupToggle}
      />
      {viewClass && (
        <ClassModal
          isOpen={!!viewClass}
          onClose={clearClass}
          gymClass={viewClass}
        />
      )}
      <Fade>
        <div className='calendarApp'>
          <DnDCalendar
            localizer={localizer}
            defaultView={Views.DAY}
            events={events}
            views={['day', 'month']}
            min={new Date(0, 0, 0, 6, 0, 0)}
            max={new Date(0, 0, 0, 22, 0, 0)}
            resources={resourceMap}
            resourceIdAccessor='resourceId'
            resourceTitleAccessor='resourceTitle'
            onSelectEvent={(event) => {
              event.isClass ? setViewClass(event) : setPopupToggle(true)
              setSelectedEvent(event)
            }}
            onEventResize={updateBooking}
            resizable
            onEventDrop={updateBooking}
          />
        </div>
      </Fade>
    </AdminPage>
  )
}

export default CalendarPage
