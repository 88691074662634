import styled from 'styled-components'

export const Membership = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 30px;
`

export const Header = styled.div`
  display: grid;
  grid-template-columns: 50px auto;
  gap: 5px;
`

export const Stripe = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #5952e6;
  cursor: pointer;

  &:hover {
    background: #5952e6;
  }
`

export const Status = styled.div`
  border: 1px solid ${(props) => props.background || '#21B446'};
  color: ${(props) => props.background || '#21B446'};
  text-align: center;

  text-transform: uppercase;
  padding: 10px;
`

export const MembershipDetails = styled.div`
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(4, 1fr);

  div {
    border: 1px solid ${({ theme }) => theme.colors.yellow};

    text-align: center;
    h5 {
      padding: 5px;
      font-size: 0.7rem;
    }
    p {
      padding: 20px;
      font-size: 0.9rem;
    }
  }
  @media only screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const PaymentsHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr 1fr;
  background: #e9e9e9;
  color: black;
  padding: 5px;
  text-transform: uppercase;
`

export const Payments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: -3px;
`

export const Payment = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr 1fr;
  background: white;
  padding: 5px;
  color: black;
  font-size: 0.8rem;
`

export const PaymentsButton = styled.div`
  button {
    width: 100%;
    text-transform: uppercase;
    background: ${({ theme }) => theme.colors.yellow};
    padding: 10px;
    cursor: pointer;
    color: black;
    &:hover {
      opacity: 0.9;
    }
  }
`
