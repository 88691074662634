import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { formatPrice } from '../helpers'
import { DateTimeField } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { portalRedirect } from '../../../utils/firebase/firebase'

const MembershipDetails = ({
  subscriptionData: {
    priceData: { description, unit_amount },
    subscriptionData: { cancel_at_period_end, status, current_period_end },
  },
}) => {
  const [loading, setLoading] = useState(false)

  const handleClick = () => {
    setLoading(true)
    portalRedirect()
  }

  return (
    <Stack gap={1}>
      <Typography mb={2} variant='h5' color='white.main'>
        HYVE Llanelli
      </Typography>
      {cancel_at_period_end && (
        <Alert severity='info'>
          <AlertTitle>Cancellation requested</AlertTitle>Your membership will
          end at tthe end of your biilling period
        </Alert>
      )}

      <TextField
        disabled
        label='Plan  &nbsp; &nbsp;'
        variant='outlined'
        defaultValue={description}
      />
      <TextField
        disabled
        label='Price  &nbsp; &nbsp;'
        variant='outlined'
        defaultValue={formatPrice(unit_amount)}
      />
      <TextField
        disabled
        label='Status  &nbsp; &nbsp;'
        variant='outlined'
        defaultValue={status}
      />
      <DateTimeField
        label='Next payment due&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
        defaultValue={dayjs.unix(current_period_end.seconds)}
        disabled
        format='DD MMM YYYY hh:mm a'
      />
      <Button
        variant='contained'
        onClick={handleClick}
        disabled={loading}
        sx={{ p: 1 }}
      >
        {loading ? (
          <CircularProgress size='20px' />
        ) : (
          `Manage membership & billing`
        )}
      </Button>
    </Stack>
  )
}

export default MembershipDetails
