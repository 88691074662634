import * as yup from 'yup'
import dayjs from 'dayjs'

// Todo add the phone regex and test.
// const phoneRegex = /^(07[\d]{8,12}|447[\d]{7,11})$/
export const validationSchema = yup.object({
  firstName: yup.string('Enter your name').required('Your name is required'),
  lastName: yup.string('Enter your name').required('Your name is required'),
  mobile: yup.string('Enter your mobile').required('Your mobile is required'),
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], "Passwords don't match")
    .required('Confirm Password is required'),
  dob: yup
    .date('enter a valid date')
    .max(dayjs().subtract(16, 'year'), 'You must be at least 16 to join HYVE')
    .required('Enter your date of birth'),
  terms: yup
    .bool()
    .oneOf([true], 'Field must be checked')
    .required('Please accept our terms and conditions to continue'),
})

//.matches(phoneRegex, 'Must be a valid mobile number'),

export const convertCycle = (productCycle) => {
  switch (productCycle) {
    case 'Quarterly':
      return '3 months'
    case 'Annually':
      return 'year'
    case 'Weekly':
      return 'week'
    default:
      return 'month'
  }
}
