import { useEffect, useState } from 'react'
import { firestore } from '../../utils/firebase/firebase'

const useUser = (userId) => {
  const [user, setUser] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  useEffect(() => {
    const unsubscribe = firestore
      .collection('users')
      .doc(userId)
      .onSnapshot(
        (doc) => {
          if (!doc.exists) {
            setLoading(false)
            setError('User not found')
          } else {
            setUser({ ...doc.data(), id: doc.id })
            setLoading(false)
          }
        },
        (error) => {
          setLoading(false)
          setError(error.message)
        }
      )

    return () => {
      unsubscribe()
    }
  }, [userId])

  return { user, loading, error }
}

export default useUser
