import { Box, Button, Card, Divider, Typography } from '@mui/material'
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined'
import MoneyOffCsredOutlinedIcon from '@mui/icons-material/MoneyOffCsredOutlined'
import CurrencyPoundOutlinedIcon from '@mui/icons-material/CurrencyPoundOutlined'
import FitnessCenterOutlinedIcon from '@mui/icons-material/FitnessCenterOutlined'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import React from 'react'
import dayjs from 'dayjs'
import { convertCycle } from './helpers'

const MembershipSummary = ({ product, onGoBack }) => {
  return (
    <Card
      variant='outlined'
      sx={{
        border: (theme) => `1px solid ${theme.palette.primary.main} `,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'space-between',
      }}
    >
      <Box>
        <Typography variant='h4' mb={3}>
          Membership summary
        </Typography>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography variant='h6'>Pay {product.cycle}</Typography>
          <Typography variant='h6'>
            £{product.priceMain}.{product.priceDecimal}
          </Typography>
        </Box>
        <Divider sx={{ mb: 2, mt: 1 }} />
        <Box display={'grid'} gap={1}>
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <AdjustOutlinedIcon fontSize='small' />
            <Typography>Unlimited 24/7 access to the gym</Typography>
          </Box>
          {product.type === 'family' && (
            <Box display={'flex'} alignItems={'center'} gap={1}>
              <AdjustOutlinedIcon fontSize='small' />
              <Typography>
                Household membership for 2 adults & 2 children
              </Typography>
            </Box>
          )}
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <AdjustOutlinedIcon fontSize='small' />
            <Typography>No Contract. Cancel anytime</Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <AdjustOutlinedIcon fontSize='small' />
            <Typography>Unlimited free member classes</Typography>
          </Box>
          {product.cycle !== 'Weekly' && (
            <Box display={'flex'} alignItems={'center'} gap={1}>
              <AdjustOutlinedIcon fontSize='small' />
              <Typography>24hr free trial</Typography>
            </Box>
          )}
          <Box display={'flex'} alignItems={'start'} gap={1}>
            <AdjustOutlinedIcon fontSize='small' />
            <Box>
              <Typography>
                {product.priceMain} HYVE credits per{' '}
                {convertCycle(product.cycle)}
              </Typography>
              <Typography variant='body2' color={'primary'}>
                Redeem HYVE credist for private studio or pool bookings, guest
                passes and discounted supplements
              </Typography>
            </Box>
          </Box>
        </Box>
        <Typography variant='h6' mt={4}>
          Payment schedule
        </Typography>
        <Divider sx={{ mb: 2, mt: 1 }} />
        {product.cycle !== 'Weekly' ? (
          <PayScheduleWithTrial product={product} />
        ) : (
          <PayScheduleWithoutTrial product={product} />
        )}
      </Box>

      <Button
        variant='outlined'
        size='large'
        fullWidth
        onClick={onGoBack}
        sx={{ mt: 2 }}
      >
        Back to select membership
      </Button>
    </Card>
  )
}

export default MembershipSummary

const PayScheduleWithTrial = ({ product }) => {
  const tomorrow = dayjs().add(25, 'hour').format('hh:mm a on ddd D MMM')
  return (
    <Box display={'grid'} gap={1.5}>
      <Box display={'flex'} alignItems={'start'} gap={1}>
        <MoneyOffCsredOutlinedIcon fontSize='small' />
        <Typography>
          Today pay{' '}
          <Typography component={'span'} fontWeight={'bold'} color={'primary'}>
            £0.00
          </Typography>{' '}
        </Typography>
      </Box>
      <Box display={'flex'} alignItems={'start'} gap={1}>
        <FitnessCenterOutlinedIcon fontSize='small' />
        <Typography>
          Trial the gym for 24 hours. Not suitable? Cancel online and you won't
          be charged.
        </Typography>
      </Box>
      <Box display={'flex'} alignItems={'start'} gap={1}>
        <CurrencyPoundOutlinedIcon fontSize='small' />
        <Typography>
          Your first payment of{' '}
          <Typography component={'span'} fontWeight={'bold'} color={'primary'}>
            £{product.priceMain}.{product.priceDecimal}{' '}
          </Typography>
          will be debited around {tomorrow}
        </Typography>
      </Box>
      <Box display={'flex'} alignItems={'start'} gap={1}>
        <CurrencyPoundOutlinedIcon fontSize='small' />
        <Typography>
          Then pay{' '}
          <Typography component={'span'} fontWeight={'bold'} color={'primary'}>
            £{product.priceMain}.{product.priceDecimal}
          </Typography>{' '}
          at the same time every {convertCycle(product.cycle)}
        </Typography>
      </Box>
      <Box display={'flex'} alignItems={'start'} gap={1}>
        <SentimentVeryDissatisfiedIcon />
        <Typography>
          Ever unhappy? No hard feelings! Cancel online in minutes and any
          future charges are stopped immediately.
        </Typography>
      </Box>
    </Box>
  )
}

const PayScheduleWithoutTrial = ({ product }) => {
  return (
    <Box display={'grid'} gap={1.5}>
      <Box display={'flex'} alignItems={'start'} gap={1}>
        <CurrencyPoundOutlinedIcon fontSize='small' />
        <Typography>
          Pay{' '}
          <Typography component={'span'} fontWeight={'bold'} color={'primary'}>
            £{product.priceMain}.{product.priceDecimal}{' '}
          </Typography>
          today
        </Typography>
      </Box>
      <Box display={'flex'} alignItems={'start'} gap={1}>
        <CurrencyPoundOutlinedIcon fontSize='small' />
        <Typography>
          Then{' '}
          <Typography component={'span'} fontWeight={'bold'} color={'primary'}>
            £{product.priceMain}.{product.priceDecimal}
          </Typography>{' '}
          at the same time every {convertCycle(product.cycle)}
        </Typography>
      </Box>
      <Box display={'flex'} alignItems={'start'} gap={1}>
        <SentimentVeryDissatisfiedIcon />
        <Typography>
          Ever unhappy? No hard feelings! Cancel online in minutes and any
          future charges are stopped immediately.
        </Typography>
      </Box>
    </Box>
  )
}
