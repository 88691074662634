import { Box, Typography } from '@mui/material'
import React from 'react'

const TotalMembers = ({ users, newUsers }) => {
  return (
    <Box
      sx={{
        border: (t) => `1px solid ${t.palette.primary.main}`,
        padding: 3,
        borderRadius: '10px',
        width: 'fit-content',
      }}
      display={'grid'}
      alignItems={'center'}
    >
      <Typography textAlign={'center'}>Total Members</Typography>
      <Typography sx={{ fontSize: '5rem', textAlign: 'center' }}>
        {users.length}
      </Typography>
      <Typography
        textAlign={'center'}
        fontSize={'0.8rem'}
        color={'primary'}
        textTransform={'uppercase'}
        fontWeight={'bold'}
      >
        <Typography component={'span'} fontWeight={'bold'}>
          + {newUsers.length}
        </Typography>{' '}
        in the last 7 days
      </Typography>
    </Box>
  )
}

export default TotalMembers
