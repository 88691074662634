import {
  TableCell,
  Typography,
  TableRow as MUIRow,
  Button,
} from '@mui/material'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import CanxModal from './CanxModal'
import dayjs from 'dayjs'

const TableRow = ({ row, gymClass, source }) => {
  const history = useHistory()
  const [showCanxModal, setShowCanxModal] = useState(false)

  const toggleModal = () => {
    setShowCanxModal((ps) => !ps)
  }

  return (
    <MUIRow
      sx={{
        '&:hover': {
          backgroundColor: (t) => t.palette.primary.dark,
        },
      }}
    >
      <TableCell>
        <Typography
          variant='body2'
          textTransform={'capitalize'}
          onClick={() => history.push(`/admin/users/${row.primeId ?? row.id}`)}
          sx={{
            '&:hover': {
              cursor: 'pointer',
              color: 'background.default',
            },
          }}
        >
          {row.firstName} {row.lastName}
        </Typography>
      </TableCell>
      <TableCell>{row.email}</TableCell>
      <TableCell>{row.mobile}</TableCell>
      <TableCell>{dayjs().diff(`${row.dob}`, 'year')}</TableCell>
      <TableCell>
        <Button variant='contained' color='secondary' onClick={toggleModal}>
          Cancel
        </Button>
      </TableCell>
      <CanxModal
        isOpen={showCanxModal}
        onClose={toggleModal}
        user={row}
        gymClass={gymClass}
        source={source}
      />
    </MUIRow>
  )
}

export default TableRow
