import { userActionTypes } from './user.types';

export const setCurrentUser = (user) => ({
	type: userActionTypes.SET_CURRENT_USER,
	payload: user,
});

export const setUserSubscription = (userSubscription) => ({
	type: userActionTypes.SET_USER_SUBSCRIPTION,
	payload: userSubscription,
});
