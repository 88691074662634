import { systemActionTypes } from './system.types';

const INITIAL_STATE = {
	loading: false,
	location: {
		code: 'LLA1',
		id: 'M2tQnpS5QHIA8w2qJD5V',
		name: 'Llanelli',
	},
	studio: false,
	bookingRequest: [],
};

const systemReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case systemActionTypes.SET_LOADING:
			return { ...state, loading: action.payload };

		case systemActionTypes.SET_STUDIO_ID:
			return { ...state, studioId: action.payload };

		case systemActionTypes.SET_LOCATION:
			return { ...state, location: action.payload };

		case systemActionTypes.SET_STUDIO:
			return { ...state, studio: action.payload };

		case systemActionTypes.SET_BOOKING_REQUEST:
			return { ...state, bookingRequest: action.payload };

		default:
			return state;
	}
};

export default systemReducer;
