import { useEffect, useState } from 'react'
import { firestore } from '../../utils/firebase/firebase'

const useClass = (id) => {
  const [gymClass, setGymClass] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  useEffect(() => {
    const unsubscribe = firestore
      .collection('bookings')
      .doc(id)
      .onSnapshot(
        (doc) => {
          if (!doc.exists) {
            setError('Class not found, please try again.')
            setLoading(false)
          } else {
            setGymClass({ ...doc.data(), id: doc.id })
            setLoading(false)
          }
        },
        (error) => {
          setError('Error retrieving bookings: ', error.message)
          setLoading(false)
        }
      )

    return () => {
      unsubscribe()
    }
  }, [id])

  return { gymClass, loading, error }
}

export default useClass
