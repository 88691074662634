import React from 'react'
import Modal from '../../../components/Modal'
import { Box, Button, Typography } from '@mui/material'
import dayjs from 'dayjs'
import Span from '../../../components/Span'
import { cancelStudioBooking } from '../helpers'
import { connect } from 'react-redux'

const ConfirmCanxStudioModal = ({ isOpen, onClose, booking, currentUser }) => {
  const handleCancelBooking = () => {
    cancelStudioBooking(currentUser, booking.id, booking.bookingDetails.credits)
    onClose()
  }

  return (
    <Modal title={'Are you sure?'} onClose={onClose} isOpen={isOpen}>
      <Typography>
        Are you sure you want to cancel your booking for{' '}
        <Span>{booking.studio.name}</Span> on{' '}
        <Span>{dayjs(booking.startTime.toDate()).format('ddd DD MMM')}</Span> at{' '}
        <Span>{dayjs(booking.startTime.toDate()).format('h:mm A')}</Span>? Any
        credits paid for this booking will be refunded immediately to your
        account.
      </Typography>
      <Box mt={2} display={'flex'} gap={1}>
        <Button variant='contained' color='error' onClick={handleCancelBooking}>
          Cancel booking
        </Button>
        <Button variant='outlined' onClick={onClose}>
          Exit
        </Button>
      </Box>
    </Modal>
  )
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
})

export default connect(mapStateToProps, null)(ConfirmCanxStudioModal)
