import React, { useState } from 'react'
import Modal from '../../../../components/Modal'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import { NavLink } from 'react-router-dom'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { cancelSingleBooking } from '../helpers'

const ClassModal = ({ isOpen, onClose, gymClass }) => {
  const [copied, setCopied] = useState(false)
  const copyLink = () => {
    navigator.clipboard.writeText(
      `https://www.trainhyve.com/classes/${gymClass.eventId}`
    )
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, [1000])
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={'Class details'}>
      {!gymClass.isPublished && (
        <Alert sx={{ mb: 2 }} severity='warning'>
          <AlertTitle>CLASS NOT PUBLISHED</AlertTitle>
          View the full class details to publish this class.
        </Alert>
      )}
      <Box
        sx={{
          background: `url(${gymClass.gymClass.coverImage}), linear-gradient(270deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 23%, rgba(0,0,0,0.6) 41%, rgba(0,0,0,1) 100%, rgba(0,0,0,0.6) 100%);`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundBlendMode: 'multiply',
          backgroundSize: 'cover',
        }}
        p={2}
        borderRadius={1}
      >
        <Typography
          textTransform={'uppercase'}
          fontWeight={500}
          color='primary'
          variant='h6'
        >
          {gymClass.gymClass.title}
        </Typography>
        <Typography textTransform={'uppercase'}>
          {dayjs(gymClass.start).format('dddd D MMM HH:mm a')}
        </Typography>
        {gymClass.isRecurring && (
          <Typography fontWeight={300} fontSize={14}>
            Recurring session {gymClass.recurringWeek} of{' '}
            {gymClass.recurringCount > 0 ? gymClass.recurringCount : '-'}
          </Typography>
        )}
      </Box>
      <Stack mt={1} gap={1}>
        <Box display={'flex'} gap={1}>
          <TextField defaultValue={'Trainer'} disabled sx={{ flexGrow: 1 }} />
          <TextField
            defaultValue={`${gymClass.firstName} ${gymClass.lastName}`}
            sx={{ textTransform: 'capitalize', flexGrow: 1 }}
            disabled
          />
        </Box>
        <Box display={'flex'} gap={1}>
          <TextField defaultValue={'Enrolled'} disabled sx={{ flexGrow: 1 }} />
          <TextField
            defaultValue={`${gymClass.gymClass.enrolledMembers.length} of ${gymClass.gymClass.capacity} members`}
            disabled
            sx={{ flexGrow: 1 }}
            error
          />
        </Box>
        <Box display={'flex'} gap={1}>
          <TextField
            defaultValue={'Waitlisted'}
            disabled
            sx={{ flexGrow: 1 }}
          />
          <TextField
            defaultValue={`${gymClass.gymClass.waitlistMembers.length} member${
              gymClass.gymClass.waitlistMembers.length > 1 ? 's' : ''
            }`}
            disabled
            sx={{ flexGrow: 1 }}
          />
        </Box>
        <FormControl variant='outlined'>
          <InputLabel
            htmlFor='outlined-adornment-password'
            disabled
            sx={{ fontSize: 14 }}
          >
            {`https://www.trainhyve.com/classes/${gymClass.eventId}`}
          </InputLabel>
          <OutlinedInput
            disabled
            endAdornment={
              <InputAdornment position='end'>
                {copied && 'copied'}
                <IconButton onClick={copyLink} edge='end'>
                  {copied ? <CheckCircleIcon /> : <ContentPasteIcon />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Stack>
      <Button
        component={NavLink}
        to={`/admin/classes/${gymClass.eventId}`}
        variant='contained'
        fullWidth
        sx={{ mt: 2 }}
      >
        View full details
      </Button>
      <Button
        variant='contained'
        color='error'
        sx={{ mt: 1 }}
        fullWidth
        onClick={() => {
          cancelSingleBooking(gymClass.eventId)
          onClose()
        }}
      >
        Cancel class
      </Button>
    </Modal>
  )
}

export default ClassModal
