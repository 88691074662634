import { useEffect, useState } from 'react'
import { newFirestore } from '../../utils/firebase/firebase'
import { collection, query, where, onSnapshot } from 'firebase/firestore'
import { sortAndFilterBookings } from './helpers'

const useUserBookings = (userId) => {
  const [bookings, setBookings] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  useEffect(() => {
    const q = query(
      collection(newFirestore, 'bookings'),
      where('user.id', '==', userId),
      where('isClass', '==', false)
    )
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const bookings = []
        querySnapshot.forEach((doc) => {
          bookings.push({ ...doc.data(), id: doc.id })
        })
        setBookings(sortAndFilterBookings(bookings))
        setLoading(false)
      },
      (error) => {
        setError(error.message)
      }
    )

    return () => {
      unsubscribe()
    }
  }, [userId])

  return { bookings, loading, error }
}

export default useUserBookings
