import {
  Alert,
  AlertTitle,
  Button,
  Chip,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'

import { Link, useHistory } from 'react-router-dom'

const ClassTable = ({ classes }) => {
  const history = useHistory()

  if (classes.length === 0) {
    return (
      <Alert severity='info'>
        <AlertTitle>No future classes scheduled</AlertTitle>
        <Link to='/admin/book?class'>
          <Button variant='contained'>Schedule a class</Button>
        </Link>
      </Alert>
    )
  }
  return (
    <TableContainer>
      <Table>
        <TableHead
          sx={{
            backgroundColor: (t) => t.palette.primary.main,
          }}
        >
          <TableRow>
            <TableCell sx={{ color: (t) => t.palette.background.default }}>
              Status
            </TableCell>
            <TableCell sx={{ color: (t) => t.palette.background.default }}>
              Date
            </TableCell>
            <TableCell sx={{ color: (t) => t.palette.background.default }}>
              Title
            </TableCell>
            <TableCell sx={{ color: (t) => t.palette.background.default }}>
              PT
            </TableCell>
            <TableCell sx={{ color: (t) => t.palette.background.default }}>
              Studio
            </TableCell>
            <TableCell
              sx={{
                textAlign: 'center',
                color: (t) => t.palette.background.default,
              }}
            >
              Registered
            </TableCell>
            <TableCell
              sx={{
                textAlign: 'center',
                color: (t) => t.palette.background.default,
              }}
            >
              Waitlisted
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {classes.map((row) => {
            const soldPercent =
              (row.class.enrolledMembers.length / row.class.capacity) * 100
            return (
              <TableRow
                key={row.id}
                sx={{
                  '&:hover': {
                    backgroundColor: (t) => t.palette.primary.dark,
                    cursor: 'pointer',
                  },
                }}
                onClick={() => history.push(`/admin/classes/${row.id}`)}
              >
                <TableCell sx={{ textTransform: 'uppercase' }}>
                  <Chip
                    label={
                      row.status === 'cancelled'
                        ? 'Cancelled'
                        : !row.isPublished
                        ? 'Draft'
                        : 'Published'
                    }
                    sx={{ borderRadius: 1 }}
                    color={
                      row.status === 'cancelled'
                        ? 'error'
                        : !row.isPublished
                        ? 'info'
                        : 'success'
                    }
                  />
                </TableCell>
                <TableCell>
                  {dayjs(row.startTime.toDate()).format('ddd DD MMM HH:mm')}
                </TableCell>
                <TableCell>{row.class.title}</TableCell>
                <TableCell>{row.user.firstName}</TableCell>
                <TableCell>{row.studio.name}</TableCell>
                <TableCell
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  <Stack gap={1}>
                    <Typography variant='body2'>
                      {row.class.enrolledMembers.length}/{row.class.capacity}
                    </Typography>
                    <LinearProgress
                      variant='determinate'
                      value={soldPercent}
                      color={
                        soldPercent >= 100
                          ? 'error'
                          : soldPercent >= 80
                          ? 'warning'
                          : 'primary'
                      }
                    />
                  </Stack>
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  {row.class.waitlistMembers.length}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ClassTable
