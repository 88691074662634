import { Box, Typography } from '@mui/material'
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun'

const NoClass = () => {
  return (
    <Box
      sx={{
        border: (t) => `1px solid ${t.palette.primary.main}`,
        maxWidth: 'md',
        borderRadius: 2,
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'primary.main',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: 2,
        }}
      >
        <DirectionsRunIcon />
      </Box>
      <Box sx={{ p: 2 }}>
        <Typography variant='h5' color='white.main'>
          Check back soon
        </Typography>
        <Typography mt={2}>
          We're just as eager as you are to get those sweat sessions going! Our
          timetable is currently flexing its muscles, gearing up to bring you
          the most exhilarating and energizing classes your gym has ever seen.
        </Typography>
        <Typography mt={2}>
          Keep an eye on our website, as more classes will be available to book
          very soon!
        </Typography>
      </Box>
    </Box>
  )
}

export default NoClass
