import {
  Box,
  Button,
  Container,
  Stack,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material'
import bg from '../../../assets/images/gym-stretch.jpg'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Link } from 'react-router-dom'
import Span from '../../../components/Span'
import { connect } from 'react-redux'

const StyledBackgroundImage = styled(Box)(() => ({
  background: `
  radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 55%, rgba(0,0,0,1) 100%),
  url(${bg}) no-repeat center center
  `,
  backgroundSize: 'cover',
  height: '90vh',
  '@media (min-width:900px)': {
    background: `
    linear-gradient(0deg, rgba(0,0,0,1) 18%, rgba(0,0,0,0.6559217436974789) 35%, rgba(0,0,0,0.3029586834733894) 66%, rgba(0,0,0,0) 74%),
  url(${bg}) no-repeat center center
  `,
  },
}))

const StyledHeading = styled(Typography)(() => ({
  fontSize: '3.5rem',
  color: 'white',
  fontWeight: 600,
  lineHeight: 1,
  '@media (min-width:900px)': {
    fontSize: '6rem',
  },
}))

const StyledFeature = styled(Typography)(() => ({
  display: 'flex',
  color: 'white',
  gap: 3,
  alignItems: 'center',
  '@media (min-width:900px)': {
    fontSize: '1.5rem',
  },
}))

const Header = ({ user, subscriptionData }) => {
  const mobile = useMediaQuery('(max-width:600px)')

  return (
    <StyledBackgroundImage>
      <Container maxWidth='xl'>
        <Box maxWidth={'850px'} pt={mobile ? 5 : 20}>
          <StyledHeading variant='h1'>
            Join the
            <Span> most flexible </Span>
            gym in town.
          </StyledHeading>
        </Box>
        <Box
          display='flex'
          justifyContent={'space-between'}
          gap={1}
          mt={1}
          maxWidth={'sm'}
        >
          <StyledFeature noWrap variant='h6'>
            <Span>No</Span> Contract{' '}
            <CheckCircleOutlineIcon fontSize={'inherit'} />
          </StyledFeature>
          <StyledFeature noWrap variant='h6'>
            <Span>24/7</Span> Access{' '}
            <CheckCircleOutlineIcon fontSize={'inherit'} />
          </StyledFeature>
          <StyledFeature noWrap variant='h6'>
            <Span>Free</Span> trial{' '}
            <CheckCircleOutlineIcon fontSize={'inherit'} />
          </StyledFeature>
        </Box>
        <Stack direction='row' gap={1}>
          <Link to={subscriptionData ? '/gyms/llanelli/studios' : '/join'}>
            <Button sx={{ mt: 2 }} variant='contained' size='large'>
              {subscriptionData ? 'Book a Studio' : 'Join now'}
            </Button>
          </Link>

          <Link to={subscriptionData ? '/classes' : '/gyms/llanelli'}>
            <Button sx={{ mt: 2 }} variant='outlined' size='large'>
              {subscriptionData ? 'Join a class' : 'Learn More'}
            </Button>
          </Link>
        </Stack>
      </Container>
    </StyledBackgroundImage>
  )
}

const mapStateToProps = ({ user, subscription }) => ({
  currentUser: user.currentUser,
  subscriptionData: subscription.currentSubscription,
})

export default connect(mapStateToProps, null)(Header)
