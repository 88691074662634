import React from 'react'
import './styles.scss'
import logo from '../../../assets/images/hyve-yellow.png'
import { Fade } from 'react-awesome-reveal'
import Request from './components/PasswordResetForm/Request.component'

function PasswordReset() {
  return (
    <div className='requestPage'>
      <Fade direction='down'>
        <div className='requestPage__container'>
          <img className='requestPage__logo' src={logo} alt='' />
          <h1 className='requestPage__title'>Password Reset </h1>
          <p className='requestPage__subTitle'>
            You signed up with HYVE by creating a username and password. Enter
            your email to request a password reset email.
          </p>
          <Request dark />
        </div>
      </Fade>
    </div>
  )
}

export default PasswordReset
