import { Stack } from '@mui/material'
import MembershipDetails from './MembershipDetails'
import Loader from '../../../components/Loader'
import ManageFamily from '../components/ManageFamily'

const Memberships = ({ subscriptionData, currentUser }) => {
  const isFamily = currentUser.memberType === 'family'
  if (!subscriptionData) {
    return <Loader />
  }
  return (
    <Stack gap={3}>
      {subscriptionData && (
        <MembershipDetails subscriptionData={subscriptionData} />
      )}
      {isFamily && <ManageFamily currentUser={currentUser} />}
    </Stack>
  )
}

export default Memberships
