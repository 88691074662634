import React from 'react';
import './styles.scss';

function HyveButton({ children, fill, type, onClick, disabled, style }) {
	return (
		<button
			type={type}
			onClick={onClick}
			className={fill ? `hyveButton fill` : `hyveButton`}
			disabled={disabled}
			style={style}
		>
			{children}
		</button>
	);
}

export default HyveButton;
