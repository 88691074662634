import React from 'react'
import './styles.scss'
import { Fade } from 'react-awesome-reveal'
import LoginForm from './components/loginForm/LoginForm'

function LoginPage() {
  return (
    <div className='loginPage'>
      <Fade direction='down'>
        <div className='loginPage__container'>
          <h1 className='loginPage__title'>Login</h1>
          <LoginForm dark />
        </div>
      </Fade>
    </div>
  )
}

export default LoginPage
