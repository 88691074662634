import { connect } from 'react-redux'
import { setStudioId, setStudio } from '../../../redux/system/system.actions'
import { setLoading } from '../../../redux/system/system.actions'
import Page from '../../../components/page/Page'
import { Alert, AlertTitle, Box, Typography } from '@mui/material'
import Span from '../../../components/Span'
import useStudios from '../useStudios'
import StudioCard from '../components/StudioCard'

const Studios = () => {
  const { studios, error, loading } = useStudios()

  return (
    <Page loading={loading} error={error}>
      <Box>
        <Typography variant='h2' color='white.main'>
          <Span>Private</Span>
        </Typography>
        <Typography variant='h2' color='white.main'>
          Studios.
        </Typography>
        <Box
          mt={2}
          display={'grid'}
          gridTemplateColumns='repeat(auto-fit, minmax(300px, 1fr)) '
          gap={2}
        >
          {studios.length === 0 ? (
            <Alert severity='error'>
              <AlertTitle>Error</AlertTitle>
              No studios found
            </Alert>
          ) : (
            studios.map((s) => <StudioCard studio={s} key={s.id} />)
          )}
        </Box>
      </Box>
    </Page>
  )
}
const mapStateToProps = ({ system, user }) => ({
  loading: system.loading,
  currentUser: user.currentUser,
  studioId: system.studioId,
})

const mapDispatchToProps = (dispatch) => ({
  setLoading: (system) => dispatch(setLoading(system)),
  setStudioId: (system) => dispatch(setStudioId(system)),
  setStudio: (system) => dispatch(setStudio(system)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Studios)
