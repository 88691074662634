import React, { useState } from 'react'
import { Button, Typography } from '@mui/material'
import useClasses from '../../../classes/useClasses'
import ClassTable from '../components/ClassTable'
import { toast } from 'react-toastify'
import AdminPage from '../../../../components/page/AdminPage'

const Classes = () => {
  const [viewPast, setViewPast] = useState(false)
  const { classes, loading, error } = useClasses(viewPast, true, true)

  const togglePast = () => {
    setViewPast((ps) => !ps)
  }

  if (error) {
    toast.error(error)
  }

  return (
    <AdminPage loading={loading}>
      <Typography variant='h4' color={'white.main'} textAlign={'center'}>
        Classes
      </Typography>
      <Button
        onClick={togglePast}
        variant={viewPast ? 'contained' : 'outlined'}
        sx={{ mb: 1 }}
      >
        {viewPast ? 'Hide past' : 'Show past'}
      </Button>
      <ClassTable classes={classes} />
    </AdminPage>
  )
}

export default Classes
