import styled from 'styled-components'

export const UserPageContainer = styled.div`
  min-height: 93vh;
  display: grid;
  grid-template-columns: 300px auto;
  color: white;
  text-align: left;
  background-color: var(--black);
  position: relative;

  @media only screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }
`

export const UserContainer = styled.div`
  display: flex;
  gap: 5px;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    padding-top: 10px;
  }
`

export const Section = styled.div`
  width: 100%;
  color: white;
`

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  margin-bottom: 10px;
  h4 {
    color: white;
  }
`

export const PersonalDetails = styled.div`
  margin-bottom: 20px;
`

export const AdminInfo = styled.div`
  margin-bottom: 20px;

  button {
    padding: 5px 10px;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  button {
    padding: 5px 10px;
    text-transform: uppercase;
    cursor: pointer;
    margin-left: 5px;
  }
`

export const Input = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.yellow};
  padding: 10px 5px;
  display: grid;
  grid-template-columns: 100px auto;
  margin-bottom: 5px;

  label {
    color: ${({ theme }) => theme.colors.yellow};
    margin-right: 10px;
  }
  input {
    background: none;
    color: white;
    font-size: 1rem;

    input[type='number'] {
      appearance: textfield;
      appearance: none;
      margin: 0;
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.grey};
    }
  }
`

export const MembershipDetails = styled.div``
