import { useEffect, useState } from 'react'
import dayjs from 'dayjs'

const useIsUserRestricted = (user) => {
  const [isRestricted, setIsRestricted] = useState(false)
  const [friendlyRestrictionEnds, setFriendlyRestrictionEnds] = useState('')

  useEffect(() => {
    if (!user) {
      return
    }
    if (user.restrictionEnds === null || user.restrictionEnds === undefined) {
      setIsRestricted(false)
    } else {
      const expiryTime = dayjs(user.restrictionEnds.toDate())
      const friendlyExpiry = expiryTime.format('DD MMM, YYYY [at] h:mm A')
      const now = dayjs()
      const isRestricted = now.isBefore(expiryTime)

      if (isRestricted) {
        setIsRestricted(true)
        setFriendlyRestrictionEnds(friendlyExpiry)
      } else {
        setIsRestricted(false)
        setFriendlyRestrictionEnds('')
      }
    }
  }, [user])

  return { isRestricted, friendlyRestrictionEnds }
}

export default useIsUserRestricted
