import firebase, { firestore } from '../../utils/firebase/firebase'

export const SHOW_LOW_AVAILABILITY_ALERT_COUNT = 3
/**
 * Check if a user is enrolled in a class
 * @param {*} id The user to check
 * @param {*} enrolledMembers Array of enrolled members in the class
 * @returns Boolean representing the user's enrolent status in the classs
 */
export const checkEnrollment = (id, enrolledMembers) => {
  let found = false
  enrolledMembers.forEach((member) => {
    if (member.id === id) {
      found = true
    }
  })
  return found
}

/**
 * Checks if the user is waitlisted for the class
 * @param {*} id The user to check
 * @param {*} waitlistMembers Array of waitlist members in the class
 * @returns Object representing the user's waitlist status and queue position in the classs
 */
export const checkWaitlist = (id, waitlistMembers) => {
  let isWaitlisted = false
  waitlistMembers.forEach((member) => {
    if (member.id === id) {
      isWaitlisted = true
    }
  })
  const position = waitlistMembers.findIndex((member) => member.id === id) + 1
  return { isWaitlisted, position }
}

/**
 * Checks if the user is enrolled or waitlisted for the class
 * @param {*} id The user to check
 * @param {*} enrolledMembers Array of enrolled members in the class
 * @param {*} waitlistMembers Array of waitlist members in the class
 * @returns Boolean representing the user's registation status
 */
export const checkRegistration = (id, enrolledMembers, waitlistMembers) => {
  const isEnrolled = checkEnrollment(id, enrolledMembers)
  const { isWaitlisted } = checkWaitlist(id, waitlistMembers)
  return isEnrolled || isWaitlisted
}

export const FAKE_DOB = '01-01-1900'

/**
 * Reduce the currentUser object to essential properties only
 * @param {*} currentUser Full current user object from state
 * @returns A minified version of the user object inclduing only information required to book classes
 */
export const friendlyCurrentUser = (currentUser) => {
  const { id, email, firstName, lastName, dob, mobile, primeId } = currentUser

  const friendlyCurrentUser = {
    id,
    email,
    firstName,
    lastName,
    dob: dob ?? FAKE_DOB,
    mobile,
    primeId: primeId ?? null,
  }

  return friendlyCurrentUser
}

const promoteUserFromClassWaitlist = async (gymClass) => {
  const classRef = firestore.collection('bookings').doc(gymClass.id)
  const memberToPromote = gymClass.class.waitlistMembers[0]

  gymClass.class.waitlistMembers.length > 0 &&
    (await classRef.set(
      {
        class: {
          enrolledMembers:
            firebase.firestore.FieldValue.arrayUnion(memberToPromote),
          waitlistMembers:
            firebase.firestore.FieldValue.arrayRemove(memberToPromote),
        },
      },
      { merge: true }
    ))
}

export const removeUserFromClass = async (isEnrolled, user, gymClass) => {
  const arrayToUpdate = isEnrolled
    ? { enrolledMembers: firebase.firestore.FieldValue.arrayRemove(user) }
    : { waitlistMembers: firebase.firestore.FieldValue.arrayRemove(user) }
  const classRef = firestore.collection('bookings').doc(gymClass.id)

  await classRef
    .set(
      {
        class: arrayToUpdate,
      },
      { merge: true }
    )
    .then(() => {
      isEnrolled &&
        setTimeout(() => {
          promoteUserFromClassWaitlist(gymClass)
        }, [1000])
    })
}
