import React from 'react'
import useTrend from '../useTrend'
import { LineChart } from '@mui/x-charts/LineChart'
import { BarChart } from '@mui/x-charts/BarChart'
import { Box, Typography } from '@mui/material'

const WebKpis = () => {
  const { visits, pageViews, averageSession, date, loading } = useTrend()
  const valueFormatter = (value) => `${value} minutes`

  return (
    <>
      <Box
        flexGrow={1}
        sx={{
          border: (t) => `1px solid ${t.palette.primary.main}`,
          padding: 3,
          borderRadius: '10px',
          width: 'fit-content',
        }}
      >
        <Typography>Website KPIs</Typography>
        {!loading && (
          <Box
            display={'grid'}
            gridTemplateColumns='repeat(auto-fit, minmax(300px, 1fr))'
          >
            <LineChart
              height={300}
              colors={['#3f51b5', '#f50057']}
              series={[
                { data: visits, area: true, label: 'Visitors', stack: 'total' },
                {
                  data: pageViews,
                  area: true,
                  label: 'Page views',
                  stack: 'total',
                },
              ]}
              xAxis={[{ scaleType: 'point', data: date }]}
            />
            <BarChart
              xAxis={[{ scaleType: 'band', data: date }]}
              yAxis={[
                {
                  label: 'minutes',
                },
              ]}
              series={[
                {
                  data: averageSession,
                  valueFormatter,
                  label: 'Avg. Visit time',
                },
              ]}
              height={300}
            />
          </Box>
        )}
      </Box>
    </>
  )
}

export default WebKpis
