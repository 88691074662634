import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import Span from '../../../components/Span'

const Classes = () => {
  return (
    <Box>
      <Typography
        variant='h2'
        fontSize={'45px'}
        fontWeight={400}
        gutterBottom
        color='white.main'
      >
        Amazing
        <Span> variety </Span>
        of classes.
      </Typography>
      <Typography mb={2} color='white.main'>
        Explore our variety of dynamic classes led by expert trainers. Boost
        strength, flexibility, and endurance in our supportive community. From
        calorie-torching HIIT sessions to rejuvenating yoga flows, there's
        something for everyone.
      </Typography>
      <Link to='/classes'>
        <Button variant='contained'>View Schedule</Button>
      </Link>
    </Box>
  )
}

export default Classes
