import { useState } from 'react'
import AdminPage from '../../../../components/page/AdminPage'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Button, Box, Typography, Stack, Skeleton, Chip } from '@mui/material'
import dayjs from 'dayjs'
import firebase, { newFirestore } from '../../../../utils/firebase/firebase'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import logo from '../../../../assets/images/hyve-yellow.png'
import * as htmlToImage from 'html-to-image'

const Podium = () => {
  const [from, setFrom] = useState(dayjs())
  const [to, setTo] = useState(dayjs())
  const [podium, setPodium] = useState([])
  const [loading, setLoading] = useState(false)

  const download = () => {
    htmlToImage
      .toJpeg(document.getElementById('podium'))
      .then(function (dataUrl) {
        var link = document.createElement('a')
        link.download = 'hyve-class-podium.jpeg'
        link.href = dataUrl
        link.click()
      })
    htmlToImage
      .toJpeg(document.getElementById('table'))
      .then(function (dataUrl) {
        var link = document.createElement('a')
        link.download = 'hyve-class-table.jpeg'
        link.href = dataUrl
        link.click()
      })
  }
  const getData = async () => {
    setLoading(true)
    const bookingsRef = collection(newFirestore, 'bookings')
    const q = query(
      bookingsRef,
      where('isClass', '==', true),
      where('status', '==', 'confirmed'),
      where(
        'startTime',
        '>',
        firebase.firestore.Timestamp.fromDate(from.toDate())
      ),
      where(
        'startTime',
        '<',
        firebase.firestore.Timestamp.fromDate(to.toDate())
      ),
      orderBy('startTime', 'asc')
    )
    const querySnapshot = await getDocs(q)
    const data = []
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      doc.data().class?.enrolledMembers?.forEach((member) => {
        data.push({
          id: member.id,
          ...member,
        })
      })
    })

    console.log(data)
    // Create a frequency map
    const frequencyMap = data.reduce((acc, item) => {
      if (acc[item.id]) {
        acc[item.id].count += 1
      } else {
        acc[item.id] = { ...item, count: 1 }
      }
      return acc
    }, {})
    setLoading(false)

    const frequencyArray = Object.values(frequencyMap)

    const top = frequencyArray.sort((a, b) => b.count - a.count).slice(0, 10)

    console.log(top)
    setPodium(top)
  }

  return (
    <AdminPage>
      <Typography
        variant='h5'
        textAlign={'center'}
        my={2}
        color='white!important'
      >
        Who attended the most classes?
      </Typography>
      <Box
        display={'flex'}
        alignItems={'center'}
        flexDirection={'column'}
        sx={{ width: 500, margin: 'auto' }}
      >
        <Box
          sx={{
            display: 'flex',

            justifyContent: 'start',
            alignItems: 'center',
            height: 55,
            gap: 1,
            my: 1,
          }}
        >
          <DatePicker
            label='From'
            format='DD/MM/YYYY'
            value={from}
            onChange={(newValue) => setFrom(newValue)}
          />
          <DatePicker
            label='To'
            value={to}
            format='DD/MM/YYYY'
            onChange={(newValue) => setTo(newValue)}
          />
          <Button
            variant='contained'
            onClick={getData}
            sx={{ display: 'block', height: '100%' }}
            size='large'
          >
            Go!
          </Button>
        </Box>
        {loading && (
          <Skeleton variant='rounded' width={610} height={500} mt={2} />
        )}
        {podium.length === 0 && !loading && (
          <Typography fontSize={15}>No classes, search a date range</Typography>
        )}
        {podium.length > 0 && !loading && (
          <>
            <Button
              variant='contained'
              onClick={() => download()}
              fullWidth
              sx={{ mb: 2 }}
            >
              Download
            </Button>
            <Box
              id='podium'
              sx={{
                border: (t) => `1px solid ${t.palette.primary.light}`,
                borderRadius: 2,
                padding: 0,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                overflow: 'hidden',
              }}
            >
              <Box
                component={'img'}
                mt={2}
                src={logo}
                alt='hyve logo'
                width='25%'
              />
              <Typography fontSize={15} textAlign={'center'}>
                Class Champions
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  alignItems: 'end',
                  mt: 5,
                }}
              >
                <Stack>
                  <Typography
                    textAlign={'center'}
                    fontSize={'18px'}
                    textTransform={'capitalize'}
                  >
                    {podium[1].firstName} {podium[1].lastName.charAt(0)}
                  </Typography>
                  <Box
                    sx={{
                      borderTopRightRadius: 5,
                      borderTopLeftRadius: 5,
                      background:
                        'linear-gradient(45deg,  rgba(160,160,160,1) 0%,rgba(232,232,232,1) 56%)',
                      height: 125,
                      width: 150,
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography fontSize={40} color='black'>
                      2
                    </Typography>
                    <Typography variant='body2' color={'black'}>
                      {podium[1].count} classes
                    </Typography>
                  </Box>
                </Stack>
                <Stack>
                  <Typography
                    textAlign={'center'}
                    fontSize={'20px'}
                    textTransform={'capitalize'}
                  >
                    {podium[0].firstName} {podium[0].lastName.charAt(0)}
                  </Typography>
                  <Box
                    sx={{
                      background: `radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
                    radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%)`,
                      borderTopRightRadius: 5,
                      borderTopLeftRadius: 5,
                      height: 200,
                      width: 150,
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography fontSize={90}>1</Typography>
                    <Typography variant='body2' color={'black'}>
                      {podium[0].count} classes
                    </Typography>
                  </Box>
                </Stack>
                <Stack>
                  <Typography textAlign={'center'} textTransform={'capitalize'}>
                    {podium[2].firstName} {podium[2].lastName.charAt(0)}
                  </Typography>
                  <Box
                    sx={{
                      borderTopRightRadius: 5,
                      borderTopLeftRadius: 5,
                      background:
                        'linear-gradient(45deg,  rgba(223,182,103,1) 0%,rgba(249,243,232,1) 56%,rgba(231,192,116,1) 96%);',
                      height: 100,
                      width: 150,
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography fontSize={30} color='black'>
                      3
                    </Typography>
                    <Typography variant='body2' color={'black'}>
                      {podium[2].count} classes
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              <Box
                display={'flex'}
                gap={2}
                alignItems={'center'}
                justifyContent={'center'}
                mt={2}
                backgroundColor={'primary.main'}
                width={'100%'}
                padding={1}
              >
                <Typography textAlign={'center'} color='black' variant='body2'>
                  {dayjs(from).format('dddd DD MMM YYYY')}
                </Typography>
                <Typography color={'black'} variant='body2'>
                  to
                </Typography>
                <Typography textAlign={'center'} color='black' variant='body2'>
                  {dayjs(to).format('dddd DD MMM YYYY')}
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </Box>
      {podium.length > 0 && !loading && (
        <Box
          sx={{
            width: 500,
            margin: 'auto',
            mt: 2,
            borderRadius: 2,
            overflow: 'hidden',
            border: (t) => `1px solid ${t.palette.primary.main}`,
          }}
        >
          <Box id='table'>
            <Typography
              variant='h6'
              sx={{ background: (t) => t.palette.primary.main }}
              textAlign={'center'}
              p={1}
            >
              Top 10
            </Typography>
            <Box
              p={2}
              display={'flex'}
              gap={1}
              flexDirection={'column'}
              textAlign={'center'}
              justifyContent={'center'}
            >
              {podium.map((p, i) => (
                <Box
                  sx={{
                    display: 'grid',
                    gap: 1,
                    gridTemplateColumns: '1fr 1fr 1fr',
                    alignItems: 'center',
                  }}
                >
                  <Chip
                    label={i + 1}
                    sx={{
                      width: '40px',
                      height: '40px',
                      borderRadius: 1,
                      fontSize: 10,
                    }}
                    color='primary'
                  />
                  <Typography>
                    {p.firstName} {p.lastName.charAt(0)}
                  </Typography>
                  <Typography sx={{ justifySelf: 'end' }}>
                    {p.count} classes
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </AdminPage>
  )
}

export default Podium
