import React from 'react'
import { setStudioId } from '../../redux/system/system.actions'
import { connect } from 'react-redux'
import { Box, Container, Divider, styled } from '@mui/material'
import Page from '../../components/page/Page'
import Header from './components/Header'
import Classes from './components/Classes'
import Studios from './components/Studios'
import Trainers from './components/Trainers'
import useMediaQuery from '@mui/material/useMediaQuery'

const StyledBox = styled(Box)(() => ({
  marginTop: -50,
  '@media (max-width:800px)': {
    marginTop: -250,
  },
}))

function Home({ setStudioId }) {
  const mobile = useMediaQuery('(max-width:800px)')

  return (
    <Page container={false}>
      <Header />
      <StyledBox py={5}>
        <Container
          maxWidth='xl'
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 1fr))',
            gap: 5,
            py: 5,
          }}
        >
          <Classes />
          {mobile && <Divider />}
          <Studios />
          {mobile && <Divider />}
          <Trainers />
        </Container>
      </StyledBox>
    </Page>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setStudioId: (system) => dispatch(setStudioId(system)),
})

export default connect(null, mapDispatchToProps)(Home)
