import { joinActionTypes } from './join.types';

const INITIAL_STATE = {
	joinStep: 2,
};

const joinReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case joinActionTypes.SET_CURRENT_JOIN_STEP:
			return { ...state, joinStep: action.payload };

		case joinActionTypes.SET_SELECTED_PRODUCT:
			return { ...state, selectedProduct: action.payload };

		default:
			return state;
	}
};

export default joinReducer;
