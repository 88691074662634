import {
  Alert,
  AlertTitle,
  Paper,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material'
import TableHead from './TableHead'
import TableRow from './TableRow'

const WaitlistTable = ({ waitlisted, gymClass }) => {
  return (
    <TableContainer component={Paper}>
      {waitlisted.length === 0 ? (
        <Alert severity='info'>
          <AlertTitle>Oops</AlertTitle>No members have waitlisted for this
          class.
        </Alert>
      ) : (
        <Table>
          <TableHead />
          <TableBody>
            {waitlisted.map((row) => (
              <TableRow row={row} key={row.id} gymClass={gymClass} />
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  )
}

export default WaitlistTable
