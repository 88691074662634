import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import Page from '../../components/page/Page'
import { connect } from 'react-redux'
import {
  setLoading,
  setStudioId,
  setStudio,
  setBookingRequest,
} from '../../redux/system/system.actions'
import useTimeslots from './useTimeslots'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import dayjs from 'dayjs'
import Timeslot from './components/Timeslot'
import { useEffect, useState } from 'react'
import firebase, {
  firestore,
  newFirestore,
} from '../../utils/firebase/firebase'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { NavLink, useHistory } from 'react-router-dom'
import { useQuery } from '../../hooks/useQuery'
import { dayOfWeek } from '../../utils/helpers'
import { toast } from 'react-toastify'

const Availability = ({ currentUser }) => {
  const params = useQuery()
  const history = useHistory()
  const maxDaysInAdvance = 6
  const today = dayjs()
  const [selectedTime, setSelectedTime] = useState(null)
  const [nextDayIndex, setNextDayIndex] = useState(0)
  const [studios, setStudios] = useState(null)
  const [studio, setStudio] = useState(null)
  const [bookingRequest, setBookingRequest] = useState({})
  const [selectedStudioId, setSelectedStudioId] = useState(
    params.get('studio') ?? 't4HqiPsnmZ79FSzUrKtt'
  )
  const [dateInView, setDateInView] = useState(today)
  const { loading, error, timeSlots } = useTimeslots(
    dateInView,
    studio && studio.openingHours[dayOfWeek(dateInView.toDate())].open.hour,
    studio && studio.openingHours[dayOfWeek(dateInView.toDate())].open.minute,
    studio && studio.openingHours[dayOfWeek(dateInView.toDate())].close.hour,
    studio && studio.openingHours[dayOfWeek(dateInView.toDate())].close.minute,
    studio && studio.block,
    selectedStudioId
  )

  const moveToNextDay = () => {
    setSelectedTime(null)
    setDateInView(dateInView.add(1, 'day'))
    setNextDayIndex((ps) => ps + 1)
  }

  const moveToPreviousDay = () => {
    setSelectedTime(null)
    setDateInView(dateInView.subtract(1, 'day'))
    setNextDayIndex((ps) => ps - 1)
  }

  const selectStudio = (event) => {
    setSelectedStudioId(event.target.value)
    params.set('studio', event.target.value)
  }

  const selectTimeslot = (t, i) => {
    const start = t.startTime.toDate()
    start.setSeconds(0)
    start.setMilliseconds(0)
    const end = t.endTime.toDate()
    end.setSeconds(0)
    end.setMilliseconds(0)
    setSelectedTime(i)
    setBookingRequest({
      bookingRequest: {
        createdAt: firebase.firestore.Timestamp.now(),
        startTime: firebase.firestore.Timestamp.fromDate(start),
        studio: {
          name: studio.name,
          capacity: studio.capacity,
          id: studio.id,
        },
        isClass: false,
        isRecurring: false,
        status: 'confirmed',
        user: {
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          mobile: currentUser.mobile,
          email: currentUser.email,
          id: currentUser.id,
        },
        location: {
          code: 'LLA1',
          id: 'M2tQnpS5QHIA8w2qJD5V',
          name: 'Llanelli',
        },
        bookingDetails: {
          price: studio.price,
          time: `${t.startTime.format('h:mma')} - ${t.endTime.format('h:mma')}`,
          credits: studio.price,
        },
        channel: 'user',
        endTime: firebase.firestore.Timestamp.fromDate(end),
      },
    })
  }

  // Create a session document
  const createBookingSession = () => {
    console.log('Creating Booking session...')
    firestore
      .collection('users')
      .doc(currentUser.id)
      .collection('booking_sessions')
      .doc()
      .set(bookingRequest)
      .then(() => {
        console.log('Document successfully written!')
        history.push('/availability/confirm')
      })
      .catch((error) => {
        console.error('Error writing document: ', error)
        toast.error(`Error creating booking session. ${error.message}`)
      })
  }

  const getStudios = async () => {
    const studios = []
    const q = query(
      collection(newFirestore, 'studios'),
      where('isActive', '==', true)
    )

    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
      studios.push({ id: doc.id, ...doc.data() })
    })
    setStudios(studios)
  }

  useEffect(() => {
    getStudios()
  }, [])

  useEffect(() => {
    const studio =
      studios && studios.find((studio) => studio.id === selectedStudioId)
    setStudio(studio)
  }, [selectedStudioId, studios])

  return (
    <Page error={error} loading={loading}>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Button
          variant='contained'
          disabled={nextDayIndex === 0}
          onClick={moveToPreviousDay}
        >
          <ArrowBackIosIcon />
        </Button>
        <Stack>
          <Typography
            textTransform={'uppercase'}
            mb={1}
            fontSize={'clamp(12px,3vw,30px)'}
            textAlign={'center'}
            sx={{ color: (t) => t.palette.common.white }}
          >
            {dateInView.format('dddd DD MMMM')}
          </Typography>
          {studios && (
            <FormControl>
              <Select
                value={selectedStudioId}
                size='small'
                onChange={selectStudio}
                sx={{ textTransform: 'uppercase' }}
              >
                {studios.map((studio) => (
                  <MenuItem
                    key={studio.id}
                    value={studio.id}
                    sx={{ textTransform: 'uppercase' }}
                  >
                    {studio.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Stack>
        <Button
          variant='contained'
          onClick={moveToNextDay}
          disabled={nextDayIndex === maxDaysInAdvance}
        >
          <ArrowForwardIosIcon />
        </Button>
      </Box>
      <Button
        disabled={selectedTime == null}
        component={NavLink}
        to={'/availability/confirm'}
        size='large'
        fullWidth
        variant='contained'
        color='secondary'
        sx={{
          my: 1,
          position: 'sticky',
          top: 60,
          zIndex: 100,
          opacity: 1,
          '&:disabled': {
            background: '#828282',
          },
        }}
        onClick={createBookingSession}
      >
        {selectedTime !== null ? 'Continue' : 'Select a time slot'}
      </Button>
      <Box
        display={'grid'}
        gap={1}
        flexWrap={'wrap'}
        gridTemplateColumns={'repeat(auto-fill,minmax(170px,1fr))'}
      >
        {studios && studio ? (
          timeSlots.map((t, i) => (
            <Timeslot
              key={i}
              studio={studio}
              t={t}
              index={i}
              selectedTime={selectedTime}
              onSelect={() => selectTimeslot(t, i)}
            />
          ))
        ) : (
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            minHeight={'70vh'}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Page>
  )
}

const mapStateToProps = ({ system, user }) => ({
  loading: system.loading,
  currentUser: user.currentUser,
  studioId: system.studioId,
  studio: system.studio,
  bookingRequest: system.bookingRequest,
})

const mapDispatchToProps = (dispatch) => ({
  setLoading: (system) => dispatch(setLoading(system)),
  setStudioId: (system) => dispatch(setStudioId(system)),
  setStudio: (system) => dispatch(setStudio(system)),
  setBookingRequest: (system) => dispatch(setBookingRequest(system)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Availability)
