import React from 'react'
import { Link } from 'react-router-dom'
import './styles.scss'

function Rules() {
  return (
    <div className='rules__wrapper'>
      <div className='rules__heading'>
        <h1>House Rules</h1>
      </div>
      <div className='rules__body'>
        <p>Please read the below carefully. </p>
        <p>
          By completing any transaction with HYVE or using a HYVE service in any
          format, you are agreeing to abide by the House Rules. We ask you to
          make sure you are comfortable that you can comply with the House Rules
          before continuing. This is not an exhaustive list and HYVE has the
          right to use its sole discretion to add or alter at any time to
          prevent a breach of our <Link to='/terms'>Terms & Conditions.</Link>
        </p>
        <h4>You will</h4>
        <ul>
          <li>
            You will wear suitable gym clothing and footwear whilst using any
            HYVE facility.
          </li>
          <li>You will sanitise your hands before using HYVE equipment.</li>
          <li>
            You will safely store any bags and personal belongings off the gym
            floor and inside the lobby or bathroom areas.
          </li>
          <li>
            You will leave the HYVE studio or gym in a clean and tidy condition.
            This includes but not limited to, putting away equipment neatly,
            removing any trip hazards, disposing of any litter, wiping down
            machines and equipment, clearing any spillages and sanitising your
            hands.
          </li>
          <li>
            You will take all your belongings with you when leaving HYVE.
            Neither HYVE, the Landlord and/or the Managing Agent is responsible
            for any property left behind.
          </li>
          <li>
            You will exit the HYVE studio on time and be courteous to any users
            entering after you.
          </li>
          <li>
            You will be responsible and charged for the damage to equipment
            outside of normal wear and tear or necessary cleaning of a HYVE
            studio due to your violation of this agreement or any agreement as
            outlined in the <Link to='/terms'>Terms & Conditions.</Link>
          </li>
          <li>
            You will be held liable and charged for any costs incurred to
            replace equipment taken from a HYVE facility.
          </li>
          <li>
            You will provide proof of identity if reasonably requested by HYVE,
            the Landlord and/or Managing Agent.
          </li>
          <li>
            You will comply with any additional rules, regulations and requests
            communicated through the HYVE Website, Mobile Application, Network
            or otherwise.
          </li>
        </ul>

        <h4>YOU WILL NOT</h4>
        <ul>
          <li>
            You will not allow access to more people than the advertised
            capacity of each HYVE studio, inclusive of yourself
          </li>
          <li>
            You will not share any PIN number or Access Device supplied by HYVE
            to access the facility
          </li>
          <li>
            You will not use the Website, Mobile Application or Network for
            sending or storing any unlawful material.
          </li>
          <li>You will not use HYVE for unlawful or illegal purposes.</li>
          <li>
            You will not use the HYVE Website, Mobile Application or Network for
            any inappropriate purposes, including but not limited to drugs,
            alcohol, gambling, sexual activity, extreme sports or any other
            purpose likely to reflect negatively on HYVE, the Landlord and/or
            the Managing Agent.
          </li>
          <li>
            You will not smoke inside any HYVE facility (this includes
            e-cigarettes).
          </li>
          <li>
            You will not bring any harmful weapons or sharp objectives into the
            HYVE facility including but not limited to firearms and knives.
          </li>
          <li>
            You will not allow any naked flame inside HYVE through lighters,
            candles or other.
          </li>
          <li>
            You will not make excessive noise or create noise that HYVE, the
            Landlord and/or Managing Agent deems to be a disturbance to
            surrounding retail, office or residential dwellings.
          </li>
          <li>
            You will not bring any animals into a HYVE gym or Studio, except for
            service animals which should always be harnessed and attached to a
            lead.
          </li>
          <li>
            You will not allow the access of anybody under 16 years old into a
            HYVE studio or gym facility.
          </li>
          <li>
            You will not install, remove or modify any HYVE fixtures, fittings,
            equipment, hardware or appliances.
          </li>
          <li>
            You will not copy or distribute the Website, Mobile Application,
            Network or any other content without written permission from HYVE
            for the use of the Intellectual Property.
          </li>
          <li>
            You will not use the WiFi or Bluetooth services of HYVE for unlawful
            purposes or anti-social behaviour.
          </li>
          <li>
            You will not tarnish or purposely sabotage the HYVE brand or
            intellectual property through the Website, Mobile Application or
            Network.
          </li>
        </ul>
        <h4>ACCESS ABUSE POLICY</h4>
        <p>
          Any PIN number / Access Device assigned to you can only be used by you
          and your PIN number / Access Device is issued solely for your use, as
          your Account is personal to you and only covers your use of a HYVE
          facility. You are responsible for keeping your PIN number / Access
          Device secure and confidential at all times. The PIN number / Access
          Device remains our property at all times (unless agreed otherwise and
          evidenced in writing).
        </p>

        <p>
          Use of any issued PIN numbers is monitored in the interests of the
          safety and security of all our users, use of PIN numbers and access is
          monitored and individuals using PIN numbers / Access Devices may be
          asked to provide proof of identification.
        </p>

        <p>
          Should we believe that your PIN number / Access Device has been used
          by another individual or individuals we may (in our discretion) decide
          to conduct an investigation. If we do so we will: (a) inform you, via
          email, that we believe your PIN number / Access Device has been used
          by another individual or individuals and ask you to provide us with
          reasonable assistance to investigate the matter; and (b) following our
          investigation we will contact you, via email, to inform you of our
          findings and our proposed course of action, which may include one or
          more of the steps set out in the below.
        </p>

        <p>
          If you unreasonably refuse to cooperate with our investigation, or
          following our investigation we have reasonable grounds to believe that
          your PIN number / Access Device was used, with or without your
          knowledge and/or consent, by another individual or individuals,
          depending on the particular circumstances of each case, we reserve the
          right to take one or both of the following steps, which are in
          addition to any other legal rights that we may have: (a) to apply a
          penalty charge to your card on file. The penalty charge will be
          calculated as being equal to the daily charge (that applied at the
          time of use) for each occasion on which your PIN number was used by
          that individual/those individuals; and/or (b) in the event of serious
          misuse of your PIN number, for example, your PIN number has been used
          on repeated occasions and/or by more than one individual, to notify
          you, via email, that we are cancelling your Account with immediate
          effect, and no refunds will be given.
        </p>

        <p>
          If we have reasonable grounds for believing that you knowingly
          provided your PIN number / Access Device to another individual or
          individuals, or allowed unauthorised entry following your entry to the
          HYVE facility (known as tailgating) in addition to our rights referred
          to above, we may hold you responsible for the conduct of the
          individual(s) while on our premises, and liable for any loss we suffer
          as a consequence of that conduct.
        </p>

        <p>
          (“Access Device”) is the device, key-fob, or any other relevant
          security hardware or software device with built-in authentication
          equipment, issued or otherwise provided to you by us to enable you to
          securely access the relevant HYVE facility in accordance with the
          Terms of your Account. Only one device can be registered to an Account
          at any time, if you lose or misplace the device you need to contact
          hi@trainhyve.com to remove the device immediately.
        </p>
      </div>
    </div>
  )
}

export default Rules
