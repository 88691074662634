import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import firebase, {
  firestore,
  newFirestore,
} from '../../../../utils/firebase/firebase'
import { collection, query, where, getDocs, or } from 'firebase/firestore'

import { toast } from 'react-toastify'
import { FAKE_DOB, checkRegistration } from '../../../classes/helpers'
import useClass from '../../../classes/useClass'

const AddMember = ({ isFull, classId }) => {
  const [userOpen, setUserOpen] = useState(false)
  const [users, setUsers] = useState([])
  const loadingUsers = userOpen && users.length === 0
  const { gymClass } = useClass(classId)

  const handleSignup = async (user) => {
    const enrolledMembers = gymClass.class.enrolledMembers
    const waitlistMembers = gymClass.class.waitlistMembers
    const isRegistered = checkRegistration(
      user.id,
      enrolledMembers,
      waitlistMembers
    )

    const enroll = {
      enrolledMembers: firebase.firestore.FieldValue.arrayUnion(user),
    }

    const waitlist = {
      waitlistMembers: firebase.firestore.FieldValue.arrayUnion(user),
    }

    const classRef = firestore.collection('bookings').doc(classId)

    isRegistered
      ? toast.error('Member already signed up for this class')
      : classRef
          .set(
            {
              class: isFull ? waitlist : enroll,
            },
            { merge: true }
          )
          .then(() => {
            isFull
              ? toast.warning('Class full. Member added to waitlist')
              : toast.success('Success! Member added to class')
          })
          .catch((error) => {
            toast.error(
              'Cannot add member to class. Try again: ',
              error.message
            )
          })
  }

  const getUsers = async () => {
    const userData = []
    const userRef = collection(newFirestore, 'users')
    const q = query(
      userRef,
      or(where('status', '==', 'active'), where('status', '==', 'trialing'))
    )

    try {
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        const data = doc.data()

        userData.push({
          ...data,
          id: doc.id,
          label: ` ${data.firstName} ${data.lastName} 
        ${data.email}`,
        })

        if (data.family) {
          const fam = Object.entries(data.family).map(([member, v]) => ({
            member,
            ...v,
          }))

          fam.forEach((f, i) => {
            userData.push({
              ...f,
              primeId: doc.id,
              label: ` ${f.firstName} ${f.lastName}    ${data.email}`,
              email: data.email,
              mobile: data.mobile,
            })
          })
        }
      })
      setUsers(userData)
      console.log(userData)
    } catch (error) {
      toast.error('unable to get members: ', error.message)
    }
  }

  return (
    <Formik
      initialValues={{}}
      onSubmit={(v, { setSubmitting }) => {
        setSubmitting(true)
        handleSignup(v)
        setSubmitting(false)
      }}
    >
      {(props) => {
        return (
          <Form>
            <Box
              display={'grid'}
              gridTemplateColumns={'3fr 1fr'}
              gap={2}
              mb={2}
            >
              <Autocomplete
                id='user'
                name='user'
                required
                onChange={(e, value) =>
                  value &&
                  props.setValues({
                    email: value.email,
                    firstName: value.firstName,
                    lastName: value.lastName,
                    mobile: value.mobile,
                    dob: value.dob ?? FAKE_DOB,
                    id: value.id,
                    primeId: value.primeId ?? value.id,
                  })
                }
                open={userOpen}
                onOpen={() => {
                  setUserOpen(true)
                  getUsers()
                }}
                onClose={() => {
                  setUserOpen(false)
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.label}
                options={users}
                loading={loadingUsers}
                disabled={props.isSubmitting}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Add member &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
                    disabled={props.isSubmitting}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingUsers ? (
                            <CircularProgress color='inherit' size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              <Button
                variant='contained'
                disabled={props.isSubmitting || !props.values.id}
                type='submit'
              >
                {isFull ? 'Add to waitlist' : 'Enroll'}
              </Button>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

export default AddMember
