import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { firestore } from '../../../../../../utils/firebase/firebase'
import {
  Membership,
  Header,
  Stripe,
  Status,
  MembershipDetails,
  Payments,
  Payment,
  PaymentsHeader,
  PaymentsButton,
} from './MembershipCard.styles'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'

function MembershipCard({ sub }) {
  const [payments, setPayments] = useState([])
  const params = useParams()

  const currencyFormat = new Intl.NumberFormat('gb-GB', {
    style: 'currency',
    currency: 'GBP',
  })

  const getPayments = async (subID) => {
    console.log('getting payments...')
    setPayments([])
    const payments = []

    firestore
      .collection(`users/${params.uid}/subscriptions/${subID}/invoices`)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          payments.push({ data: doc.data(), id: doc.id })
        })
        setPayments(payments)
      })
      .catch((error) => {
        toast.error('Error getting documents: ', error)
      })
  }

  return (
    <Membership>
      <Header>
        <Stripe href={sub.data.stripeLink} target='_blank'>
          S
        </Stripe>
        <Status
          background={
            sub.data.status === 'past_due'
              ? '#F00'
              : sub.data.status === 'canceled'
              ? '#fff'
              : ''
          }
        >
          {sub.data.status === 'past_due' ? 'OVERDUE' : sub.data.status}
        </Status>
      </Header>
      <MembershipDetails>
        <div>
          <h5>Cancellation Requested</h5>
          <p>{sub.data.cancel_at_period_end ? 'YES' : 'NO'}</p>
        </div>
        <div>
          <h5>Payment Interval</h5>
          <p>MONTHLY</p>
        </div>
        <div>
          <h5>Start Date</h5>
          <p>{dayjs.unix(sub.data.created.seconds).format('D MMM YY')}</p>
        </div>
        <div>
          <h5>Next Payment</h5>
          <p>
            {sub.data.status === 'canceled'
              ? ''
              : dayjs
                  .unix(sub.data.current_period_end.seconds)
                  .format('D MMM YY')}
          </p>
        </div>
      </MembershipDetails>

      {payments.length > 0 && (
        <PaymentsHeader>
          <h5>Due</h5>
          <h5>Status</h5>
          <h5>Subtotal</h5>
          <h5>Discount</h5>
          <h5>Total</h5>
        </PaymentsHeader>
      )}
      <Payments>
        {payments.map((payment, index) => (
          <Payment key={index}>
            <p>{dayjs.unix(payment.data.created).format('D MMM YY')}</p>
            <p>{payment.data.paid ? 'PAID' : 'UNPAID'}</p>
            <p>{currencyFormat.format(payment.data.subtotal / 100)}</p>
            <p>{payment.data.discount && payment.data.discount.coupon.name}</p>
            <p>{currencyFormat.format(payment.data.total / 100)}</p>
          </Payment>
        ))}
      </Payments>
      <PaymentsButton>
        {payments.length < 1 ? (
          <button onClick={() => getPayments(sub.id)}>View payments</button>
        ) : (
          <button onClick={() => setPayments([])}>Close</button>
        )}
      </PaymentsButton>
    </Membership>
  )
}

export default MembershipCard
