import { Box, Button, Stack, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import firebase, { firestore } from '../../../../utils/firebase/firebase'
import { useEffect, useState } from 'react'
import Loader from '../../../../components/Loader'

const GuestPass = () => {
  const params = useParams()
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)
  const passes = user && user.guestPass

  useEffect(() => {
    const unsubscribe = firestore
      .collection('users')
      .doc(params.uid)
      .onSnapshot(
        (doc) => {
          if (!doc.exists) {
            setLoading(false)
            toast.error('User not found')
          } else {
            setUser({ ...doc.data(), id: doc.id })
            setLoading(false)
          }
        },
        (error) => {
          toast.error('Error retrieving user: ', error.message)
          setLoading(false)
        }
      )

    return () => {
      unsubscribe()
    }
  }, [params.uid])

  const registerGuest = () => {
    var userRef = firestore.collection('users').doc(params.uid)
    const now = new Date().toLocaleString('en-GB')
    const currentCredits = user.credits

    if (currentCredits < 8) {
      return toast.error('Not enough credits')
    }

    try {
      if (user.guestPass) {
        userRef.update({
          guestPass: firebase.firestore.FieldValue.arrayUnion(now),
        })

        userRef.update({ credits: currentCredits - 8 })

        toast.success(
          `Credits reduced from ${currentCredits} to ${currentCredits - 8} `
        )
      } else {
        userRef.set({ guestPass: [now] }, { merge: true })
        userRef.update({ credits: currentCredits - 8 })
        toast.success(
          `Credits reduced from ${currentCredits} to ${currentCredits - 8} `
        )
      }
    } catch (error) {
      toast.error('unable to checking')
    }
  }

  if (loading) {
    return <Loader />
  }

  return (
    <Stack>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'start'}
      >
        <Box mb={2}>
          <Typography variant='h5' color='white.main'>
            Guest Pass
          </Typography>
          <Typography fontSize={'small'}>
            Check in this member's guest and credits are automatically deducted
            from their account.
          </Typography>
        </Box>
        <Button variant='outlined' onClick={() => registerGuest()}>
          Check in
        </Button>
      </Box>
      <Typography variant='h6' color='white.main'>
        History
      </Typography>
      <Typography fontSize={'small'} mb={2}>
        A history of all guest passes used by this member.
      </Typography>
      <Box>{passes && passes.map((p, i) => <Typography>{p}</Typography>)}</Box>
    </Stack>
  )
}

export default GuestPass
