import styled from 'styled-components';

export const UserBookingContainer = styled.div`
	display: grid;
	width: 100%;
	grid-template-columns: ${(props) =>
		props.bookingType === 'recurring'
			? '0.5fr 2.5fr 2fr 2.5fr 0.5fr'
			: '1.5fr 2fr 3fr 1fr'};
	border: 1px solid
		${(props) =>
			props.background === 'cancelled' ? '#f00' : props.theme.colors.yellow};

	padding: 15px 5px;
	font-size: 0.8rem;

	@media only screen and (max-width: 1000px) {
		grid-template-columns: 1fr;
	}
`;
