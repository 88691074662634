import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material'
import Modal from '../../../components/Modal'
import dayjs from 'dayjs'
import { connect } from 'react-redux'
import Span from '../../../components/Span'
import firebase, { firestore } from '../../../utils/firebase/firebase'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { checkEnrollment, checkWaitlist, friendlyCurrentUser } from '../helpers'
import { Link } from 'react-router-dom'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const FamilySignupModal = ({ isOpen, onClose, gymClass, currentUser }) => {
  if (!currentUser) {
    return null
  }

  const isFull =
    gymClass.class.capacity <= gymClass.class.enrolledMembers.length

  const { isWaitlisted, position } = checkWaitlist(
    currentUser.id,
    gymClass.class.waitlistMembers
  )
  const isEnrolled = checkEnrollment(
    currentUser.id,
    gymClass.class.enrolledMembers
  )

  const family = Object.entries(currentUser.family).map(([member, v]) => ({
    member,
    ...v,
  }))

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={'Confirm signup'}>
      <Box
        sx={{
          background: `url(${gymClass.class.coverImage}), linear-gradient(270deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 23%, rgba(0,0,0,0.6) 41%, rgba(0,0,0,1) 100%, rgba(0,0,0,0.6) 100%);`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundBlendMode: 'multiply',
          backgroundSize: 'cover',
        }}
        p={2}
        borderRadius={1}
        mb={1}
      >
        <Typography
          textTransform={'uppercase'}
          fontWeight={500}
          color='primary'
          variant='h6'
        >
          {gymClass.class.title}
        </Typography>
        <Typography textTransform={'uppercase'}>
          {dayjs(gymClass.startTime.toDate()).format('dddd D MMM HH:mm a')}
        </Typography>
      </Box>
      <Box>
        <FamilyAccordion
          gymClass={gymClass}
          member={currentUser}
          isEnrolled={isEnrolled}
          isWaitlisted={isWaitlisted}
          isFull={isFull}
          position={position}
        />
        {family.map((m, i) => {
          const { isWaitlisted, position } = checkWaitlist(
            m.id,
            gymClass.class.waitlistMembers
          )
          const isEnrolled = checkEnrollment(
            m.id,
            gymClass.class.enrolledMembers
          )
          return (
            <FamilyAccordion
              gymClass={gymClass}
              member={m}
              isEnrolled={isEnrolled}
              isWaitlisted={isWaitlisted}
              isFull={isFull}
              position={position}
              currentUser={currentUser}
              key={m.id}
            />
          )
        })}
        <Box sx={{ mt: 1 }}>
          <Link to='/account?classes'>
            <Button variant='contained' fullWidth>
              View my Classes
            </Button>
          </Link>
        </Box>
      </Box>
    </Modal>
  )
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
})

export default connect(mapStateToProps, null)(FamilySignupModal)

const FamilyAccordion = ({
  gymClass,
  member,
  isEnrolled,
  isWaitlisted,
  isFull,
  position,
  currentUser,
}) => {
  const [submitting, setSubmitting] = useState(false)
  const handleSignup = async (user) => {
    setSubmitting(true)

    const enroll = {
      enrolledMembers: firebase.firestore.FieldValue.arrayUnion(
        friendlyCurrentUser(user)
      ),
    }

    const waitlist = {
      waitlistMembers: firebase.firestore.FieldValue.arrayUnion(
        friendlyCurrentUser(user)
      ),
    }

    const classRef = firestore.collection('bookings').doc(gymClass.id)
    classRef
      .set(
        {
          class: isFull ? waitlist : enroll,
        },
        { merge: true }
      )
      .then(() => {
        setSubmitting(false)
        toast.success(
          `${user.firstName} signed up for ${gymClass.class.title} 🚀`
        )
      })
      .catch(() => {
        toast.error("Sorry, we couldn't confirm your booking. Please try again")
        setSubmitting(false)
      })
  }
  return (
    <Accordion sx={{ p: 0.5 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography textTransform={'capitalize'}>
            {member.firstName} {member.lastName}
          </Typography>
          <Typography>{`${isEnrolled ? '🚀' : ''}${
            isWaitlisted ? '⏳' : ''
          }`}</Typography>
        </Box>
      </AccordionSummary>
      <Divider sx={{ mb: 1 }} />
      {!isWaitlisted && !isEnrolled && (
        <Box px={1}>
          <Typography fontWeight={200} fontSize={14}>
            By confirming your class booking below, you agree to follow HYVE's
            cancellation policy. If you are unable to attend, please cancel your
            booking online prior to the start time. Account limitations will
            apply if you no-show on multiple occasions.
          </Typography>
          {isFull && (
            <Box mt={2}>
              <Typography
                textTransform={'uppercase'}
                sx={{ textDecoration: 'underline' }}
                gutterBottom
              >
                Please note
              </Typography>

              <Typography fontWeight={200} fontSize={14}>
                This class is now full and you are making a waitlist request
                only! When you confirm your booking below, you'll be put in{' '}
                <Span>
                  position {gymClass.class.waitlistMembers.length + 1}
                </Span>{' '}
                on the waitlist. You can always check your current waitlist
                position in your account. Should there be cancellations and you
                make it to the front of the line, we'll send you an email to let
                you know you've got a spot in the class.
              </Typography>
            </Box>
          )}
        </Box>
      )}
      <Button
        variant='contained'
        sx={{ mt: 2, mb: 1 }}
        onClick={() =>
          !currentUser
            ? handleSignup(member)
            : handleSignup({
                ...member,
                mobile: currentUser.mobile,
                email: currentUser.email,
                primeId: currentUser.id,
              })
        }
        fullWidth
        disabled={submitting || isEnrolled || isWaitlisted}
        color={isFull ? 'warning' : 'primary'}
      >
        {isWaitlisted ? (
          `⏳ ${member.firstName} waitlisted in position ${position}`
        ) : isEnrolled ? (
          `🚀 ${member.firstName} is registered`
        ) : submitting ? (
          <CircularProgress size={25} />
        ) : isFull ? (
          `Add ${member.firstName} to waitlist`
        ) : (
          `Confirm booking for ${member.firstName}`
        )}
      </Button>
    </Accordion>
  )
}
