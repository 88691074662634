import { joinActionTypes } from './join.types';

export const setCurrentJoinStep = (join) => ({
	type: joinActionTypes.SET_CURRENT_JOIN_STEP,
	payload: join,
});

export const setSelectedProduct = (join) => ({
	type: joinActionTypes.SET_SELECTED_PRODUCT,
	payload: join,
});
