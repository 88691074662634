import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material'
import React from 'react'
import useUserBookings from '../useUserBookings'
import BookingCard from '../components/BookingCard'
import { Link } from 'react-router-dom'
import Loader from '../../../components/Loader'
const Studios = ({ currentUser }) => {
  const { bookings, loading, error } = useUserBookings(currentUser.id)

  if (loading) {
    return <Loader size={5} />
  }

  return (
    <Box>
      <Typography mb={2} variant='h5' color='white.main'>
        UPCOMING STUDIO BOOKINGS
      </Typography>
      {error ? (
        <Alert severity='error'>
          <AlertTitle>Oops</AlertTitle>
          Error fetching bookings. {error}
        </Alert>
      ) : (
        <>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(18rem, 1fr))',
              gap: 2,
            }}
          >
            {bookings.map((booking, i) => (
              <BookingCard booking={booking} key={booking.id + i} />
            ))}
          </Box>
          {bookings.length === 0 && (
            <Alert severity='info'>
              <AlertTitle>Oops</AlertTitle>
              <Typography>
                It looks like you don't have any studio bookings coming up
              </Typography>

              <Button
                variant='contained'
                sx={{ mt: 1 }}
                component={Link}
                to='/gyms/llanelli/studios'
              >
                Book a private studio
              </Button>
            </Alert>
          )}
        </>
      )}
    </Box>
  )
}

export default Studios
