import { useEffect, useState } from 'react'
import firebase, { firestore } from '../../utils/firebase/firebase'

const usePtClasses = (userId) => {
  const [classes, setClasses] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  useEffect(() => {
    const classData = []
    const now = firebase.firestore.Timestamp.fromDate(new Date())

    firestore
      .collection('bookings')
      .where('isClass', '==', true)
      .where('startTime', '>', now)
      .where('user.id', '==', userId)
      .orderBy('startTime', 'asc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data()

          classData.push({
            ...data,
            id: doc.id,
          })
        })
        setClasses(classData)
        setLoading(false)
      })
      .catch((error) => {
        setError(error.message)
        setLoading(false)
      })
  }, [userId])

  return { classes, loading, error }
}

export default usePtClasses
