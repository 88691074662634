import React from 'react';
import './styles.scss';

function Loader({ inline }) {
	return (
		<div
			className={!inline ? 'loader__container' : 'loader__container__inline'}
		>
			<div className={!inline ? 'loader' : 'loader__inline'}></div>
		</div>
	);
}

export default Loader;
