import { Box, Button, Typography } from '@mui/material'
import Modal from '../../../../components/Modal'
import { toast } from 'react-toastify'
import { removeUserFromClass } from '../../../classes/helpers'

const CanxModal = ({ isOpen, onClose, user, gymClass, source }) => {
  const isEnrolled = source === 'enrolled'
  const handleCancellation = () => {
    try {
      removeUserFromClass(isEnrolled, user, gymClass)
      toast.success('Cancellation confirmed')
    } catch (error) {
      toast.error('Cannot cancel enrolment. Try again: ', error.message)
    }
  }

  return (
    <Modal title='Are you sure?' isOpen={isOpen} onClose={onClose}>
      <Typography>
        Are you want to cancel {user.firstName} {user.lastName}'s registration
        for this class?
      </Typography>
      <Box display='flex' gap={1} mt={2}>
        <Button color='error' variant='contained' onClick={handleCancellation}>
          Confirm cancellation
        </Button>
        <Button variant='contained' color='primary' onClick={onClose}>
          Exit
        </Button>
      </Box>
    </Modal>
  )
}

export default CanxModal
