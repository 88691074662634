import { toast } from 'react-toastify'
import firebase, { firestore } from '../../../../utils/firebase/firebase'

const USERS_COLLECTION = 'users'
const SUBSCRIPTIONS_COLLECTION = 'subscriptions'
export const RESTRICTION_TIMEFRAME = 7 // restriction in days
const restrictionMilliseconds = 1000 * 60 * 60 * 24 * RESTRICTION_TIMEFRAME

const fetchLatestSubscription = async (userId) => {
  const latestSubSnapshot = await firestore
    .collection(USERS_COLLECTION)
    .doc(userId)
    .collection(SUBSCRIPTIONS_COLLECTION)
    .orderBy('current_period_end', 'desc')
    .limit(1)
    .get()

  if (!latestSubSnapshot.empty) {
    return latestSubSnapshot.docs[0].data()
  }

  return null
}

export const refreshMembershipStatus = async (userId, currentStatus) => {
  const userRef = firestore.collection(USERS_COLLECTION).doc(userId)
  const latestSubscription = await fetchLatestSubscription(userId)

  if (!latestSubscription || !latestSubscription.status) {
    return toast.error(
      'Error updating membership status. Cannot find subscription.'
    )
  }

  if (currentStatus === latestSubscription.status) {
    return toast.info('Membership status is already up to date')
  }

  try {
    await userRef.update({ status: latestSubscription.status })
    toast.success('Membership status updated')
  } catch (err) {
    toast.error('Error updating membership status')
    console.error(
      `Error updating membership status for user: ${userId}, Error:`,
      err
    )
  }
}

export const addRestriction = async (user) => {
  const userRef = firestore.collection(USERS_COLLECTION).doc(user)

  try {
    await userRef
      .update({
        restrictionEnds: firebase.firestore.Timestamp.fromMillis(
          Date.now() + restrictionMilliseconds
        ),
      })
      .then(() => {
        toast.success(
          `Class restriction added for ${RESTRICTION_TIMEFRAME} days`
        )
      })
  } catch (error) {
    toast.error('Error updating user Restriction')
  }
}

export const removeRestriction = async (userId) => {
  const userRef = firestore.collection(USERS_COLLECTION).doc(userId)

  try {
    await userRef
      .update({
        restrictionEnds: null,
      })
      .then(() => {
        toast.success('User Un-Restricted')
      })
  } catch (error) {
    toast.error('Error updating user Restriction')
  }
}
