import { useEffect, useState } from 'react'
import { newFirestore } from '../../utils/firebase/firebase'
import { collection, query, where, onSnapshot } from 'firebase/firestore'

const useStudios = (isActive = true) => {
  const [studios, setStudios] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  useEffect(() => {
    const activeq = query(
      collection(newFirestore, 'studios'),
      where('isActive', '==', true)
    )

    const q = query(collection(newFirestore, 'studios'))
    const unsubscribe = onSnapshot(
      isActive ? activeq : q,
      (querySnapshot) => {
        const studios = []
        querySnapshot.forEach((doc) => {
          studios.push({ ...doc.data(), id: doc.id })
        })
        setStudios(studios)
        setLoading(false)
      },
      (error) => {
        setError(error.message)
      }
    )

    return () => {
      unsubscribe()
    }
  }, [isActive])

  return { studios, loading, error }
}

export default useStudios
