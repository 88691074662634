import React from 'react'
import './styles.scss'
import RegisterForm from './components/RegisterForm'
import { Fade } from 'react-awesome-reveal'
function Register() {
  return (
    <div className='registerPage'>
      <Fade direction='down'>
        <div className='registerPage__container'>
          <RegisterForm dark />
        </div>
      </Fade>
    </div>
  )
}

export default Register
