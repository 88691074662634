import styled from 'styled-components'

export const BookingCard = styled.div``

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  margin-top: 16px;

  button {
    background: ${({ theme }) => theme.colors.yellow};
    padding: 10px;
    text-transform: uppercase;
    color: black;

    &:hover {
      opacity: 0.9;
      cursor: pointer;
    }
  }
`

export const Bookings = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const UserBookingContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.bookingType === 'past' ? 'auto' : 'auto 100px'};
  gap: 5px;
`

export const Button = styled.button`
  background: ${({ theme }) => theme.colors.yellow};
  border: 1px solid transparent;
  text-transform: uppercase;
  cursor: pointer;
  color: black;

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    background: none;
    border: 1px solid #f00;
    color: #f00;
    cursor: default;
  }
`

export const CloseButton = styled.button`
  width: 100%;
  background: ${({ theme }) => theme.colors.yellow};
  text-transform: uppercase;
  padding: 10px;
  margin-top: 5px;
  cursor: pointer;
  color: black;
  &:hover {
    opacity: 0.9;
  }
`

export const MoreButton = styled.button`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.yellow};
  background: none;
  color: ${({ theme }) => theme.colors.yellow};
  text-transform: uppercase;
  padding: 10px;
  margin-top: 5px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.yellow};
    color: black;
  }
`
