import './styles.scss'
import Faq from 'react-faq-component'

const styles = {
  bgColor: 'none',
  titleTextColor: 'white',
  rowTitleColor: 'white',
  rowContentColor: '#d3b908',
  arrowColor: '#d3b908',
  rowContentPaddingTop: '10px',
  rowContentPaddingBottom: '10px',
  transitionDuration: '0.6s',
}

const config = {
  // animate: true,
  // arrowIcon: "V",
  // tabFocus: true
}

const data = {
  title: 'FAQ',
  rows: [
    {
      title: 'Do I need to be a member to book a private studio?',
      content: (
        <p>
          No, there is no membership or monthly fees associated with our private
          studio. Anyone can book and Pay-As-You-Go online at trainhyve.com
        </p>
      ),
    },
    {
      title: "I'm a PT, can I train clients in your members gym?",
      content: (
        <p>
          Absolutely! The only condition is that you and your clients are all
          subscribed as members.
        </p>
      ),
    },
    {
      title: 'Who can I bring with me to a private studio booking',
      content: (
        <div>
          <p>
            Your mum, your friends, your clients, your dog, your colleagues,
            your teammates... Anyone you like!
          </p>
          <br />
          <p>Ok, maybe not the dog.</p>
          <br />
          <p>
            Book online at trainhyve.com and the studio is for your exclusive
            use with whoever you like. People joining in your party don’t need
            to do anything other than turn up and enjoy. All we ask is that you
            stick to the capacity limits published for each room (this is
            monitored).
          </p>
        </div>
      ),
    },
    {
      title: "I'm interested in the memebrs gym but can I try it first?",
      content: (
        <div>
          <p>Yes!</p>
          <br />
          <p>
            Every new subscription to our members gym comes with a 24 hour free
            trial. You're free to use the facility in this period and if for any
            reason you don't want to continue with your membership you can
            cancel by visiting your account at trainhyve.com before the trial
            ends and you won't be charged.
          </p>
        </div>
      ),
    },
    {
      title: 'How long is the contract on your gym mebership?',
      content: (
        <div>
          <p>Contracts? Blergh!</p>
          <br />
          <p>
            We understand personal circumstances change and are committed to
            making it easy for you to amend your HYVE membership if required.
            Should you wish to cancel, you can do so with a couple of clicks by
            visiting your account at trainhyve.com
          </p>
          <br />
          <p>No hard feelings 😎</p>
        </div>
      ),
    },
    {
      title: 'How long can I book a private studio for?',
      content: (
        <p>
          Studios are available for 1 hour booking slots. Book and Pay-As-You-Go
          online at trainhyve.com
        </p>
      ),
    },
    {
      title:
        "Can I book a private studio if I'm paying the monthly fee for the members gym?",
      content: (
        <p>
          Yes and it's included in your monthly fee! Each month we reward you
          with HYVE credits to book private studio or Pay-As-You-Go online at
          trainhyve.com if you want some extra time.
        </p>
      ),
    },
  ],
}

function FaqPage() {
  return (
    <div className='faq__wrapper'>
      <div className='faq__body'>
        <Faq data={data} styles={styles} config={config} />
      </div>
    </div>
  )
}

export default FaqPage
