import {
  Alert,
  AlertTitle,
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import EditIcon from '@mui/icons-material/Edit'
import { updateFamily } from '../../../../account/helpers'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const ManageFamily = ({ family }) => {
  const [formData, setFormData] = useState(family)
  const [edit, setEdit] = useState(false)
  const { uid } = useParams()

  const onChangeAdult2 = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      adult2: {
        ...prevState.adult2,
        [e.target.id]: e.target.value,
      },
    }))
  }

  const onChangeChild1 = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      child1: {
        ...prevState.child1,
        [e.target.id]: e.target.value,
      },
    }))
  }

  const onChangeChild2 = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      child2: {
        ...prevState.child2,
        [e.target.id]: e.target.value,
      },
    }))
  }

  const addAdult2 = () => {
    setFormData({
      ...formData,
      adult2: {
        firstName: '',
        lastName: '',
        id: uuidv4(),
        dob: dayjs().format('YYYY-MM-DD'),
      },
    })
  }

  const addChild1 = () => {
    setFormData({
      ...formData,
      child1: {
        firstName: '',
        lastName: '',
        id: uuidv4(),
        dob: dayjs().format('YYYY-MM-DD'),
      },
    })
  }

  const addChild2 = () => {
    setFormData({
      ...formData,
      child2: {
        firstName: '',
        lastName: '',
        id: uuidv4(),
        dob: dayjs().format('YYYY-MM-DD'),
      },
    })
  }

  const handleSubmit = async () => {
    try {
      await updateFamily(uid, formData)
      setEdit(false)
      toast.success('Details updated successfully')
    } catch (error) {
      toast.error('Failed to update. Try again.')
    }
  }

  return (
    <Stack gap={1}>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography variant='h5'>Family members</Typography>
        <IconButton
          sx={{ mb: 1 }}
          disabled={edit}
          onClick={() => setEdit(true)}
        >
          <EditIcon />
        </IconButton>
      </Box>
      {!family && !edit && (
        <Alert severity='info'>
          <AlertTitle>Oops</AlertTitle>There's no family members assoiciated
          with this account. Add family now to allow supplementary members to
          book onto HYVE classes.
          <Box>
            <Button
              onClick={() => setEdit(true)}
              sx={{ mt: 2 }}
              variant='contained'
            >
              Add family
            </Button>
          </Box>
        </Alert>
      )}
      {formData && formData.adult2 ? (
        <Box display={'grid'} gap={1} gridTemplateColumns={'1fr 2fr 2fr 2fr'}>
          <TextField disabled variant='outlined' defaultValue={'Adult 2'} />
          <TextField
            id={`firstName`}
            name={`firstName`}
            label='First name  &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;'
            variant='outlined'
            disabled={!edit}
            value={formData.adult2.firstName}
            onChange={onChangeAdult2}
          />
          <TextField
            id={`lastName`}
            name={`lastName`}
            label='Last name  &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;'
            variant='outlined'
            disabled={!edit}
            value={formData.adult2.lastName}
            onChange={onChangeAdult2}
          />
          <DatePicker
            format='DD-MM-YYYY'
            id='dob'
            name='dob'
            label='Date of birth &nbsp; &nbsp; &nbsp;'
            size='small'
            disabled={!edit}
            value={dayjs(formData.adult2.dob)}
            onChange={(newValue) =>
              setFormData({
                ...formData,
                adult2: {
                  ...formData.adult2,
                  dob: dayjs(newValue).format('YYYY-MM-DD'),
                },
              })
            }
          />
        </Box>
      ) : (
        edit && (
          <Button variant='outlined' onClick={addAdult2} fullWidth>
            {' '}
            Add Adult 2
          </Button>
        )
      )}
      {formData && formData.child1 ? (
        <Box display={'grid'} gap={1} gridTemplateColumns={'1fr 2fr 2fr 2fr'}>
          <TextField disabled variant='outlined' defaultValue={'Child 1'} />
          <TextField
            id={`firstName`}
            name={`firstName`}
            label='First name  &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;'
            variant='outlined'
            disabled={!edit}
            value={formData.child1.firstName}
            onChange={onChangeChild1}
          />
          <TextField
            id={`lastName`}
            name={`lastName`}
            label='Last name  &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;'
            variant='outlined'
            disabled={!edit}
            value={formData.child1.lastName}
            onChange={onChangeChild1}
          />
          <DatePicker
            format='DD-MM-YYYY'
            id='dob'
            name='dob'
            label='Date of birth &nbsp; &nbsp; &nbsp;'
            size='small'
            disabled={!edit}
            value={dayjs(formData.child1.dob)}
            onChange={(newValue) =>
              setFormData({
                ...formData,
                child1: {
                  ...formData.chiild1,
                  dob: dayjs(newValue).format('YYYY-MM-DD'),
                },
              })
            }
          />
        </Box>
      ) : (
        edit && (
          <Button variant='outlined' onClick={addChild1} fullWidth>
            {' '}
            Add Child 1
          </Button>
        )
      )}
      {formData && formData.child2 ? (
        <Box display={'grid'} gap={1} gridTemplateColumns={'1fr 2fr 2fr 2fr'}>
          <TextField disabled variant='outlined' defaultValue={'Child 2'} />
          <TextField
            id={`firstName`}
            name={`firstName`}
            label='First name  &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;'
            variant='outlined'
            disabled={!edit}
            value={formData.child2.firstName}
            onChange={onChangeChild2}
          />
          <TextField
            id={`lastName`}
            name={`lastName`}
            label='Last name  &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;'
            variant='outlined'
            disabled={!edit}
            value={formData.child2.lastName}
            onChange={onChangeChild2}
          />
          <DatePicker
            format='DD-MM-YYYY'
            id='dob'
            name='dob'
            label='Date of birth &nbsp; &nbsp; &nbsp;'
            size='small'
            disabled={!edit}
            value={dayjs(formData.child1.dob)}
            onChange={(newValue) =>
              setFormData({
                ...formData,
                child2: {
                  ...formData.chiild2,
                  dob: dayjs(newValue).format('YYYY-MM-DD'),
                },
              })
            }
          />
        </Box>
      ) : (
        edit && (
          <Button variant='outlined' onClick={addChild2} fullWidth>
            {' '}
            Add Child 2
          </Button>
        )
      )}
      {edit && (
        <Box display={'flex'} gap={1} mt={1}>
          <Button variant='contained' onClick={handleSubmit}>
            Submit chages
          </Button>
          <Button
            variant='outlined'
            onClick={() => {
              setEdit(false)
              setFormData(family)
            }}
          >
            Cancel chages
          </Button>
        </Box>
      )}
    </Stack>
  )
}

export default ManageFamily
