import { styled } from '@mui/system'

const StyledSpan = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: 'inherit',
}))

const Span = (props) => {
  return <StyledSpan {...props}>{props.children}</StyledSpan>
}

export default Span
