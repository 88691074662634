import { Stack } from '@mui/material'
import React from 'react'
import Brand from './Brand'
import PtClasses from './PtClasses'

const PersonalTraining = ({ currentUser }) => {
  return (
    <Stack gap={3}>
      <Brand currentUser={currentUser} />
      <PtClasses currentUser={currentUser} />
    </Stack>
  )
}

export default PersonalTraining
