import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const StudioCard = ({ studio }) => {
  return (
    <Link to={`/gyms/llanelli/studios/${studio.id}`}>
      <Box
        sx={{
          background: `url(${studio.coverImage}), radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3029586834733894) 23%, rgba(0,0,0,0.4559217436974789) 51%, rgba(0,0,0,1) 100%, rgba(0,0,0,0.6559217436974789) 100%)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundBlendMode: 'multiply',
          height: 280,
          backgroundSize: 'cover',
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          padding: 2,
          opacity: 0.9,
          '&:hover': {
            background: `url(${studio.coverImage}), radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2929586834733894) 23%, rgba(0,0,0,0.4559217436974789) 41%, rgba(0,0,0,.8) 100%, rgba(0,0,0,0.6559217436974789) 100%)`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundBlendMode: 'multiply',
            backgroundSize: 'cover',
            opacity: 1,
          },
        }}
      >
        <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
          <Typography
            textTransform={'uppercase'}
            color='common.white'
            variant='h6'
          >
            {studio.name}
          </Typography>
          <Typography variant='body2' color={'primary'}>
            £{studio.price} / {studio.price} credits for {studio.block} minutes
          </Typography>
        </Box>
      </Box>
    </Link>
  )
}

export default StudioCard
