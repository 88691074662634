import {
  Box,
  Button,
  Card,
  Divider,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import Page from '../../../components/page/Page'
import Span from '../../../components/Span'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import gym1 from '../../../assets/images/llanelli/hyve1.webp'
import gym2 from '../../../assets/images/llanelli/hyve3.webp'
import gym3 from '../../../assets/images/llanelli/hyve4.webp'
import gym4 from '../../../assets/images/llanelli/hyve6.webp'
import gym5 from '../../../assets/images/llanelli/hyve7.webp'
import gym6 from '../../../assets/images/llanelli/hyve9.webp'
import gym7 from '../../../assets/images/llanelli/hyve10.webp'
import gym8 from '../../../assets/images/llanelli/hyve12.webp'
import gym9 from '../../../assets/images/llanelli/2.webp'
import NearMeIcon from '@mui/icons-material/NearMe'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

const LLanelli = ({ subscriptionData }) => {
  const mobile = useMediaQuery('(max-width:900px)')
  const images = [gym1, gym2, gym3, gym4, gym5, gym6, gym7, gym8, gym9]
  const [pause, setPause] = useState(false)
  const timer = useRef()
  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    duration: 1000,
    dragStart: () => {
      setPause(true)
    },
    dragEnd: () => {
      setPause(false)
    },
  })

  useEffect(() => {
    sliderRef.current.addEventListener('mouseover', () => {
      setPause(true)
    })
    sliderRef.current.addEventListener('mouseout', () => {
      setPause(false)
    })
  }, [sliderRef])

  useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next()
      }
    }, 2000)
    return () => {
      clearInterval(timer.current)
    }
  }, [pause, slider])
  return (
    <Page>
      <Typography variant='h2' color='white.main'>
        Home <Span>sweat</Span>
      </Typography>
      <Typography variant='h2' color='white.main' gutterBottom>
        home.
      </Typography>
      <Box
        display={'grid'}
        gridTemplateColumns={mobile ? '1fr' : '1fr 2fr'}
        gap={1}
      >
        <Card
          variant='outlined'
          sx={{
            border: (theme) => `1px solid ${theme.palette.primary.main}`,
            p: 2,
            borderRadius: 1,
            order: mobile ? 1 : 0,
          }}
        >
          <Typography variant='h3' color='white.main' mb={2}>
            HYVE LLanelli
          </Typography>
          <Divider sx={{ mb: 4 }} />

          <Box display={'flex'} flexDirection='column' gap={5}>
            <Box display={'flex'} justifyContent={'space-between'} gap={2}>
              <Typography color={'primary'}>Gym hours</Typography>
              <Typography variant='body2'>24/7</Typography>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'} gap={2}>
              <Typography color={'primary'} width={'130px'}>
                Staff & studio hours
              </Typography>
              <Box display={'stack'} textAlign={'right'}>
                <Typography variant='body2'>Mon-Thu: 6am - 10pm</Typography>
                <Typography variant='body2'>Fri: 6am - 9pm</Typography>
                <Typography variant='body2'>Sat: 7am - 6pm</Typography>
                <Typography variant='body2'>Sun: 8am - 6pm</Typography>
              </Box>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'} gap={2}>
              <Typography color={'primary'}>Address</Typography>
              <Box display={'stack'} textAlign={'right'}>
                <Typography variant='body2'>
                  Sandy Bridge Business Park
                </Typography>
                <Typography variant='body2'>Traeth Ffordd</Typography>
                <Typography variant='body2'>Llanelli</Typography>
                <Typography mb={2} variant='body2'>
                  SA15 4AX
                </Typography>
                <a
                  rel='noreferrer'
                  target='_blank'
                  href='https://www.google.com/maps/place/HYVE+Llanelli+Gym/@51.6833114,-4.1731281,15z/data=!4m2!3m1!1s0x0:0x5c015350a5464eec?sa=X&ved=2ahUKEwjt3_DQn4L-AhVyoFwKHa_sDNoQ_BJ6BAhrEAg'
                >
                  <Button variant='outlined'>view on map</Button>
                </a>
              </Box>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'} gap={2}>
              <Typography color={'primary'}>Contact</Typography>
              <a href='mailto:hi@trainhyve.com'>
                <Button>
                  <Typography variant='body2'>hi@trainhyve.com</Typography>
                </Button>
              </a>
            </Box>
          </Box>
        </Card>
        <Box ref={sliderRef} className='keen-slider'>
          {images.map((img, index) => (
            <Box
              className={`keen-slider__slide number-slide${index}`}
              key={img + index}
            >
              <img
                alt='hyve'
                src={img}
                width='100%'
                height='100%'
                style={{ objectFit: 'cover' }}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        display={'grid'}
        gridTemplateColumns={mobile ? '1fr' : '2fr 1fr'}
        gap={mobile ? 10 : 30}
      >
        <Box>
          <Typography variant='h3' color='white.main' my={4}>
            Why join us?
          </Typography>
          <Box my={4}>
            <Typography color={'white.main'} variant='h5' mb={2}>
              <Span>No</Span> Contract
            </Typography>
            <Typography>
              Setting the precedent for local gyms; no contract, cancel anytime
              online, in minutes.
            </Typography>
          </Box>
          <Box my={4}>
            <Typography color={'white.main'} variant='h5' mb={2}>
              <Span>No</Span> Joining fee
            </Typography>
            <Typography>
              At HYVE, there are no hidden costs and everybody is welcome to
              join.
            </Typography>
          </Box>
          <Box my={4}>
            <Typography color={'white.main'} variant='h5' mb={2}>
              <Span>Free</Span> Classes
            </Typography>
            <Typography>
              Discover our diverse range of engaging, results-driven classes led
              by expert instructors. From calorie-torching HIIT sessions to
              rejuvenating yoga flows, there's something for everyone. Boost
              your strength, flexibility, and endurance while connecting with
              our supportive fitness community.
            </Typography>
          </Box>
          <Box my={4}>
            <Typography color={'white.main'} variant='h5' mb={2}>
              <Span>Free</Span> Trial
            </Typography>
            <Typography>
              As standard, all memberships 1 month+ include a free trial. Feel
              free to give us a try, but if something doesn’t suit you, just
              cancel online within 24 hours and you won't be charged. No hard
              feelings.
            </Typography>
          </Box>
          <Box my={4}>
            <Typography color={'white.main'} variant='h5' mb={2}>
              <Span>No</Span> Time restrictions
            </Typography>
            <Typography>
              All of our memberships include full access to the facility. No
              peak or off-peak limitations.
            </Typography>
          </Box>
          <Box my={4}>
            <Typography color={'white.main'} variant='h5' mb={2}>
              <Span>Free</Span> HYVE credits
            </Typography>
            <Typography>
              For every pound you invest in a HYVE membership, you'll earn an
              equal number of HYVE credits, which can be redeemed for studio
              rentals, pool access, guest passes, or discounted supplements.
            </Typography>
            <Typography mt={1}>
              For instance, a £29 individual monthly membership grants you 29
              credits. You can then use these credits for: <br />
              4 guest passes <br />3 hours in a private studio or pool
            </Typography>
          </Box>
          <Box my={4}>
            <Typography color={'white.main'} variant='h5' mb={2}>
              <Span>Private</Span> Studio hire
            </Typography>
            <Typography>
              Looking for an exclusive space to exercise with friends? HYVE
              provides an array of micro-gym areas and wellness amenities
              tailored to diverse workout preferences. Utilize your credits to
              reserve a studio and feel free to invite anyone, even non-members,
              to join your fitness session.
            </Typography>
          </Box>
        </Box>
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'start'}
          flexDirection={'column'}
        >
          {!subscriptionData && (
            <>
              <Box
                sx={{
                  border: (theme) => `1px solid ${theme.palette.primary.main}`,
                  p: 5,
                  borderRadius: 1,
                }}
              >
                <Typography variant='h3' color='white.main'>
                  {' '}
                  What are you waiting for?
                </Typography>
                <Link to='/join'>
                  <Button size='large' sx={{ mt: 2, fontSize: '1.2rem' }}>
                    JOIN NOW
                  </Button>
                </Link>
              </Box>
              <NearMeIcon
                color='white'
                fontSize='large'
                sx={{ mt: -2, ml: -2 }}
              />
            </>
          )}
        </Box>
      </Box>
    </Page>
  )
}

const mapStateToProps = ({ user, subscription }) => ({
  currentUser: user.currentUser,
  subscriptionData: subscription.currentSubscription,
})

export default connect(mapStateToProps, null)(LLanelli)
