import { Chip } from '@mui/material'
import CampaignIcon from '@mui/icons-material/Campaign'
import { SHOW_LOW_AVAILABILITY_ALERT_COUNT } from '../helpers'

const LowAvailabilityWarning = ({ capacity, enrolled }) => {
  const remaining = capacity - enrolled
  const soldOut = remaining <= 0

  if (remaining > SHOW_LOW_AVAILABILITY_ALERT_COUNT || soldOut) {
    return null
  }

  return (
    <Chip
      label={`Only ${remaining} space${remaining > 1 ? 's' : ''} left!`}
      sx={{ borderRadius: 1, maxWidth: 200 }}
      color='primary'
      icon={<CampaignIcon />}
    />
  )
}

export default LowAvailabilityWarning
