import { Box, Button, CircularProgress, Typography } from '@mui/material'
import Modal from '../../../components/Modal'
import dayjs from 'dayjs'
import { connect } from 'react-redux'
import Span from '../../../components/Span'
import PersonIcon from '@mui/icons-material/Person'
import firebase, { firestore } from '../../../utils/firebase/firebase'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { checkEnrollment, checkWaitlist, friendlyCurrentUser } from '../helpers'
import { Link } from 'react-router-dom'

const ConfirmSignupModal = ({ isOpen, onClose, gymClass, currentUser }) => {
  const [submitting, setSubmitting] = useState(false)

  const isFull =
    gymClass.class.capacity <= gymClass.class.enrolledMembers.length

  const { isWaitlisted, position } = checkWaitlist(
    currentUser.id,
    gymClass.class.waitlistMembers
  )
  const isEnrolled = checkEnrollment(
    currentUser.id,
    gymClass.class.enrolledMembers
  )

  const handleSignup = async () => {
    setSubmitting(true)
    const enroll = {
      enrolledMembers: firebase.firestore.FieldValue.arrayUnion(
        friendlyCurrentUser(currentUser)
      ),
    }

    const waitlist = {
      waitlistMembers: firebase.firestore.FieldValue.arrayUnion(
        friendlyCurrentUser(currentUser)
      ),
    }

    const classRef = firestore.collection('bookings').doc(gymClass.id)
    classRef
      .set(
        {
          class: isFull ? waitlist : enroll,
        },
        { merge: true }
      )
      .then(() => {
        setSubmitting(false)
        toast.success(`You signed up for ${gymClass.class.title} 🚀`)
      })
      .catch(() => {
        toast.error("Sorry, we couldn't confirm your booking. Please try again")
        setSubmitting(false)
      })
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={isWaitlisted || isEnrolled ? 'Booking confirmed' : 'Confirm'}
    >
      <Box
        sx={{
          background: `url(${gymClass.class.coverImage}), linear-gradient(270deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 23%, rgba(0,0,0,0.6) 41%, rgba(0,0,0,1) 100%, rgba(0,0,0,0.6) 100%);`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundBlendMode: 'multiply',
          backgroundSize: 'cover',
        }}
        p={2}
        borderRadius={1}
      >
        <Typography
          textTransform={'uppercase'}
          fontWeight={500}
          color='primary'
          variant='h6'
        >
          {gymClass.class.title}
        </Typography>
        <Typography textTransform={'uppercase'}>
          {dayjs(gymClass.startTime.toDate()).format('dddd D MMM HH:mm a')}
        </Typography>

        <Box display='flex' alignItems={'center'} gap={0.5} mt={2}>
          <PersonIcon fontSize='small' />
          <Typography textTransform={'capitalize'} variant='body2'>
            {currentUser.firstName} {currentUser.lastName}
          </Typography>
        </Box>
      </Box>
      {!isWaitlisted && !isEnrolled && (
        <Box>
          <Typography mt={2} variant='body2'>
            By confirming your class booking below, you agree to follow HYVE's
            cancellation policy. If you are unable to attend, please cancel your
            booking online prior to the start time. Account limitations will
            apply if you no-show on multiple occasions.
          </Typography>
          {isFull && (
            <Box mt={2}>
              <Typography
                textTransform={'uppercase'}
                sx={{ textDecoration: 'underline' }}
                gutterBottom
              >
                Please note
              </Typography>

              <Typography variant='body2'>
                This class is now full and you are making a waitlist request
                only! When you confirm your booking below, you'll be put in{' '}
                <Span>
                  position {gymClass.class.waitlistMembers.length + 1}
                </Span>{' '}
                on the waitlist. You can always check your current waitlist
                position in your account. Should there be cancellations and you
                make it to the front of the line, we'll send you an email to let
                you know you've got a spot in the class.
              </Typography>
            </Box>
          )}
        </Box>
      )}
      <Button
        variant='contained'
        sx={{ mt: 2, mb: 1 }}
        onClick={handleSignup}
        fullWidth
        disabled={submitting || isEnrolled || isWaitlisted}
      >
        {isWaitlisted ? (
          `Waitlisted in position ${position}`
        ) : isEnrolled ? (
          'Registered'
        ) : submitting ? (
          <CircularProgress size={25} />
        ) : (
          'Confirm my booking'
        )}
      </Button>
      {(isWaitlisted || isEnrolled) && (
        <Link to='/account?classes'>
          <Button variant='contained' fullWidth>
            My Classes
          </Button>
        </Link>
      )}
    </Modal>
  )
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
})

export default connect(mapStateToProps, null)(ConfirmSignupModal)
