import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material'
import styled from '@emotion/styled'
import CloseIcon from '@mui/icons-material/Close'

const StyledDialogContent = styled(DialogContent, {
  shouldForwardProp: (prop) => prop !== 'noPadding',
})(({ theme, noPadding = false }) => {
  if (noPadding) return { padding: '24px 0px 0px' }
})

const Modal = ({
  isOpen = false,
  title,
  subtitle,
  children,
  onClose,
  hideButton = false,
  fullScreen = false,
  maxWidth,
  noPadding = false,
}) => {
  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogTitle>
        <Box
          display='flex'
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{ columnGap: 5 }}
        >
          <Box display='flex'>
            <Box sx={{ display: 'flex', columnGap: 3 }}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
              >
                <Typography
                  variant={'h5'}
                  gutterBottom={Boolean(subtitle) || false}
                >
                  {title}
                </Typography>
                <Typography component='div'>{subtitle}</Typography>
              </Box>
            </Box>
          </Box>

          {!hideButton && (
            <Box alignSelf='flex-start'>
              <DialogCloseButton onClick={onClose} />
            </Box>
          )}
        </Box>
        <Divider sx={{ mt: 1 }} />
      </DialogTitle>
      <StyledDialogContent noPadding={noPadding}>
        {children}
      </StyledDialogContent>
    </Dialog>
  )
}

export default Modal

const DialogCloseButton = (props) => {
  return (
    <IconButton
      aria-label='Close modal'
      data-testid={'dialog-close-button'}
      {...props}
      color={'primaryDark'}
    >
      <CloseIcon fontSize='small' />
    </IconButton>
  )
}
