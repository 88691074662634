import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
} from '@mui/material'
import { firestore, sendToCheckout } from '../../../../utils/firebase/firebase'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

const AddMembershipModal = ({ open, handleClose }) => {
  const [loading, setLoading] = useState(true)
  const [hyveMemberships, setHyveMemberships] = useState([])
  const [selectedMembership, setSelectedMembership] = useState('')
  const { uid } = useParams()

  const handleChange = (event) => {
    setSelectedMembership(event.target.value)
  }

  useEffect(() => {
    const fetchData = async () => {
      const data = []
      firestore
        .collection('memberships')
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc, index) => data.push(doc.data()))
          setHyveMemberships(data)
          setLoading(false)
        })
        .catch((error) => {
          toast.error('Error::', error)
          setLoading(false)
        })
    }
    fetchData()
  }, [])

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        display='flex'
        justifyContent={'center'}
        alignItems={'center'}
        sx={{ height: '100vh' }}
      >
        <Box
          sx={{
            background: 'black',
            padding: 5,
            borderRadius: 2,
            border: (t) => `1px solid ${t.palette.primary.main}`,
            width: 500,
          }}
        >
          <FormControl fullWidth sx={{ mb: 1 }}>
            <InputLabel>Select a membership plan</InputLabel>
            <Select
              value={selectedMembership}
              label='Plan &nbsp;  &nbsp;  &nbsp;  &nbsp;   &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;&nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;&nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp; '
              onChange={handleChange}
            >
              {hyveMemberships
                .sort((a, b) => a.order - b.order)
                .map((membership) => (
                  <MenuItem
                    key={membership.stripeAPI}
                    value={membership.stripeAPI}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {membership.type} - {membership.cycle}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Stack gap={1}>
            <Button
              variant='contained'
              fullWidth
              size='large'
              onClick={() => {
                sendToCheckout(selectedMembership, uid)
                setLoading(true)
              }}
              disabled={!selectedMembership || loading}
            >
              {loading ? (
                <CircularProgress size={20} color='secondary' />
              ) : (
                'Go To Checkout'
              )}
            </Button>
            <Button
              variant='outlined'
              onClick={handleClose}
              sx={{ mr: 1 }}
              fullWidth
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  )
}

export default AddMembershipModal
