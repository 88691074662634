import React, { useState } from 'react'

import './styles.scss'
import { auth } from '../../../../../utils/firebase/firebase'
import Loader from '../../../../../components/loader/Loader.component'
import HyveInput from '../../../../../components/brand/input/Input.component'
import HyveButton from '../../../../../components/brand/button/Button.component'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { setLoading } from '../../../../../redux/system/system.actions'

function LoginForm({ setLoading }) {
  const [error, setError] = useState('')
  const [state, setState] = useState({
    email: '',
    password: '',
    loading: false,
  })

  const handleChange = (e) => {
    const { value, name } = e.target
    setState({ ...state, [name]: value })
  }

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()
    setError('')

    await auth
      .signInWithEmailAndPassword(state.email, state.password)
      .then((userCredential) => {})
      .catch((error) => {
        setError(error.message)
      })

    setLoading(false)
  }

  return (
    <>
      <form className='loginForm' onSubmit={handleSubmit}>
        <HyveInput
          type='email'
          name='email'
          required
          value={state.email}
          onChange={handleChange}
          dark
        >
          Email Address
        </HyveInput>
        <HyveInput
          type='password'
          name='password'
          required
          value={state.password}
          onChange={handleChange}
          dark
        >
          Password
        </HyveInput>
        <HyveButton
          disabled={!state.email || !state.password}
          fill
          type='submit'
          dark
        >
          {!state.loaing ? 'LOGIN' : <Loader inline />}
        </HyveButton>
      </form>
      <p className='login__error'>{error && 'invalid email or password'}</p>
      <Link to='/request'>
        <p className='login__forgotPassword'>forgotten your password?</p>
      </Link>
      <p style={{ color: 'white', marginTop: '20px' }}>- OR - </p>
      <Link to='/register'>
        <div className='login__register'>Join HYVE</div>
      </Link>
      <p className='login__registerText'>
        You must create a free account to view private studio availability.
      </p>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setLoading: (system) => dispatch(setLoading(system)),
})

export default connect(null, mapDispatchToProps)(LoginForm)
