import { Stack } from '@mui/material'
import WaitlistClasses from './WaitlistClasses'
import EnrolledClasses from './EnrolledClasses'
import { connect } from 'react-redux'

const Classes = ({ user, family }) => {
  const familyArr = family
    ? Object.entries(family).map(([member, v]) => ({
        member,
        ...v,
      }))
    : []

  return (
    <Stack gap={3}>
      <EnrolledClasses user={user} family={familyArr} />
      <WaitlistClasses user={user} family={familyArr} />
    </Stack>
  )
}

const mapStateToProps = ({ user }) => ({
  family: user.currentUser.family,
})

export default connect(mapStateToProps, null)(Classes)
